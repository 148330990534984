import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Comment.module.sass";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Checkbox from "../../../components/Checkbox";
import Icon from "../../../components/Icon";
import { useMediaQuery } from "react-responsive";
import { COMMENT_JOB } from "../../../common/const";
import RestService from "../../../adapter/base";

const Comment = ({ currency }) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [creator, setCreator] = useState(true);
  const [recommender, setRecommender] = useState(true);
  const [superbaker, setSuperbaker] = useState(true);
  const [baker, setBaker] = useState(true);
  const [others, setOthers] = useState(true);

  const [showFilter, setShowFilter] = useState(isMobile ? false : true);

  const [comments, setComments] = useState([]);

  const [showLoadMore, setShowLoadMore] = useState(true);

  const getCategoryClassName = (category) => {
    switch (category) {
      case COMMENT_JOB.CREATOR:
        return "category-stroke-red";
      case COMMENT_JOB.RECOMMENDER:
        return "category-stroke-blue";
      case COMMENT_JOB.SUPER_BAKER:
        return "category-stroke-green";
      case COMMENT_JOB.BAKER:
        return "category-stroke-purple";
      case COMMENT_JOB.OTHERS:
      default:
        return "category-stroke-orange";
    }
  };

  const getCommentList = (refresh = false) => {
    let filters = [];

    if (creator) {
      filters.push(`job = ${COMMENT_JOB.CREATOR}`);
    }

    if (recommender) {
      filters.push(`job = ${COMMENT_JOB.RECOMMENDER}`);
    }

    if (superbaker) {
      filters.push(`job = ${COMMENT_JOB.SUPER_BAKER}`);
    }

    if (baker) {
      filters.push(`job = ${COMMENT_JOB.BAKER}`);
    }

    if (others) {
      filters.push(`job = ${COMMENT_JOB.OTHERS}`);
    }

    RestService.get("/comment/list", {
      where: {
        currency: currency,
        raw: filters.length ? filters.join(" OR ") : "job = -1",
      },
      limit: 10,
      offset: refresh ? 0 : comments.length,
      orderBy: ["created_at", "asc"],
    }).then((result) => {
      if (!result) {
        return;
      }

      if (refresh) {
        setComments(result.rows);
        setShowLoadMore(result.count !== result.rows.length);
      } else {
        setComments([...comments, ...result.rows]);
        setShowLoadMore(result.count !== comments.length + result.rows.length);
      }
    });
  };

  useEffect(() => {
    getCommentList(true);
  }, [creator, recommender, superbaker, baker, others]);

  const getCategoryName = (category) => {
    switch (category) {
      case COMMENT_JOB.CREATOR:
        return t("Creator");
      case COMMENT_JOB.RECOMMENDER:
        return t("Recommender");
      case COMMENT_JOB.SUPER_BAKER:
        return t("Superbaker");
      case COMMENT_JOB.BAKER:
        return t("Baker");
      case COMMENT_JOB.OTHERS:
      default:
        return t("Others");
    }
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={styles.notification_body}>
        <div className={styles.center}>
          <div className={styles.notification_top}>
            <h2 className={cn("h2", styles.title)}>{t("Comments")}</h2>
            <div className={styles.btn_group}>
              <button
                className={cn("button-small button-stroke", styles.button)}
                style={{ marginRight: "16px" }}
              >
                {t("Mark all as read")}
              </button>
              <button
                className={cn("button-small button-stroke", styles.button)}
              >
                {t("Clear all")}
              </button>
            </div>
          </div>
          <div className={styles.show_filter_btn}>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => setShowFilter(!showFilter)}
            >
              {t("Advanced Filter")}
            </button>
          </div>
          <div className={styles.notification_row}>
            <div
              className={styles.notification_filter}
              style={{ display: showFilter ? "block" : "none" }}
            >
              <div className={styles.notification_info}>{t("Filters")}</div>
              <div className={styles.notification_group}>
                <Checkbox
                  className={styles.checkbox}
                  content={t("Creator")}
                  value={creator}
                  onChange={() => setCreator(!creator)}
                  note={undefined}
                />
                <Checkbox
                  className={styles.checkbox}
                  content={t("Recommender")}
                  value={recommender}
                  onChange={() => setRecommender(!recommender)}
                  note={undefined}
                />
                <Checkbox
                  className={styles.checkbox}
                  content={t("Superbaker")}
                  value={superbaker}
                  onChange={() => setSuperbaker(!superbaker)}
                  note={undefined}
                />
                <Checkbox
                  className={styles.checkbox}
                  content={t("Baker")}
                  value={baker}
                  onChange={() => setBaker(!baker)}
                  note={undefined}
                />
                <Checkbox
                  className={styles.checkbox}
                  content={t("Others")}
                  value={others}
                  onChange={() => setOthers(!others)}
                  note={undefined}
                />
              </div>
              <div className={styles.notification_control}>
                <button
                  className={cn("button-small button-stroke", styles.button)}
                  style={{ marginRight: "16px" }}
                  onClick={() => {
                    setCreator(true);
                    setRecommender(true);
                    setSuperbaker(true);
                    setBaker(true);
                    setOthers(true);
                  }}
                >
                  {t("Select all")}
                </button>
                <button
                  className={cn("button-small button-stroke", styles.button)}
                  onClick={() => {
                    setCreator(false);
                    setRecommender(false);
                    setSuperbaker(false);
                    setBaker(false);
                    setOthers(false);
                  }}
                >
                  {t("Unselect all")}
                </button>
              </div>
            </div>
            <div className={styles.notification_wrap}>
              {comments.map((comment, index) => {
                return (
                  <div className={styles.notification_item} key={index}>
                    <div className={styles.notification_details}>
                      <div className={styles.notification_line}>
                        <div className={styles.notification_subtitle}>
                          {comment.name}
                          <div
                            className={cn(
                              getCategoryClassName(comment.job),
                              styles.category
                            )}
                            style={{ marginLeft: "12px" }}
                          >
                            {getCategoryName(comment.job)}
                          </div>
                        </div>
                        <div className={styles.notification_date}>
                          {comment.created_at.replace("T", " ").slice(0, 16)}
                        </div>
                      </div>
                      <div className={styles.notification_content}>
                        {comment.comment}
                      </div>
                    </div>
                  </div>
                );
              })}
              {showLoadMore && (
                <div className={styles.notification_btns}>
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                    onClick={() => getCommentList(false)}
                  >
                    {t("Load more")}
                    <Icon name="calendar" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comment;
