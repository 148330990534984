import React, { useMemo } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Cards.module.sass";

import { useTranslation } from "react-i18next";
import Youtube from "./youtube";
import Instagram from "./instagram";
import Twitter from "./twitter";
import Facebook from "./facebook";

const Cards = ({ snsList, token, showAction }) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const userId = useMemo(() => queryParams.get("user_id"), [queryParams]);
  const currency = useMemo(() => queryParams.get("currency"));
  const sortedData = useMemo(() => {
    const sortedOrder = ["youtube", "twitter", "facebook", "instagram"];
    const result = [];
    if (snsList) {
      for (let i = 0; i < sortedOrder.length; i++) {
        const value = snsList.find((x) => x.category === sortedOrder[i]);
        if (value !== undefined) {
          result.push(value);
        }
      }
    }

    return result;
  }, [snsList]);

  return (
    <div className={styles.cards_container}>
      <div
        className={cn("container", styles.container)}
        style={{ padding: "0px" }}
      >
        {sortedData.map((item, index) => (
          <>
            {item.category === "youtube" && <Youtube snsId={item.sns_name} />}
            {item.category === "instagram" && (
              <Instagram snsId={item.sns_name} />
            )}
            {item.category === "facebook" && <Facebook snsId={item.sns_name} />}
            {item.category === "twitter" && <Twitter snsId={item.sns_name} />}
          </>
        ))}
      </div>
    </div>
  );
};

export default Cards;
