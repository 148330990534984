import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

import { useTranslation } from 'react-i18next';

const Main = ({totalMarginBalance, BTCUSDRate}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.main}>
      <h4 className={cn("h4", styles.title)}>{t("Margin")}</h4>
      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.info}>{t("Total balance")}</div>
          <div className={styles.currency}>
            <div className={styles.number}>{BTCUSDRate !== 0.0 ? (totalMarginBalance / BTCUSDRate).toFixed(2) : 0.00}</div>
            <div className={cn("category-green", styles.category)}>BTC</div>
          </div>
          <div className={styles.price}>${totalMarginBalance.toFixed(2)}</div>
        </div>
        <div className={cn(styles.item, styles.flex)}>
          <div className={styles.chart}>
            <img src="/images/content/chart.svg" alt={t("Chart")} />
          </div>
          <div className={styles.details}>
            <div className={styles.info}>{t("Margin level")}</div>
            <div className={styles.currency}>
              <div className={styles.number}>999.00</div>
              <div className={cn("category-green", styles.category)}>
                {t("low risk")}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.stage}>{t("Account Equity")}</div>
          <div className={styles.currency}>
            <div className={styles.number}>0.00000000</div>
            <div className={cn("category-gray", styles.category)}>BTC</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
