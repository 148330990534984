import React, { useRef, useState } from "react";
import cn from "classnames";
import { toast } from "react-toastify";
import styles from "./Mfa.module.sass";
import RestService from "../../../adapter/base";
import { useBitmaidoContext } from "../../../hooks/useBitmaidoContext";

import { useTranslation } from "react-i18next";

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

const Mfa = ({ user }) => {
  const { t } = useTranslation();

  const [num1, setNum1] = useState("");
  const [num2, setNum2] = useState("");
  const [num3, setNum3] = useState("");
  const [num4, setNum4] = useState("");
  const [num5, setNum5] = useState("");
  const [num6, setNum6] = useState("");
  const [ref1, setFocus1] = useFocus();
  const [ref2, setFocus2] = useFocus();
  const [ref3, setFocus3] = useFocus();
  const [ref4, setFocus4] = useFocus();
  const [ref5, setFocus5] = useFocus();
  const [ref6, setFocus6] = useFocus();
  const { setUserLoggedin } = useBitmaidoContext();

  const onClickContinue = () => {
    if (!(num1 && num2 && num3 && num4 && num5 && num6)) {
      toast.error(t("Please enter valid verification code!"));
    }
    const code = num1 + num2 + num3 + num4 + num5 + num6;

    RestService.get(`/user/mfa/verify`, {
      email: user.email,
      code: code,
    }).then((res) => {
      if (!res.error) {
        setUserLoggedin(true);
        window.localStorage.setItem("token", user.jwt_token);
        window.localStorage.setItem("user_id", user.id);
        window.location = "/exchange/spot";
      } else {
        if (res.error === "INVALID_EMAIL" || res.error === "CODE_NOT_MATCH") {
          toast.error(t("Invalid user address or verification code!"), {
            postition: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
          });
        } else {
          toast.error(t("Code check failed! Please try again!"), {
            postition: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
          });
        }
      }
    });
  };

  const onChange = (
    value,
    func,
    nextFocus = undefined,
    prevFocus = undefined
  ) => {
    try {
      if (value === "") {
        func(value);
        if (prevFocus) prevFocus();
        return;
      }
      const num = parseInt(value);
      if (num >= 0 && num <= 9) {
        func(num.toString());
        if (nextFocus) nextFocus();
      } else throw new Error("Invalid input");
    } catch {
      toast.error(t("Please enter valid Auth code!"));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  };
  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.text}>
        {t("Enter the 6 digit code received by Google Authenticator")}
      </div>
      {/* <div className={styles.qrcode}>
        <img src={qrcode}/>
      </div> */}
      <div className={styles.code}>
        <div className={styles.number}>
          <input
            type="tel"
            ref={ref1}
            value={num1}
            onChange={(e) => onChange(e.target.value, setNum1, setFocus2)}
          />
        </div>
        <div className={styles.number}>
          <input
            type="tel"
            ref={ref2}
            value={num2}
            onChange={(e) =>
              onChange(e.target.value, setNum2, setFocus3, setFocus1)
            }
          />
        </div>
        <div className={styles.number}>
          <input
            type="tel"
            ref={ref3}
            value={num3}
            onChange={(e) =>
              onChange(e.target.value, setNum3, setFocus4, setFocus2)
            }
          />
        </div>
        <div className={styles.number}>
          <input
            type="tel"
            ref={ref4}
            value={num4}
            onChange={(e) =>
              onChange(e.target.value, setNum4, setFocus5, setFocus3)
            }
          />
        </div>
        <div className={styles.number}>
          <input
            type="tel"
            ref={ref5}
            value={num5}
            onChange={(e) =>
              onChange(e.target.value, setNum5, setFocus6, setFocus4)
            }
          />
        </div>
        <div className={styles.number}>
          <input
            type="tel"
            ref={ref6}
            value={num6}
            onChange={(e) =>
              onChange(e.target.value, setNum6, undefined, setFocus5)
            }
          />
        </div>
      </div>

      <button
        className={cn("button-small", styles.button)}
        onClick={onClickContinue}
      >
        {t("Continue")}
      </button>
    </form>
  );
};

export default Mfa;
