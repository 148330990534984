import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./SessionsAndLoginHistory.module.sass";
import Profile from "../../components/Profile";
import { Link } from "react-router-dom";
import RestService from "../../adapter/base";

import { useTranslation } from "react-i18next";

const SessionsAndLoginHistory = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    {
      title: t("Home"),
      url: "/",
    },
    {
      title: t("Login history"),
    },
  ];

  const [history, setHistory] = useState([]);

  useEffect(() => {
    RestService.get("/user/log").then((result) => {
      result = result.reverse();
      setHistory(result);
    });
  }, []);

  return (
    <Profile title={t("Login history")} breadcrumbs={breadcrumbs}>
      {/* <div className={styles.section}>
        <div className={styles.title}>{t("Active sessions")}</div>
        <div className={styles.scrollArea}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>{t("Date / time")}</div>
              <div className={styles.col}>{t("Device")}</div>
              <div className={styles.col}>{t("Location")}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.content}>2023-03-05</div>
                <div className={styles.note}>10:46:09</div>
              </div>
              <div className={styles.col}>
                <div className={styles.content}>Macbook Pro</div>
                <div className={styles.note}>Mac OS Bigsur</div>
              </div>
              <div className={styles.col}>
                <div className={styles.content}>
                  <span className={cn("category", styles.location)}>USA</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.btnSection}>
          <button className={cn("button-stroke", styles.button)}>
            {t("Log out all other devices")}
          </button>
        </div>
      </div> */}
      <div className={styles.section}>
        <div className={styles.title}>{t("Login history")}</div>
        <div className={styles.scrollArea}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>{t("Date / time")}</div>
              <div className={styles.col}>{t("IP address")}</div>
              <div className={styles.col}>{t("Used 2FA")}</div>
            </div>
            {history.map((x, index) => {
              const xDate = new Date(x.updated_at);
              const date =
                xDate.getFullYear() +
                "-" +
                (xDate.getMonth() + 1) +
                "-" +
                xDate.getDate();
              const time = xDate.getHours() + ":" + xDate.getMinutes();
              return (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    <div className={styles.content}>{date}</div>
                    <div className={styles.note}>{time}</div>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.content}>{x.ip_addr}</div>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.content}>
                      {x.twoFA === 0 ? (
                        <span className={cn("category-red", styles.status)}>
                          {t("NO")}
                        </span>
                      ) : (
                        <span className={cn("category-green", styles.status)}>
                          {t("YES")}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.btnSection}>
          <button className={cn("button-stroke", styles.button)}>
            {t("Contact us")}
          </button>
        </div>
      </div>
    </Profile>
  );
};

export default SessionsAndLoginHistory;
