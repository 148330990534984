import React, { useState } from "react";
import Profile from "../../components/Profile";
import Form from "./Form";
import Finish from "./Finish";

import { useTranslation } from 'react-i18next';

const ChangePassword = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    {
      title: t("Home"),
      url: "/",
    },
    {
      title: t("Change password"),
    },
  ];

  const [finish, setFinish] = useState(false);

  return (
    <Profile title={t("Change password")} breadcrumbs={breadcrumbs}>
      {finish ? <Finish /> : <Form goFinish={() => setFinish(true)} />}
    </Profile>
  );
};

export default ChangePassword;
