import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import jwt from "jsonwebtoken";
import countryList from "react-select-country-list";
import styles from "./ProfileInfo.module.sass";
import Profile from "../../components/Profile";
import Dropdown from "../../components/Dropdown";
import Switch from "../../components/Switch";
import RestService from "../../adapter/base";
import TextInput from "../../components/TextInput";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import Avatar from "../../components/Avatar";
import { useUserContext } from "../../hooks/useUserContext";
import TextArea from "../../components/TextArea";
import Modal from "../../components/Modal";
import Icon from "../../components/Icon";
import Youtube from "./snsPreview/youtube";
import Instagram from "./snsPreview/instagram";

const ProfileInfo = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    {
      title: t("Home"),
      url: "/",
    },
    {
      title: t("Profile info"),
    },
  ];

  const [status, setStatus] = useState("KYC not verified");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [description, setDescription] = useState("");
  const [snsList, setSnsList] = useState([]);

  const [snsModalVisible, setSnsModalVisible] = useState(false);
  const [snsCategory, setSnsCategory] = useState("");
  const [snsAccount, setSnsAccount] = useState("");
  const [snsAccountLabel, setSnsAccountLabel] = useState("SNS Account Name");

  const [snsPreviewVisible, setPreviewVisible] = useState(false);
  const [snsPreviewCategory, setPreviewCategory] = useState("");
  const [snsPreviewId, setPreviewId] = useState("");

  const SNS_CATEGORY = ["twitter", "instagram", "youtube", "facebook"];

  const SNS_TOOLTIP = {
    youtube: (
      <span className={styles.tooltip}>
        • Go to YouTube Studio by clicking on your profile picture in the
        top-right corner of any YouTube page and selecting{" "}
        <strong>YouTube Studio</strong>.
        <br />• In YouTube Studio, click on <strong>Settings</strong> (the gear
        icon) in the left sidebar. <br />• Under <strong>Channel</strong>, click
        on <strong>Advanced settings</strong>. <br />• You'll see your Channel
        ID listed there.
      </span>
    ),
    facebook: (
      <span className={styles.tooltip}>
        • {t("Please refer to this link for getting user id: ")}
        <a href="https://www.facebook.com/help/1397933243846983">
          https://www.facebook.com/help
        </a>
      </span>
    ),
    instagram: (
      <span className={styles.tooltip}>
        • {t("Please refer to this link for getting user id: ")}
        <a href="https://superface.ai/blog/instagram-account-id">
          https://superface.ai/blog/instagram-account-id
        </a>
      </span>
    ),
  };

  useEffect(() => {
    if (snsCategory === "youtube") {
      setSnsAccountLabel("Youtube Channel ID");
    } else {
      setSnsAccountLabel("SNS Account Name");
    }
  }, [snsCategory]);

  const { user, updateUser } = useUserContext();

  useEffect(() => {
    if (user) {
      setFirstName(user.firstname ?? "");
      setLastName(user.lastname ?? "");
      setDescription(user.description ?? "");
    }
  }, [user, setFirstName, setLastName]);

  const getVerifiedStatus = () => {
    if (user?.kyc_status === 0) {
      setStatus("KYC not verified");
    } else if (user?.kyc_status === 1) {
      setStatus("KYC Verified");
    } else if (user?.kyc_status === 2) {
      setStatus("KYC pending");
    } else {
      setStatus("KYC rejected");
    }
  };

  useEffect(() => {
    getVerifiedStatus();
  }, [user]);

  const updateAvatar = (file) => {
    const data = new FormData();
    data.append("avatar", file);
    RestService.post("/user/avatar", data).then((res) => {
      if (res === null) {
        toast.success(t("User updated successfully!"));
        window.location.reload(true);
      } else {
        toast.error(
          t("Upload Failed! Reason: ", { error: t("Internal Server Error") })
        );
      }
    });
  };

  const onUpdateUser = () => {
    try {
      RestService.put("/user", {
        firstname: firstName,
        lastname: lastName,
        description: description,
      }).then((res) => {
        if (!res.error) {
          toast.success(t("User updated successfully!"));
          updateUser();
        } else {
          toast.error(
            t("Update User Failed! Reason: ", {
              error: t("Internal Server Error"),
            })
          );
        }
      });
    } catch {
      toast.error(t("User Update Failed! Internal Error!"));
    }
  };

  const getSnsList = () => {
    try {
      RestService.get("/sns").then((res) => {
        setSnsList(res);
      });
    } catch {}
  };

  const putSnsInfo = () => {
    RestService.post("/sns", {
      category: snsCategory,
      sns_name: snsAccount,
    }).then((res) => {
      if (!res.error) {
        toast.success(t("User updated successfully!"));
        getSnsList();
        setSnsModalVisible(false);
      } else {
        toast.error(
          t("Update User Failed! Reason: ", {
            error: t("Internal Server Error"),
          })
        );
      }
    });
  };

  const onAddSnsAccount = () => {
    setSnsCategory("");
    setSnsAccount("");
    setSnsModalVisible(true);
  };

  const onUpdateSnsAccount = (category, account) => {
    setSnsAccount(account);
    setSnsCategory(category);
    setSnsModalVisible(true);
  };

  useEffect(() => {
    const item = snsList.find((x) => x.category === snsCategory);
    setSnsAccount(item?.sns_name ?? "");
  }, [snsCategory, setSnsAccount]);

  useEffect(() => {
    getSnsList();
  }, []);

  const onVisiblePreview = (category, id) => {
    setPreviewCategory(category);
    setPreviewId(id);
    setPreviewVisible(true);
  };

  return (
    <Profile title={t("Profile info")} breadcrumbs={breadcrumbs}>
      <div className={styles.head}>
        <div className={styles.headerUser}>
          <Avatar image={user?.avatar} setImage={updateAvatar} />
          <div className={styles.details}>
            <div className={styles.user}>
              {user?.fullname ? user?.fullname : "Unknown"}
            </div>
            <div className={styles.email}>{user?.email}</div>
          </div>
        </div>

        <div className={styles.level}>{status}</div>
      </div>

      <div className={styles.settings}>
        <div className={styles.title}>{t("Profile")}</div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.label}>{t("First Name")}</div>
            <TextInput
              className={styles.field}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className={styles.col}>
            <div className={styles.label}>{t("Last Name")}</div>
            <TextInput
              className={styles.field}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.colfull}>
            <div className={styles.label}>{t("Description")}</div>
            <TextArea
              style={{
                height: 120,
              }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={onUpdateUser}>
          {t("Update Profile")}
        </button>
      </div>

      <div className={styles.section}>
        <div className={styles.title}>{t("Sns Links")}</div>
        <div className={styles.scrollArea}>
          <div className={styles.table}>
            <div className={cn(styles.table_row, styles.table_header)}>
              <div
                className={styles.table_col}
                style={{
                  width: "200px",
                }}
              >
                {t("Category")}
              </div>
              <div className={styles.table_col}>{t("Account")}</div>
              <div className={styles.table_col}>{t("Action")}</div>
            </div>
            {snsList.map((item, index) => (
              <div className={styles.table_row} key={index}>
                <div className={styles.table_col}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <img
                      className={styles.image}
                      src={`/images/content/${item.category}.svg`}
                      alt=""
                    />
                    <div className={styles.note}>{item.category}</div>
                  </div>
                </div>
                <div className={styles.table_col}>
                  <div className={styles.table_content}>
                    <span>{item.sns_name}</span>
                  </div>
                </div>
                <div className={styles.table_col}>
                  <div className={styles.table_content}>
                    {/* {item.category !== "facebook" && (
                      <span
                        className={cn("category-green", styles.status)}
                        onClick={() =>
                          onVisiblePreview(item.category, item.sns_name)
                        }
                      >
                        {t("Preview")}
                      </span>
                    )} */}
                    <span
                      className={cn("category-blue", styles.status)}
                      onClick={() =>
                        onUpdateSnsAccount(item.category, item.sns_name)
                      }
                    >
                      {t("Update")}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.btns}>
        <button
          className={cn("button", styles.button)}
          onClick={onAddSnsAccount}
        >
          {t("Add New")}
        </button>
      </div>

      <div className={styles.settings}>
        <div className={styles.title}>{t("Features")}</div>
        {/* LEVEL 1 */}
        <div className={styles.subtitle}>{`${t("LEVEL")} 1`}</div>
        <div className={styles.row}>
          <div className={styles.label}>{t("Deposit assets")}</div>
          <Switch></Switch>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>{t("Withdraw assets")}</div>
          <div className={styles.description}>
            {t("Enabled day", { value: "1,000,000" })}
          </div>
        </div>
        {/* LEVEL 2 */}
        <div className={styles.subtitle}>{`${t("LEVEL")} 2`}</div>
        <div className={styles.row}>
          <div className={styles.label}>{t("Fiat and Spot wallet")}</div>
          <Switch></Switch>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>{t("Margin wallet")}</div>
          <div className={styles.description}>
            {t("Enabled day", { value: "1,000,000" })}
          </div>
        </div>
      </div>

      <Modal
        visible={snsModalVisible}
        onClose={() => setSnsModalVisible(false)}
      >
        <div className={styles.snsUpdate}>
          <div className={cn("h4", styles.title)}>
            <Icon name="arrow-left" size="32" />
            {snsList.find((x) => x.category === snsCategory)
              ? t("Update Your SNS")
              : t("Add new SNS")}
          </div>
          <Dropdown
            className={styles.dropbox}
            classDropdownHead={styles.dropdownHead}
            label={t("Category")}
            value={snsCategory}
            setValue={setSnsCategory}
            options={SNS_CATEGORY}
          />
          <TextInput
            className={styles.field}
            label={t(snsAccountLabel)}
            name="snsAccount"
            type="text"
            value={snsAccount}
            onChange={(e) => setSnsAccount(e.target.value)}
            required
          />
          {SNS_TOOLTIP[snsCategory]}
          <button className={cn("button", styles.button)} onClick={putSnsInfo}>
            {snsList.find((x) => x.category === snsCategory)
              ? t("Update")
              : t("Add new")}
          </button>
        </div>
      </Modal>
      <Modal
        visible={snsPreviewVisible}
        onClose={() => setPreviewVisible(false)}
      >
        <div className={styles.snsUpdate}>
          <div className={cn("h4", styles.title)}>
            <Icon name="arrow-left" size="32" />
            {snsPreviewCategory}
          </div>
          {snsPreviewCategory === "youtube" && <Youtube snsId={snsPreviewId} />}
          {snsPreviewCategory === "instagram" && (
            <Instagram snsId={snsPreviewId} />
          )}
        </div>
      </Modal>
    </Profile>
  );
};

export default ProfileInfo;
