import React, { useEffect, useState } from "react";
import cn from "classnames";
import { toast } from "react-toastify";
import styles from "./PaymentDetails.module.sass";
import { Link } from "react-router-dom";
import RestService from "../../../adapter/base";
import CONST from "../../../common/const";
import Icon from "../../../components/Icon";

import { useTranslation } from "react-i18next";

const PaymentDetails = ({
  goNext,
  method,
  type,
  price,
  currency,
  address,
  net,
}) => {
  const { t } = useTranslation();
  const [depositAddress, setDepositAddress] = useState("");
  const [requestCode, setRequestCode] = useState("");

  const items =
    net === undefined
      ? [
          {
            title: t("Currency"),
            content: currency,
          },
          {
            title: t("Method"),
            content: method,
          },
          {
            title: t("Type"),
            content: type,
          },
          {
            title: t("Amount"),
            content: price,
          },
          {
            title: t("Address"),
            content: address,
          },
        ]
      : [
          {
            title: t("Currency"),
            content: currency,
          },
          {
            title: t("Amount"),
            content: price,
          },
          {
            title: t("Chain"),
            content: net,
          },
        ];

  useEffect(() => {
    RestService.get("/deposit/address", {
      method:
        method === t("Direct Transfer")
          ? CONST.TRADE_METHOD.DIRECT
          : CONST.TRADE_METHOD.WITHDRAW,
      trade_type:
        type === "Spot" ? CONST.TRADE_TYPE.SPOT : CONST.TRADE_TYPE.MARGIN,
      currency: currency,
      address: address,
      amount: price,
      chain: net,
    }).then((res) => {
      if (!res.error) {
        toast.success(t("Deposit Address returned successfully!"));
        if (net === undefined) {
          setRequestCode(res.requestCode);
          setDepositAddress(res.address);
        } else {
          const deposits = JSON.parse(res);
          setDepositAddress(deposits[currency][net]);
        }
      } else {
        toast.error(
          t("Get Deposit Address Failed! Reason: ", {
            error: t("Internal Server Error"),
          })
        );
      }
    });
  }, []);

  const onContinue = () => {
    window.location = "/activity";
  };

  return (
    <div className={styles.item}>
      <div className={styles.title}>{t("Request Address")}</div>
      <div className={styles.info}>{t("Deposit Details")}</div>
      <div className={styles.list}>
        {items.map((x, index) => {
          if (x.title === t("Address") && method !== t("Direct Transfer")) {
            return "";
          }

          return (
            <div className={styles.line} key={index}>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.details}>
                <div className={styles.content}>{x.content}</div>
                <button className={styles.copy}>
                  <Icon name="copy" size="24" />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.info}>{t("Deposit Address")}</div>
      {net === undefined && depositAddress && (
        <>
          <div className={styles.code}>{depositAddress}</div>
          <div className={styles.text}>
            {t(
              "You MUST keep your Reference Code in other to request validate for your deposit"
            )}
            <br></br>
            {t("Reference Code:")}
          </div>
          <div className={styles.code}>{requestCode}</div>
        </>
      )}
      {net !== undefined && depositAddress && (
        <>
          <div className={styles.code}>{depositAddress}</div>
          <div className={styles.row}>
            <img
              className={styles.qrcode}
              src={`http://api.qrserver.com/v1/create-qr-code/?data=${depositAddress}`}
            />
          </div>
        </>
      )}
      {!depositAddress && (
        <div className={styles.alertText}>
          {t("Get Deposit Address Failed! Please contact to the manager!")}
        </div>
      )}
      <div className={styles.info}>{t("Description")}</div>
      <div className={styles.text}>
        {t(
          "You MUST send the correct amount you entered to deposit address in order to credit your account!"
        )}
      </div>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={onContinue}>
          {t("Continue")}
        </button>
      </div>
    </div>
  );
};

export default PaymentDetails;
