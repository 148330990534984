import React, { useEffect, useState } from "react";
import cn from "classnames";
import { toast } from "react-toastify";
import styles from "./TwoFaDisabled.module.sass";
import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";
import RestService from "../../../adapter/base";

import { useTranslation } from "react-i18next";

const TwoFaDisabled = ({ user }) => {
  const { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [qrcode, setQRCode] = useState({});

  const goEnabled = () => {
    if (!user) toast.error(t("Update Error! Invalid User Information."));
    RestService.put("/user/mfa/update", {
      email: user.email,
      password: password,
      code: code,
      enabled: 1,
    }).then((res) => {
      if (!res.error) {
        window.location = "/2fa";
      } else {
        toast.error(t("Please enter your password and 2FA code."));
      }
    });
  };

  useEffect(() => {
    RestService.get("/user/mfa/qrcode", { email: user.email }).then((res) => {
      if (!res.error) {
        setQRCode(res);
      }
    });
  }, [user]);

  const onChangeCode = (e) => {
    try {
      const value = e.target.value;
      if (value === "") {
        setCode(value);
        return;
      }
      const num = parseInt(value);
      if (!isNaN(num) && num < 1000000) {
        setCode(value);
      }
    } catch {}
  };

  return (
    <div>
      <div className={cn("h3", styles.title)}>
        {t("2FA")} <span>{t("Disabled")}</span>
      </div>
      <div className={styles.text}>
        {t(
          'If you want to turn off 2FA, input your account password and the six-digit code provided by the Google Authenticator app below, then click "'
        )}
        <span>{t("Disable 2FA")}</span>".
      </div>
      <div className={styles.subtitle}>{t("Enable 2FA")}</div>
      <div className={styles.info}>
        {t(
          "Enter your password and scan SQ code by the Google Authenticator app to Enable the 2FA verification"
        )}
      </div>
      <div className={styles.email}>
        <Icon name="email" size="24" />
        {user && user.email}
      </div>
      <div className={styles.row}>
        <TextInput
          className={styles.field}
          label={t("password")}
          name={"password"}
          type="password"
          placeholder={t("Password")}
          required
          view
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextInput
          className={styles.field}
          label={t("2FA Code")}
          name={"code"}
          type="text"
          required
          value={code}
          onChange={onChangeCode}
        />
      </div>
      <div className={styles.box}>
        <div className={styles.details}>
          <div className={styles.code}>
            {qrcode === {} ? (
              <img src="/images/content/qr-code.jpg" alt={t("Qr code")} />
            ) : (
              <img src={qrcode} alt={t("Qr code")} />
            )}
          </div>
        </div>
      </div>
      <div className={styles.btns}>
        <button
          className={cn("button-green", styles.button)}
          onClick={goEnabled}
        >
          {t("Enable 2FA")}
        </button>
      </div>
    </div>
  );
};

export default TwoFaDisabled;
