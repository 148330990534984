import React, { useEffect, useState, useRef } from "react";
import { ThemeContext } from "./context/themeContext";

import useDarkMode from "use-dark-mode";

export const ThemeProvider = ({ children, type }) => {
  const [isDark, setDark] = useState(false);
  const darkMode = useDarkMode(false);

  useEffect(() => {
    darkMode.disable();
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        isDark,
        setDark,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
