import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import styles from "./Update.module.sass";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RestService from "../../../adapter/base";

const Update = ({ user }) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const userId = useMemo(() => queryParams.get("user_id"), [queryParams]);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    RestService.get("/blog/public", {
      user_id: userId,
    }).then((res) => {
      setBlogs(res.rows);
    });
  }, [userId]);

  return (
    <div className={cn("section", styles.section)}>
      <div className={styles.notification_body}>
        <div className={styles.center}>
          <div className={styles.content}>
            <h2 className={cn("h2", styles.title)}>{t("Update")}</h2>
          </div>
          <div className={styles.lesson_list}>
            {blogs.map((blog, index) => (
              <Link
                className={styles.lesson_item}
                key={`blog_${index}`}
                to={`/blog?blog_id=${blog.id}`}
              >
                <div className={styles.lesson_preview}>
                  <img
                    src={`${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${blog.image}`}
                    alt="Lesson"
                    onError={(e) => {
                      e.target.src = "/images/content/learn-pic-2@2x.jpg";
                    }}
                  />
                </div>
                <div className={cn("category-green", styles.category)}>
                  {t("Update")}
                </div>
                <div className={styles.lesson_subtitle}>{blog.title}</div>
                <div className={styles.lesson_line}>
                  <div className={styles.lesson_author}>
                    <div className={styles.lesson_avatar}></div>
                    <div className={styles.lesson_man}>{user.fullname}</div>
                  </div>
                  <div className="lessons__date">
                    {new Date(blog.updated_at).toLocaleDateString()}
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className={styles.lesson_buttons}>
            <button className={cn("button-stroke", styles.button)}>
              <span>{t("Load more")}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
