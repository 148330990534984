import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Cards.module.sass";
import io from "socket.io-client";
import numeral from "numeral";
import { useSimpleBitmaidoContext } from "../../../../hooks/useSimpleBitmaidoContext";
import CONST from "../../../../common/const";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../../components/Dropdown";

const Cards = ({ className, trendTab, handleTrendTab }) => {
  const { t } = useTranslation();

  const { items } = useSimpleBitmaidoContext();

  const getTrendTab = (tablName) => {
    if (tablName === t("Personalized token")) {
      return CONST.TREND_TAB.USER_TOKEN;
    }

    if (tablName === t("Cryptocurrencies")) {
      return CONST.TREND_TAB.CRYPTO_CURRENCY;
    }

    if (tablName === t("DeFi")) {
      return CONST.TREND_TAB.DEFI;
    }

    if (tablName === t("ICO")) {
      return CONST.TREND_TAB.ICO;
    }
  };

  const getTrendTabName = () => {
    if (trendTab === CONST.TREND_TAB.USER_TOKEN) {
      return t("Personalized token");
    }

    if (trendTab === CONST.TREND_TAB.CRYPTO_CURRENCY) {
      return t("Cryptocurrencies");
    }

    if (trendTab === CONST.TREND_TAB.DEFI) {
      return t("DeFi");
    }

    if (trendTab === CONST.TREND_TAB.ICO) {
      return t("ICO");
    }
  };

  return (
    <div className={styles.cards_container}>
      <div className={cn("container", styles.container)}>
        <h2 className={cn("h2", styles.title)}>
          {t(
            "Today's Market Prices For Personalized token and Cryptocurrencies"
          )}
        </h2>

        <div className={styles.cards_content}>
          <div className={styles.cards}>
            {items.slice(0, 6).map((x, index) => {
              let numeralFormat = "0,0.";
              for (let i = 0; i < x.precision; i++) {
                numeralFormat += "0";
              }

              return (
                <Link
                  className={styles.card}
                  key={index}
                  to={`${x.url}?symbol=${x.currency}USDT`}
                >
                  <div className={styles.icon}>
                    <img src={x.image} alt={t("Currency")} />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.line}>
                      <div className={styles.coin_title}>{x.title}</div>
                      {x.changePct >= 0 && (
                        <div className={styles.positive}>
                          {parseFloat(x.changePct) === 0
                            ? "-.--"
                            : parseFloat(x.changePct).toFixed(2)}
                          %
                        </div>
                      )}
                      {x.changePct < 0 && (
                        <div className={styles.negative}>
                          {parseFloat(x.changePct).toFixed(2)}%
                        </div>
                      )}
                    </div>
                    <div className={styles.price}>
                      {parseFloat(x.price) === 0
                        ? "-.--"
                        : numeral(parseFloat(x.price)).format(numeralFormat)}
                    </div>
                    <div className={styles.money}>
                      {parseFloat(x.money) === 0
                        ? "-.--"
                        : numeral(parseFloat(x.money)).format("0,0.0000")}
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
          <div className={styles.trade_content_mobile}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              classDropdownArrow={styles.dropdownArrow}
              classDropdownBody={styles.dropdownBody}
              classDropdownOption={styles.dropdownOption}
              value={getTrendTabName()}
              setValue={(tab) => {
                handleTrendTab(getTrendTab(tab));
              }}
              options={[
                t("Personalized token"),
                t("Cryptocurrencies"),
                t("DeFi"),
              ]}
            />
          </div>
          <div className={styles.trade_content}>
            {trendTab === CONST.TREND_TAB.USER_TOKEN ? (
              <Link
                className={cn("button-gray button-xs", styles.button)}
                to=""
              >
                {t("Personalized token")}
              </Link>
            ) : (
              <span
                className={styles.button}
                onClick={() => handleTrendTab(CONST.TREND_TAB.USER_TOKEN)}
              >
                {t("Personalized token")}
              </span>
            )}
            {trendTab === CONST.TREND_TAB.CRYPTO_CURRENCY ? (
              <Link
                className={cn("button-gray button-xs", styles.button)}
                to=""
              >
                {t("Cryptocurrencies")}
              </Link>
            ) : (
              <span
                className={styles.button}
                onClick={() => handleTrendTab(CONST.TREND_TAB.CRYPTO_CURRENCY)}
              >
                {t("Cryptocurrencies")}
              </span>
            )}
            {trendTab === CONST.TREND_TAB.DEFI ? (
              <Link
                className={cn("button-gray button-xs", styles.button)}
                to=""
              >
                {t("DeFi")}
              </Link>
            ) : (
              <span
                className={styles.button}
                onClick={() => handleTrendTab(CONST.TREND_TAB.DEFI)}
              >
                {t("DeFi")}
              </span>
            )}
            <div className={styles.trade}>
              <Link className={cn("button-stroke", styles.button)} to="">
                {t("Trade")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
