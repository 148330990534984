import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import { BitmaidoContext } from "./context/bitmaidoContext";
import { isLoggedIn } from "./common/helper";
import RestService from "./adapter/base";
import CONST from "./common/const";
import { SimpleBitmaidoContext } from "./context/simpleBitmaidoContext";
import { ExchangeContext } from "./context/exchangeContext";
import { UserContext } from "./context/userContext";

export const BitmaidoProvider = ({ children, type }) => {
  const [symbols, setSymbols] = useState([]);
  const [symbolInfo, setSymbolInfo] = useState(
    localStorage.getItem("symbol")
      ? JSON.parse(localStorage.getItem("symbol"))
      : type === CONST.TRADE_TYPE.SPOT
      ? {
          symbol: "BTCUSDT",
          alias: "BTC/USDT",
          precision: 1,
          baseCurrency: "BTC",
          foreignCurrency: "USDT",
        }
      : {
          symbol: "BTCUSD",
          alias: "BTC/USD",
          precision: 2,
          baseCurrency: "BTC",
          foreignCurrency: "USD",
        }
  );
  const [symbolQuote, setSymbolQuote] = useState({
    price: 0,
    volume: 0,
    changeDir: CONST.CHANGE_DIRECTION.PLUS,
    change: 0,
  });
  const [prevSymbolName, setPrevSymbolName] = useState(
    type === CONST.TRADE_TYPE.SPOT ? "BTCUSDT" : "BTCUSD"
  );

  const [trades, setTrades] = useState([]);

  const prevOrders = useRef();
  const [orders, setOrders] = useState({
    bids: [],
    asks: [],
    rate: 0,
    direction: CONST.CHANGE_DIRECTION.PLUS,
  });

  const client = useRef();
  const currentSymbol = useRef();

  const [refreshOrderTable, setRefreshOrderTable] = useState(false);
  const [userLoggedin, setUserLoggedin] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [precisions, setPrecisions] = useState({});
  const [orderDecimal, setOrderDecimal] = useState(0);
  const prevOrderDecimal = useRef();

  const [datafeed, setDatafeed] = useState(null);

  const [ignoreSymbolParam, setIgnoreSymbolParam] = useState(false);

  useEffect(() => {
    RestService.get("/symbols")
      .then((resSymbols) => {
        const tmpSymbols = [];
        for (let i in resSymbols) {
          if (
            resSymbols[i].status === CONST.SYMBOL_STATUS.ACTIVE &&
            resSymbols[i].type === type
          ) {
            resSymbols[i].quote = {
              price: 0,
              volume: 0,
              changeDir: CONST.CHANGE_DIRECTION.PLUS,
            };
            tmpSymbols.push(resSymbols[i]);
          }
        }
        setSymbols(tmpSymbols);
      })
      .catch((err) => {
        console.log(err);
      });

    currentSymbol.current = symbolInfo;
    prevOrderDecimal.current = 0;
  }, []);

  useEffect(() => {
    setUserLoggedin(isLoggedIn());
  }, []);

  useEffect(() => {
    RestService.get("/currencies").then((res) => {
      if (!res.error) {
        setCurrencies(res);
        const data = {};
        for (let i in res) {
          data[res[i].currency] = res[i].precision;
        }
        setPrecisions(data);
      }
    });
  }, []);

  useEffect(() => {
    if (userLoggedin === false) {
      setAccounts([]);
      return;
    }

    RestService.get("/accounts").then((res) => {
      setAccounts(res);
    });
  }, [userLoggedin]);

  useEffect(() => {
    if (symbols.length === 0) {
      return;
    }

    if (client.current !== undefined) {
      return;
    }

    if (type === CONST.TRADE_TYPE.SPOT) {
      initSpotSocket();
    } else if (type === CONST.TRADE_TYPE.MARGIN) {
      initMarginSocket();
    }
  }, [symbols]);

  useEffect(() => {
    if (symbolInfo.symbol === prevSymbolName) {
      return;
    }

    // setTimeout(() => {
    if (type === CONST.TRADE_TYPE.SPOT) {
      processSpotSymbolChanged();
    } else if (type === CONST.TRADE_TYPE.MARGIN) {
      processMarginSymbolChanged();
    }
    // }, 1000);
  }, [symbolInfo]);

  useEffect(() => {
    if (!client.current) return;

    if (prevOrderDecimal.current !== undefined) {
      client.current.emit("unsubscribe", {
        topic: `crypto_spot:orderbook@${symbolInfo.symbol}${prevOrderDecimal.current}`,
      });
    }

    client.current.emit("subscribe", {
      topic: `crypto_spot:orderbook@${symbolInfo.symbol}${orderDecimal}`,
    });

    client.current.on(
      `crypto_spot:orderbook@${symbolInfo.symbol}${orderDecimal}`,
      (order) => {
        processSpotOrder(order);
      }
    );

    prevOrderDecimal.current = orderDecimal;
  }, [orderDecimal]);

  const processMarket = (market) => {
    const tmpSymbols = [...symbols];
    for (let i in tmpSymbols) {
      if (tmpSymbols[i].symbol === market.symbol) {
        if (tmpSymbols[i].quote.price > market.LastPrice) {
          tmpSymbols[i].quote.changeDir = CONST.CHANGE_DIRECTION.MINUS;
        } else {
          tmpSymbols[i].quote.changeDir = CONST.CHANGE_DIRECTION.PLUS;
        }
        tmpSymbols[i].quote.price = market.LastPrice;
        tmpSymbols[i].quote.volume = market.Volume;
        tmpSymbols[i].change = market.Change;

        setSymbols(tmpSymbols);
        break;
      }
    }

    const tmpSymbolQuote = symbolQuote;

    if (market.symbol === currentSymbol.current.symbol) {
      if (tmpSymbolQuote.price > market.LastPrice) {
        tmpSymbolQuote.changeDir = CONST.CHANGE_DIRECTION.MINUS;
      } else {
        tmpSymbolQuote.changeDir = CONST.CHANGE_DIRECTION.PLUS;
      }
      tmpSymbolQuote.price = market.LastPrice;
      tmpSymbolQuote.volume = market.Volume;
      tmpSymbolQuote.change = market.Change;
      tmpSymbolQuote.changePct = market.ChangePct;
    }

    setSymbolQuote(tmpSymbolQuote);
  };

  const processTrade = (trade) => {
    if (!trade.length) {
      let tmpTrades = trades;
      tmpTrades.unshift(trade);
      setTrades(tmpTrades.slice(0, 32));
    } else {
      setTrades(trade);
    }
  };

  const processMarginTrade = (trade) => {
    for (let i = trade.length - 1; i >= 0; i--) {
      trade[i].quantity = trade[i].size;
      trade[i].side =
        trade[i].side === CONST.TRADE_SIDE_STR.BUY
          ? CONST.TRADE_SIDE.BUY
          : CONST.TRADE_SIDE.SELL;
      trade[i].time = trade[i].trade_time_ms;

      processTrade(trade[i]);
    }
  };

  const processOrder = (order) => {
    const rate =
      order.bids.length === 0
        ? order.asks.length > 0
          ? order.asks[0][0]
          : 0
        : order.asks.length === 0
        ? order.bids[0][0]
        : parseFloat(order.bids[0][0]) +
          (parseFloat(order.asks[0][0]) - parseFloat(order.bids[0][0])) / 2;
    if (prevOrders.current === undefined || rate >= prevOrders.current.rate) {
      order.direction = CONST.CHANGE_DIRECTION.PLUS;
    } else {
      order.direction = CONST.CHANGE_DIRECTION.MINUS;
    }
    order.rate = rate;

    let askTotal = 0;
    order.asks.forEach((ask) => {
      askTotal += ask[2];
    });

    let bidTotal = 0;
    order.bids.forEach((bid) => {
      bidTotal += bid[2];
    });

    order.askTotal = askTotal;
    order.bidTotal = bidTotal;
    setOrders(order);
    prevOrders.current = order;
  };

  const processSpotOrder = (order) => {
    for (let i in order.asks) {
      order.asks[i][2] =
        parseFloat(order.asks[i][0]) * parseFloat(order.asks[i][1]);
    }
    for (let i in order.bids) {
      order.bids[i][2] =
        parseFloat(order.bids[i][0]) * parseFloat(order.bids[i][1]);
    }

    processOrder(order);
  };

  const processMarginOrder = (datas) => {
    let orders = [];
    datas.forEach((data) => {
      orders.push([
        data.price,
        parseFloat(data.size) / parseFloat(data.price),
        data.size,
      ]);
    });

    processOrder({
      bids: orders.slice(5, 24),
      asks: orders.slice(25, 45),
    });
  };

  const initSpotSocket = () => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT, {
      path: "/bitmaido",
    });

    socket.on("connect", () => {
      console.log(`Client connected: ${socket.id}`);

      socket.emit("subscribe", {
        topic: "crypto_spot:market",
      });

      socket.emit("subscribe", {
        topic: `crypto_spot:trade@${symbolInfo.symbol}`,
      });

      socket.emit("subscribe", {
        topic: `crypto_spot:orderbook@${symbolInfo.symbol}${orderDecimal}`,
      });
    });

    socket.on("disconnect", (reason) =>
      console.log(`Client disconnected: ${reason}`)
    );

    socket.on("connect_error", (reason) =>
      console.log(`Client connect_error: ${reason.stack}`)
    );

    socket.on("crypto_spot:market", (market) => {
      processMarket(market);

      if (datafeed != null) {
        datafeed.refreshLastCandle(market.symbol, market.LastPrice);
      }
    });

    socket.on("crypto_spot:trade", (trade) => {
      processTrade(trade);
    });
    socket.on(
      `crypto_spot:orderbook@${symbolInfo.symbol}${orderDecimal}`,
      (order) => {
        processSpotOrder(order);
      }
    );

    client.current = socket;
  };

  const processSpotSymbolChanged = () => {
    if (symbolInfo.symbol === prevSymbolName) {
      return;
    }

    if (!client.current) {
      return;
    }

    client.current.emit("unsubscribe", {
      topic: `crypto_spot:trade@${prevSymbolName}`,
    });
    client.current.emit("subscribe", {
      topic: `crypto_spot:trade@${symbolInfo.symbol}`,
    });

    client.current.emit("unsubscribe", {
      topic: `crypto_spot:orderbook@${prevSymbolName}${orderDecimal}`,
    });
    client.current.emit("subscribe", {
      topic: `crypto_spot:orderbook@${symbolInfo.symbol}0`,
    });
    client.current.on(
      `crypto_spot:orderbook@${symbolInfo.symbol}0`,
      (order) => {
        processSpotOrder(order);
      }
    );

    currentSymbol.current = symbolInfo;
    setPrevSymbolName(symbolInfo.symbol);
    prevOrderDecimal.current = 0;
    setOrderDecimal(0);
  };

  const initMarginSocket = () => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT, {
      path: "/bitmaido",
    });

    socket.on("connect", () => {
      console.log(`Client connected: ${socket.id}`);

      socket.emit("subscribe", {
        topic: `crypto_margin:orderbook@${symbolInfo.symbol}`,
      });

      socket.emit("subscribe", {
        topic: `crypto_margin:trade@${symbolInfo.symbol}`,
      });

      socket.emit("subscribe", {
        topic: "crypto_margin:market",
      });
    });

    socket.on("disconnect", (reason) =>
      console.log(`Client disconnected: ${reason}`)
    );

    socket.on("connect_error", (reason) =>
      console.log(`Client connect_error: ${reason.stack}`)
    );

    socket.on(
      `crypto_margin:orderbook@${symbolInfo.symbol}@snapshot`,
      (data) => {
        processMarginOrder(data);
      }
    );

    socket.on("crypto_margin:trade", (trade) => {
      processMarginTrade(trade);
    });
    socket.on("crypto_margin:market", (market) => {
      processMarket(market);
    });

    client.current = socket;
  };

  const processMarginSymbolChanged = () => {
    if (symbolInfo.symbol === prevSymbolName) {
      return;
    }

    client.current.emit("unsubscribe", {
      topic: `crypto_margin:orderbook@${prevSymbolName}`,
    });

    client.current.emit("unsubscribe", {
      topic: `crypto_margin:trade@${prevSymbolName}`,
    });

    client.current.emit("subscribe", {
      topic: `crypto_margin:orderbook@${symbolInfo.symbol}`,
    });

    client.current.emit("subscribe", {
      topic: `crypto_margin:trade@${symbolInfo.symbol}`,
    });

    client.current.on(
      `crypto_margin:orderbook@${symbolInfo.symbol}@snapshot`,
      (data) => {
        processMarginOrder(data);
      }
    );

    currentSymbol.current = symbolInfo;
    setPrevSymbolName(symbolInfo.symbol);
  };

  const handleSetSymbolInfo = (symbolInfo) => {
    setSymbolInfo(symbolInfo);
    localStorage.setItem("symbol", JSON.stringify(symbolInfo));
  };

  return (
    <BitmaidoContext.Provider
      value={{
        symbols,
        symbolInfo,
        symbolQuote,
        trades,
        orders,
        refreshOrderTable,
        userLoggedin,
        accounts,
        currencies,
        precisions,
        datafeed,
        ignoreSymbolParam,
        setSymbols,
        setSymbolInfo: handleSetSymbolInfo,
        setRefreshOrderTable,
        setUserLoggedin,
        setAccounts,
        setDatafeed,
        setOrderDecimal,
        setIgnoreSymbolParam,
      }}
    >
      {children}
    </BitmaidoContext.Provider>
  );
};

const initials = [
  {
    title: "Bitcoin",
    currency: "BTC",
    symbol: "BTCUSDT",
    alias: "BTC-USDT",
    price: 0,
    money: 0,
    change: 0,
    image: "images/content/currency/bitcoin.svg",
    url: "/exchange/spot",
    precision: "2",
  },
  {
    title: "Ethereum",
    currency: "ETH",
    symbol: "ETHUSDT",
    alias: "ETH-USDT",
    price: 0,
    money: 0,
    change: 0,
    image: "images/content/currency/ethereum.svg",
    url: "/exchange/spot",
    precision: "2",
  },
  {
    title: "Bitcoin Cash",
    currency: "BCH",
    symbol: "BCHUSDT",
    alias: "BCH-USDT",
    price: 0,
    money: 0,
    change: 0,
    image: "images/content/currency/bitcoin-cash.svg",
    url: "/exchange/spot",
    precision: "2",
  },
  {
    title: "TRON",
    currency: "TRX",
    symbol: "TRXUSDT",
    alias: "TRX-USDT",
    price: 0,
    money: 0,
    change: 0,
    image: "images/content/currency/tron.svg",
    url: "/exchange/spot",
    precision: "5",
  },
  {
    title: "Lite Coin",
    currency: "LTC",
    symbol: "LTCUSDT",
    alias: "LTC-USDT",
    price: 0,
    money: 0,
    change: 0,
    image: "images/content/currency/litecoin.svg",
    url: "/exchange/spot",
    precision: "1",
  },
  {
    title: "Ripple",
    currency: "XRP",
    symbol: "XRPUSDT",
    alias: "XRP-USDT",
    price: 0,
    money: 0,
    change: 0,
    image: "images/content/currency/ripple.svg",
    url: "/exchange/spot",
    precision: "3",
  },
];

export const SimpleBitmaidoProvider = ({ children, type }) => {
  const [items, setItems] = useState(initials);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT, {
      path: "/bitmaido",
    });

    socket.on("connect", () => console.log(`Client connected: ${socket.id}`));

    socket.on("disconnect", (reason) =>
      console.log(`Client disconnected: ${reason}`)
    );

    socket.on("connect_error", (reason) =>
      console.log(`Client connect_error: ${reason.stack}`)
    );

    socket.emit("subscribe", {
      topic: "crypto_spot:market",
    });

    socket.on("crypto_spot:market", (market) => {
      processMarket(market);
    });
  }, []);

  useEffect(() => {
    RestService.get("/currency/list", {
      where: {
        type: CONST.CURRENCY_TYPE.USER_DEPLOYED,
      },
      limit: 10,
      offset: 0,
      orderBy: ["created_at", "asc"],
      sns: true,
    }).then((currencies) => {
      if (!currencies) {
        return;
      }

      let items = [];
      currencies.rows.map((currency) => {
        items.push({
          title: currency.description,
          currency: currency.currency,
          symbol: `${currency.currency}USDT`,
          alias: `${currency.currency}-USDT`,
          price: currency.price,
          money: 0,
          change: 0,
          image: currency.avatar,
          url: "/exchange/spot",
          precision: "1",
          changePct: 0,
          lockTime: currency.lock_time,
          type: currency.type,
          icoStatus: currency.ico_status,
          promotion_url: currency.promotion_url,
          category: currency.category,
          target: currency.target_amount,
          saleAmount: currency.sale_amount,
          sns: currency.sns ?? [],
        });
      });
      setCurrencies(items);
    });
  }, []);

  const processMarket = (market) => {
    let tmpItems = [...items];
    for (let i in tmpItems) {
      if (tmpItems[i].symbol === market.symbol) {
        tmpItems[i].price = market.LastPrice;
        tmpItems[i].money = market.Volume;
        tmpItems[i].change = market.Change;
        tmpItems[i].changePct = market.ChangePct;

        setItems(tmpItems);
        break;
      }
    }

    const tmpCurrencies = [...currencies];
    for (let i in tmpCurrencies) {
      if (tmpCurrencies[i].symbol === market.symbol) {
        tmpCurrencies[i].price = market.LastPrice;
        tmpCurrencies[i].money = market.Volume;
        tmpCurrencies[i].change = market.Change;
        tmpCurrencies[i].changePct = market.ChangePct;

        setCurrencies(tmpCurrencies);
        break;
      }
    }
  };

  return (
    <SimpleBitmaidoContext.Provider
      value={{
        items,
        currencies,
        processMarket,
      }}
    >
      {children}
    </SimpleBitmaidoContext.Provider>
  );
};

export const ExchangeProvider = ({ children }) => {
  const [selectedOrder, setSelectedOrder] = useState();

  return (
    <ExchangeContext.Provider
      value={{
        selectedOrder,
        setSelectedOrder,
      }}
    >
      {children}
    </ExchangeContext.Provider>
  );
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();

  const updateUser = () => {
    RestService.get("/user").then((res) => {
      if (!res.error) {
        setUser(res);
      }
    });
  };

  useEffect(() => {
    updateUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
