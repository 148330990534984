import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./SignUp.module.sass";
import Login from "../../components/Login";
import Form from "./Form";
import Confirm from "./Confirm";
import Code from "./Code";
import { isLoggedIn } from "../../common/helper";

import { useTranslation } from 'react-i18next';

const SignUp = () => {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (isLoggedIn()) {
      window.location = "/exchange/spot";
    }
  }, [])

  return (
    <Login
      content={t("Already have an account?")}
      linkText={t("Login")}
      linkUrl="/sign-in"
    >
      {activeIndex === 0 && <Form goNext={() => setActiveIndex(1)} />}
      {activeIndex === 1 && <Confirm goNext={() => setActiveIndex(2)} />}
      {activeIndex === 2 && <Code />}
    </Login>
  );
};

export default SignUp;
