import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Main.module.sass";
import Icon from "../../../components/Icon";

import numeral from "numeral";

import CONST from "../../../common/const";
import { useBitmaidoContext } from "../../../hooks/useBitmaidoContext";

import { useTranslation } from "react-i18next";

const Main = () => {
  const { t } = useTranslation();

  const { symbolInfo, symbolQuote } = useBitmaidoContext();
  const [numeralFormat, setNumeralFormat] = useState("0,0.000");

  useEffect(() => {
    let format = "0,0.";
    for (let i = 0; i < symbolInfo.precision; i++) {
      format = format + "0";
    }

    setNumeralFormat(format);
  }, [symbolInfo]);

  return (
    <div className={styles.main}>
      <div className={styles.details}>
        <div className={styles.box}>
          <div className={styles.line}>
            <div className={styles.info}>
              {symbolInfo.baseCurrency}/{symbolInfo.foreignCurrency}
            </div>
            {/* <div className={cn("category-green", styles.category)}>10x</div> */}
          </div>
          {/* <div className={styles.content}>Bitcoin</div> */}
        </div>
        <div className={styles.box}>
          <div
            className={
              symbolQuote.changeDir === CONST.CHANGE_DIRECTION.PLUS
                ? styles.pricePlus
                : styles.priceMinus
            }
          >
            {symbolQuote.price === 0
              ? "-.--"
              : numeral(symbolQuote.price).format(numeralFormat)}
          </div>
          {/* <div className={styles.content}>
            <Icon name="coin" size="16" /> {numeral(symbolQuote.price).format(numeralFormat)}
          </div> */}
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.subtitle}>
            <Icon name="clock" size="16" />
            {t("24h change")}
          </div>
          <div
            className={
              parseFloat(symbolQuote.change) > 0
                ? styles.contentPlus
                : styles.contentMinus
            }
          >
            {symbolQuote.change === 0
              ? "-.--"
              : numeral(symbolQuote.change).format(numeralFormat)}{" "}
            {symbolQuote.change === 0
              ? "-.--"
              : numeral(symbolQuote.changePct).format("0,0.00")}
            %
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.subtitle}>
            <Icon name="chart" size="16" />
            {t("24h volume")}
          </div>
          <div className={styles.content} style={{ color: "" }}>
            {symbolQuote.volume === 0
              ? "-.--"
              : numeral(symbolQuote.volume).format(numeralFormat)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
