import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as LandingIcon } from "../../assets/svg/ico_landing.svg";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import RestService from "../../adapter/base";
import styles from "./BuyCrypto.module.sass";
import Modal from "../../components/Modal";
import BuyContent from "./Modal";
import UserHeader from "../User/UserHeader";
import Story from "../User/Story";
import FAQ from "../User/FAQ";
import Update from "../User/Update";
import Comment from "../User/Comment";
import Recommends from "../User/Recommends";
import Actions from "../User/Actions";
import SendComment from "../User/SendComment";
import SendRecommend from "../User/SendRecommend";
import { USER_TAB } from "../../common/const";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import jwt from "jsonwebtoken";

const BuyCrypto = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const currency = params.get("currency");
  const [CurrencyInfo, setCurrencyInfo] = useState({});
  const [soldAmount, setSoldAmount] = useState(0);
  const [userID, setUserID] = useState(0);
  const [userTab, setUserTab] = useState(USER_TAB.STORY);
  const [showSendComment, setShowSendComment] = useState(false);
  const [showSendRecommend, setShowSendRecommend] = useState(false);
  const [story, setStory] = useState("");
  const [user, setUser] = useState();

  useEffect(() => {
    if (!userID) {
      return;
    }

    RestService.get("/user", {
      id: userID,
      currency,
    })
      .then((res) => {
        if (res.err) {
          return;
        }
        setUser(res);
        setStory(res.story);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userID]);

  useEffect(() => {
    RestService.get("/currency/list", {
      where: {
        currency,
      },
      limit: 10,
      offset: 0,
      orderBy: ["created_at", "asc"],
    }).then((currencies) => {
      if (currencies.rows.length !== 0) {
        setCurrencyInfo(currencies.rows[0]);
        setUserID(currencies.rows[0].user_id);
      }
    });
  }, []);

  const [totalSecs, setTotalSecs] = useState(0);

  useEffect(() => {
    if (!CurrencyInfo) return;
    setInterval(() => {
      const tmpTotalSec =
        new Date(CurrencyInfo.lock_time).getTime() - new Date().getTime();
      tmpTotalSec > 0 && setTotalSecs(tmpTotalSec);
    }, 1000);
  }, [CurrencyInfo]);

  const [visibleModal, setVisibleModal] = useState(false);

  const getSoldAmount = () => {
    RestService.get("/tokenSale/info", { currency }).then((info) => {
      setSoldAmount(info.sold);
    });
  };

  useEffect(() => {
    getSoldAmount();
  }, []);

  useEffect(() => {
    setShowSendComment(false);
    setShowSendRecommend(false);
  }, [userTab]);

  useEffect(() => {
    if (!visibleModal) return;
    try {
      const token = window.localStorage.getItem("token");
      const userInfo = jwt.verify(token, process.env.REACT_APP_SALT);
      if (!userInfo.id) {
        history.push("/sign-in");
      }
    } catch {
      history.push("/sign-in");
    }
  }, [visibleModal]);

  return (
    <>
      <div className={cn("section", styles.main)}>
        <div
          style={{
            width: "30%",
            display: "flex",
            flexDirection: "column",
          }}
          className={styles.story}
        >
          <div className={styles.title}>
            <label>{t("A41 Personalized Token Sales")}</label>
            <span>
              {t(
                "A41 Tokens are leading tokens of the blockchain world. Buy now."
              )}
            </span>
          </div>
          <button
            className={cn("button-large", styles.button)}
            onClick={() => setVisibleModal(true)}
          >
            {t("Buy Now", { value: currency })}
          </button>
        </div>
        <div className={styles.liveContainer}>
          <LandingIcon />
          <span className={styles.live}>
            <div className={styles.timeArea}>
              <label>{t("Presale is Live Now")}</label>
              <div className={styles.timeLabel}>{t("Presale ends in")}</div>
              <div className={styles.timeContent}>
                {(() => {
                  const days = Math.floor(totalSecs / (1000 * 3600 * 24));
                  const hours = Math.floor(
                    (totalSecs - days * 1000 * 3600 * 24) / (1000 * 3600)
                  );
                  const minutes = Math.floor(
                    (totalSecs -
                      days * 1000 * 3600 * 24 -
                      hours * 1000 * 3600) /
                      (1000 * 60)
                  );
                  const second = Math.floor(totalSecs / 1000) % 60;
                  return (
                    <>
                      <label>{totalSecs && totalSecs > 0 ? days : "--"}</label>
                      <span>d</span>
                      <label>{totalSecs && totalSecs > 0 ? hours : "--"}</label>
                      <span>h</span>
                      <label>
                        {totalSecs && totalSecs > 0 ? minutes : "--"}
                      </label>
                      <span>m</span>
                      <label>
                        {totalSecs && totalSecs > 0 ? second : "--"}
                      </label>
                      <span>s</span>
                    </>
                  );
                })()}
              </div>
            </div>
            <div className={styles.progressArea}>
              <label>{t("Pre Sale")}</label>
              <div className={styles.progressBar}>
                <span
                  className={styles.progress}
                  style={{
                    width: `${(isNaN(
                      (soldAmount * 100) / CurrencyInfo.target_amount
                    )
                      ? 0
                      : (soldAmount * 100) / CurrencyInfo.target_amount
                    ).toFixed(2)}%`,
                  }}
                >
                  {`${(isNaN((soldAmount * 100) / CurrencyInfo.target_amount)
                    ? 0
                    : (soldAmount * 100) / CurrencyInfo.target_amount
                  ).toFixed(2)}%`}
                </span>
              </div>
              <div className={styles.progressLabel}>
                <label>
                  {soldAmount !== undefined
                    ? soldAmount.toLocaleString()
                    : "--"}{" "}
                  sold
                </label>
                <label>
                  {CurrencyInfo?.target_amount > 0
                    ? CurrencyInfo.target_amount.toLocaleString()
                    : "--"}{" "}
                  goal
                </label>
              </div>
            </div>
          </span>
        </div>
      </div>
      <UserHeader
        selected={userTab}
        handleTab={setUserTab}
        token={user ? user.currency : ""}
        handleShowComment={setShowSendComment}
        handleShowRecommend={setShowSendRecommend}
      />
      {!showSendComment && !showSendRecommend && (
        <>
          {userTab === USER_TAB.STORY && <Story story={story} />}
          {userTab === USER_TAB.FAQ && <FAQ userID={userID} />}
          {userTab === USER_TAB.UPDATE && <Update user={user} />}
          {userTab === USER_TAB.COMMENT && (
            <Comment currency={user ? user.currency : ""} />
          )}
          {userTab === USER_TAB.RECOMMEND && (
            <Recommends currency={user ? user.currency : ""} />
          )}
          <Actions token={user ? user.currency : ""} />
        </>
      )}
      {showSendComment && (
        <SendComment
          currency={user ? user.currency : ""}
          handleShowComment={setShowSendComment}
        />
      )}
      {showSendRecommend && (
        <SendRecommend
          currency={user ? user.currency : ""}
          handleShowRecommend={setShowSendRecommend}
        />
      )}
      <Modal
        visible={visibleModal}
        onClose={() => {
          setVisibleModal(false);
          getSoldAmount();
        }}
      >
        <BuyContent currency={currency} sold={soldAmount} />
      </Modal>
    </>
  );
};

export default BuyCrypto;
