import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./WalletOverview.module.sass";
import Wallet from "../../components/Wallet";
import Icon from "../../components/Icon";
import Dropdown from "../../components/Dropdown";
import AccountBalances from "./AccountBalances";
import AssetBalances from "./AssetBalances";
import Integrations from "./Integrations";
import RestService from "../../adapter/base";
import { useBitmaidoContext } from "../../hooks/useBitmaidoContext";
import { numberWithCommas } from "../../common/helper";
import CONST from "../../common/const";

import { useTranslation } from "react-i18next";

const optionsCurrency = ["USD", "EUR", "RUB"];

const WalletOverview = () => {
  const { t } = useTranslation();
  const [totalBalance, setTotalBalance] = useState(0.0);
  const [totalSpotBalance, setTotalSpotBalance] = useState(0.0);
  const [totalMarginBalance, setTotalMarginBalance] = useState(0.0);
  const [BTCUSDRate, setBTCUSDRate] = useState(0.0);
  const [addedAccount, setAddedAccount] = useState(0);
  const { symbols, accounts, precisions, currencies } = useBitmaidoContext();

  useEffect(() => {
    if (addedAccount === accounts.length) return;

    let added = 0;
    let total = 0.0;
    let totalSpot = 0.0;

    for (let j in accounts) {
      for (let i in symbols) {
        if (symbols[i].symbol === "BTCUSDT") {
          setBTCUSDRate(symbols[i].quote.price);
        }
        if (symbols[i].foreign_currency !== "USDT") continue;

        if (accounts[j].currency === "USDT") {
          total += parseFloat(accounts[j].balance_total);
          if (accounts[j].type == CONST.TRADE_TYPE.SPOT) {
            totalSpot += parseFloat(accounts[j].balance_total);
          }
          added++;
          break;
        }

        if (
          symbols[i].base_currency === accounts[j].currency &&
          symbols[i].foreign_currency === "USDT"
        ) {
          total +=
            parseFloat(accounts[j].balance_total) * symbols[i].quote.price;
          if (accounts[j].type == CONST.TRADE_TYPE.SPOT) {
            totalSpot +=
              parseFloat(accounts[j].balance_total) * symbols[i].quote.price;
          }

          if (symbols[i].quote.price !== 0) added++;
          break;
        }
      }
    }
    setTotalBalance(total);
    setTotalSpotBalance(totalSpot);
    setTotalMarginBalance(total - totalSpot);
    setAddedAccount(added);
  }, [accounts, symbols, addedAccount]);

  return (
    <Wallet>
      <div className={styles.top}>
        <div className={styles.line}>
          <h4 className={cn("h4", styles.title)}>{t("Overview")}</h4>
        </div>
        <div className={styles.details}>
          <div className={styles.info}>{t("Total balance")}</div>
          <div className={styles.currency}>
            <div className={styles.number}>
              {numberWithCommas(totalBalance.toFixed(precisions["USDT"]))}
            </div>
            <div className={cn("category-green", styles.category)}>USDT</div>
          </div>
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.head}>{t("Account Balances")}</div>
          <div className={styles.body}>
            <AccountBalances
              spot={totalSpotBalance}
              margin={totalMarginBalance}
              rate={BTCUSDRate}
            />
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.head}>{t("Asset Balances")}</div>
          <div className={styles.body}>
            <AssetBalances accounts={accounts} currencies={currencies} />
          </div>
        </div>
        {/* <div className={styles.item}>
          <div className={styles.head}>Integrations</div>
          <div className={styles.body}>
            <Integrations />
          </div>
</div> */}
      </div>
    </Wallet>
  );
};

export default WalletOverview;
