import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Withdraw.module.sass";
import Icon from "../Icon";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import Checkbox from "../Checkbox";
import Successfully from "./Successfully";
import CONST from "../../common/const";
import RestService from "../../adapter/base";
import { toast } from "react-toastify";
import { useBitmaidoContext } from "../../hooks/useBitmaidoContext";
import { useTranslation } from "react-i18next";

const Withdraw = () => {
  const { t } = useTranslation();
  const [visibleWithdraw, setVisibleWithdraw] = useState(true);
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false);
  const [totalBalance, setTotalBalance] = useState(0.0);
  const [available, setAvailable] = useState(0.0);
  const { symbols, accounts, precisions } = useBitmaidoContext();
  const [currency, setCurrency] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [chains, setChains] = useState([]);
  const [chain, setChain] = useState("");
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("0");
  const [minAmount, setMinAmount] = useState(undefined);
  const [minFee, setMinFee] = useState(undefined);

  const handleClick = () => {
    if (!address) {
      toast.error(t("Please input the withdraw address."));
      return;
    }
    if (available === 0) {
      toast.error(t("There is no available ", { currency }));
      return;
    }
    const amountF = parseFloat(amount);
    if (amountF <= 0 || amountF > available) {
      toast.error(t("Please input the valid amount."));
      return;
    }

    if (amountF <= minAmount) {
      toast.error(t("Withdraw amount must be larger than minimum amount"));
      return;
    }

    RestService.post("/withdrawal/new", {
      address: address,
      currency: currency,
      amount: amount,
      chain: `${currency}-${chain}`,
    }).then((res) => {
      if (!res.error) {
        setVisibleWithdraw(false);
        setVisibleSuccessfully(true);
      } else {
        toast.error(
          t("The withdraw failed. Reason: ", {
            error: t("Internal Server Error"),
          })
        );
      }
    });
  };

  const onChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    console.log(value);
    if (value.split(".").length - 1 > 1) {
      return;
    }
    setAmount(value);
  };

  const handleMaxAmount = () => {
    setAmount(available);
  };

  useEffect(() => {
    let total = 0.0;
    for (let i in symbols) {
      if (symbols[i].foreign_currency !== "USDT") continue;
      for (let j in accounts) {
        if (accounts[j].currency === "USDT") {
          total += parseFloat(accounts[j].balance_total);
          break;
        }

        if (symbols[i].base_currency === accounts[j].currency) {
          total +=
            parseFloat(accounts[j].balance_total) * symbols[i].quote.price;
          break;
        }
      }
    }
    setTotalBalance(total);
  }, [accounts, symbols]);

  useEffect(() => {
    if (currency) {
      let amount = 0.0;
      for (let i in accounts) {
        if (currency === accounts[i].currency) {
          amount = accounts[i].balance_total - accounts[i].balance_order;
          break;
        }
      }
      setAvailable(amount);
    }
  }, [currency, accounts]);

  useEffect(() => {
    if (currency === "") return;
    setChain("");
    RestService.get("/deposit/address", {
      method: CONST.TRADE_METHOD.DIRECT,
      trade_type: CONST.TRADE_TYPE.SPOT,
      currency: currency,
      address: "test",
      amount: 1,
    }).then((res) => {
      const deposits = JSON.parse(res);
      const tempChains = [];

      for (let i in Object.keys(deposits[currency])) {
        tempChains.push(Object.keys(deposits[currency])[i]);
      }

      setChains(tempChains);
    });
  }, [currency]);

  useEffect(() => {
    if (chain === "") return;
    RestService.get("/withdrawal/minamount", {
      currency: currency,
      chain: chain,
    }).then((res) => {
      setMinAmount(parseFloat(res.minAmount));
      setMinFee(parseFloat(res.minFee));
    });
  }, [currency, chain]);

  useEffect(() => {
    let list = [];
    for (let i in accounts) {
      list.push(accounts[i].currency);
    }
    setCurrencyOptions(list);
    if (list.length > 0) {
      setCurrency(list[0]);
    }
  }, [accounts]);

  return (
    <>
      {visibleWithdraw && (
        <div className={styles.withdraw}>
          <div className={cn("h4", styles.title)}>
            <Icon name="arrow-left" size="32" />
            {t("Withdraw")}
          </div>
          <Dropdown
            className={styles.dropbox}
            classDropdownHead={styles.dropdownHead}
            label={t("Currency")}
            value={currency}
            setValue={setCurrency}
            options={currencyOptions}
          />
          <Dropdown
            className={styles.dropbox}
            classDropdownHead={styles.dropdownHead}
            label={t("Chain")}
            value={chain}
            setValue={setChain}
            options={chains}
          />
          <TextInput
            className={styles.field}
            label={t("Address")}
            name="address"
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
          <div className={styles.wrap}>
            <div
              style={{
                display: "flex",
              }}
            >
              <div className={styles.category}>{t("Available")}</div>
              <div className={styles.details}>
                <div className={styles.currency}>
                  {available.toFixed(precisions[currency])} {currency}
                </div>
              </div>
            </div>
            {minAmount && (
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                <div className={styles.category}>Minimum Amount</div>
                <div className={styles.details}>
                  <div className={styles.currency}>
                    {minAmount.toFixed(precisions[currency])} {currency}
                  </div>
                </div>
              </div>
            )}
            {minFee && (
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                <div className={styles.category}>Minimum Fee</div>
                <div className={styles.details}>
                  <div className={styles.currency}>
                    {minFee.toFixed(precisions[currency])} {currency}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.box}>
            <TextInput
              className={styles.field}
              label={t("Amount to withdraw")}
              name="amount"
              type="text"
              value={amount}
              onChange={onChange}
              required
            />
            <button
              className={cn("button-stroke button-small", styles.button)}
              onClick={handleMaxAmount}
            >
              {t("Max Amount")}
            </button>
          </div>
          <button
            className={cn("button", styles.button)}
            onClick={() => handleClick()}
          >
            {t("Withdraw")}
          </button>
        </div>
      )}
      {visibleSuccessfully && (
        <Successfully
          currency={currency}
          amount={amount}
          status="pending"
          address={address}
        />
      )}
    </>
  );
};

export default Withdraw;
