import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Main.module.sass";
import ScrollButton from "../../../components/ScrollButton";
import Cards from "./Cards";

import { useTranslation } from "react-i18next";
import Play from "../../../components/Play";
import Steps from "../Steps";

import Trend from "../Trend";
import { useUserContext } from "../../../hooks/useUserContext";
import { useHistory } from "react-router";
import MySns from "../MySns";

const Main = ({ scrollToRef, trendTab, handleTrendTab }) => {
  const history = useHistory();
  const { user } = useUserContext();
  const { t } = useTranslation();

  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn("h1", styles.title)}>
            {t("A marketplace that supports each other's dreams")}
          </h1>
          <div className={styles.text}>{t("All For One, One For All.")}</div>
          <button
            onClick={() => history.push(user ? "/exchange/spot" : "/sign-up")}
          >
            {t("Start A Fundraiser")}
          </button>
          <img src="/images/content/banner12.png" alt="" />
        </div>
      </div>

      <Cards
        className={styles.cards_container}
        trendTab={trendTab}
        handleTrendTab={handleTrendTab}
      />

      <Trend trendTab={trendTab} />
      <Steps />
    </div>
  );
};

export default Main;
