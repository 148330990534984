import React, { useState } from "react";
import cn from "classnames";
import styles from "./EnterAmount.module.sass";
import Icon from "../../../components/Icon";

import { useTranslation } from 'react-i18next';

const EnterAmount = ({ goNext, goBack }) => {
  const { t } = useTranslation();

  const [price, setPrice] = useState("1.356");

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <form className={styles.item} action="" onSubmit={() => handleSubmit()}>
      <div className={styles.control}>
        <button className={styles.back} onClick={goBack}>
          <Icon name="arrow-prev" size="14" />
          {t("Enter amount")}
        </button>
        <div className={styles.money}>
          {t("Buying Bitcoin")}
          <img src="/images/content/currency/bitcoin.svg" alt={t("Coin")} />
        </div>
      </div>
      <div className={styles.payment}>
        <div className={styles.field}>
          <div className={styles.value}>{price}</div>
          <input
            className={styles.input}
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div className={cn("h4", styles.sign)}>BTC</div>
      </div>
      <div className={styles.price}>
        {t("You will get ", {value: 56000.462548, currency: 'USD'})}
      </div>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={goNext}>
          {t("Sell")}
        </button>
      </div>
    </form>
  );
};

export default EnterAmount;
