import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import styles from "./SelectCurrency.module.sass";
import Dropdown from "../../../components/Dropdown";
import TextInput from "../../../components/TextInput";
import RestService from "../../../adapter/base";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import CONST from "../../../common/const";

const priceVariants = [];

const SelectCurrency = ({ goNext }) => {
  const { t } = useTranslation();

  const search = useLocation().search;
  const typeParam = new URLSearchParams(search).get("type");

  const [currency, setCurrency] = useState("");
  const [price, setPrice] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType] = useState(typeParam);
  const [depositMethod, setDepositMethod] = useState(t("Direct Transfer"));
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [currencyList, setCurrencyList] = useState({});
  const [net, setNet] = useState(undefined);
  const [chains, setChains] = useState([]);

  useEffect(() => {
    RestService.get("/currencies").then((res) => {
      let list = [];
      let info = {};
      for (let i in res) {
        list.push(res[i].currency);
        info[res[i].currency] = res[i];
      }
      setCurrencyOptions(list);
      setCurrencyList(info);
      if (list.length > 0) {
        setCurrency(list[0]);
      }
    });
  }, []);

  useEffect(() => {
    if (!currency || !currencyList || !currencyList[currency]) return;
    if (currencyList[currency].type === CONST.CURRENCY_TYPE.OKX) {
      setNet("");

      RestService.get("/deposit/address", {
        method: CONST.TRADE_METHOD.DIRECT,
        trade_type: CONST.TRADE_TYPE.SPOT,
        currency: currency,
        address: "test",
        amount: 1,
      }).then((res) => {
        const deposits = JSON.parse(res);
        const tempChains = [];

        for (let i in Object.keys(deposits[currency])) {
          tempChains.push(Object.keys(deposits[currency])[i]);
        }

        setChains(tempChains);
      });
    } else {
      setNet(undefined);
    }
  }, [currency, currencyList]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const onNext = () => {
    if (net === undefined) {
      if (!type) {
        toast.error(t("Deposit Type is not set."));
        return;
      }

      if (!address && depositMethod === t("Direct Transfer")) {
        toast.error(t("Your wallet address is invalid."));
        return;
      }
    } else {
      if (!net) {
        toast.error(t("Chain is not set."));
        return;
      }
    }

    if (!price) {
      toast.error(t("Deposit Amount is not set."));
      return;
    }

    if (price <= 0) {
      return;
    }
    if (!currency) {
      return;
    }

    goNext(depositMethod, type, price, currency, address, net);
  };

  return (
    <form className={styles.item} action="" onSubmit={handleSubmit}>
      <div className={styles.title}>
        {t("Select currency and payment method")}
      </div>
      {net === undefined && (
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.label}>{t("Select Deposit Method")}</div>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={depositMethod}
              setValue={setDepositMethod}
              options={[t("Direct Transfer"), t("Exchange Withdraw")]}
              validate={{
                empty: true,
              }}
            />
          </div>

          <div className={styles.col}>
            <div className={styles.label}>{t("Select Wallet Type")}</div>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={type}
              setValue={setType}
              options={[t("Spot"), t("Margin")]}
              validate={{
                empty: true,
              }}
            />
          </div>
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.label}>{t("Select currency")}</div>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={currency}
            setValue={setCurrency}
            options={currencyOptions}
          />
        </div>
        {net !== undefined ? (
          <div className={styles.col}>
            <div className={styles.label}>{t("Select chain")}</div>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={net}
              setValue={setNet}
              options={chains}
            />
          </div>
        ) : (
          <div className={styles.col}>
            <div className={styles.label}>{t("Enter Amount")}</div>
            <TextInput
              type="number"
              className={styles.field}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              validate={{
                larger: 0,
              }}
            />
          </div>
        )}
      </div>
      {net !== undefined && (
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.label}>{t("Enter Amount")}</div>
            <TextInput
              type="number"
              className={styles.field}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              validate={{
                larger: 0,
              }}
            />
          </div>
        </div>
      )}
      {depositMethod === t("Direct Transfer") && net === undefined && (
        <div className={styles.row}>
          <div className={styles.colfull}>
            <div className={styles.label}>{t("Enter Your Address")}</div>
            <TextInput
              className={styles.field}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              validate={{
                empty: true,
              }}
            />
          </div>
        </div>
      )}
      <div className={styles.payment}>
        <div className={styles.field}>
          <div className={styles.value}>{price}</div>
          <input
            className={styles.input}
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.price}>
        <span>{currency}</span>
      </div>
      <div className={styles.variants}>
        {priceVariants.map((x, index) => (
          <button
            className={cn("button-stroke button-small", styles.button)}
            type="button"
            onClick={() => setPrice(x.replace("$", ""))}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={onNext}>
          {t("Continue")}
        </button>
      </div>
    </form>
  );
};

export default SelectCurrency;
