import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Activity.module.sass";
import Icon from "../../components/Icon";
import Actions from "../../components/Actions";
import Calendar from "../../components/Calendar";
import Export from "../../components/Export";
import Dropdown from "../../components/Dropdown";
import Table from "./Table";
import RestService from "../../adapter/base";

import { useTranslation } from "react-i18next";

const Activity = () => {
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const navigation = [t("Deposit"), t("Withdrawal"), t("Referrals")];

  const [email, setEmail] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);

  const [sorting, setSorting] = useState(navigation[0]);
  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [referrals, setReferrals] = useState([]);

  const [startDate, setStartDate] = useState(new Date("1999/1/1"));
  const [endDate, setEndDate] = useState(new Date("2100/1/1"));

  useEffect(() => {
    RestService.get("/deposit/history").then((res) => {
      if (!res.error) {
        setDeposits(res);
      }
    });

    RestService.get("/withdrawal/history").then((res) => {
      if (!res.error) {
        setWithdrawals(res);
      }
    });

    RestService.get("/refer/payouts").then((res) => {
      if (!res.error) {
        setReferrals(res);
      }
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const onChangeDate = (start, end) => {
    if (start === null || end === null) {
      setStartDate(new Date("1999/1/1"));
      setEndDate(new Date("2100/1/1"));
      return;
    }
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className={styles.activity}>
      <div className={cn("container", styles.container)}>
        <div className={styles.body}>
          <div className={styles.top}>
            <div className={styles.nav}>
              {navigation.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={navigation[activeIndex]}
                setValue={(value) => setActiveIndex(navigation.indexOf(value))}
                options={navigation}
              />
            </div>
            <form
              className={styles.form}
              action=""
              onSubmit={(e) => handleSubmit(e)}
            >
              <input
                className={styles.input}
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                name="search"
                placeholder={t("Search")}
                required
              />
              <button className={styles.result}>
                <Icon name="search" size="20" />
              </button>
            </form>
            <Calendar className={styles.calendar} onChangeDate={onChangeDate} />
          </div>
          <div className={styles.line}>
            <h4 className={cn("h4", styles.title)}>
              {navigation[activeIndex]} {t("Activity")}
            </h4>
            {/* <Export className={styles.export} /> */}
          </div>
          <Table
            className={styles.table}
            items={
              activeIndex === 0
                ? deposits
                : activeIndex === 1
                ? withdrawals
                : referrals
            }
            startDate={startDate}
            endDate={endDate}
            search={search}
            activeIndex={activeIndex}
          />
        </div>
        {/* <Actions className={styles.actions} /> */}
      </div>
    </div>
  );
};

export default Activity;
