import React, { useState } from "react";
import Bidding from "../../components/Bidding";
import SelectCrypto from "../../components/SelectCrypto";
import EnterAmount from "./EnterAmount";
import Confirm from "./Confirm";

import { useTranslation } from 'react-i18next';

const SellCrypto = () => {
  const { t } = useTranslation();

  const steps = [t("Select crypto"), t("Enter amount"), t("Confirm")];

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <Bidding title={t("Sell crypto")} items={steps} activeIndex={activeIndex}>
        {activeIndex === 0 && <SelectCrypto goNext={() => setActiveIndex(1)} />}
        {activeIndex === 1 && (
          <EnterAmount
            goBack={() => setActiveIndex(0)}
            goNext={() => setActiveIndex(2)}
          />
        )}
        {activeIndex === 2 && (
          <Confirm
            goBack={() => setActiveIndex(1)}
            goFinish={() => setActiveIndex(0)}
          />
        )}
      </Bidding>
    </>
  );
};

export default SellCrypto;
