import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./FiatAndSpot.module.sass";
import { Link } from "react-router-dom";
import Wallet from "../../components/Wallet";
import Main from "./Main";
import Funds from "../../components/Funds";
import CONST from "../../common/const";
import RestService from "../../adapter/base";
import { useBitmaidoContext } from "../../hooks/useBitmaidoContext";

import { useTranslation } from "react-i18next";

const FiatAndSpot = () => {
  const { t } = useTranslation();
  const [totalSpotBalance, setTotalSpotBalance] = useState(0.0);
  const [BTCUSDRate, setBTCUSDRate] = useState(0.0);
  const [addedAccount, setAddedAccount] = useState(0);
  const { symbols, accounts } = useBitmaidoContext();

  useEffect(() => {
    if (addedAccount === accounts.length) return;
    let added = 0;
    let total = 0.0;
    for (let j in accounts) {
      for (let i in symbols) {
        if (symbols[i].symbol === "BTCUSDT") {
          setBTCUSDRate(symbols[i].quote.price);
        }
        if (symbols[i].foreign_currency !== "USDT") continue;

        if (accounts[j].currency === "USDT") {
          if (accounts[j].type == CONST.TRADE_TYPE.SPOT) {
            total += parseFloat(accounts[j].balance_total);
            added++;
          }
          break;
        }

        if (symbols[i].base_currency === accounts[j].currency) {
          if (accounts[j].type == CONST.TRADE_TYPE.SPOT) {
            if (symbols[i].quote.price !== 0) {
              added++;
            }
            total +=
              parseFloat(accounts[j].balance_total) * symbols[i].quote.price;
          }
          break;
        }
      }
    }

    setTotalSpotBalance(total);
    setAddedAccount(added);
  }, [accounts, symbols, addedAccount]);

  return (
    <>
      <Wallet>
        <Main total={totalSpotBalance} rate={BTCUSDRate} />
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.head}>{t("Funds")}</div>
            <div className={styles.body}>
              <Funds accounts={accounts} type={CONST.TRADE_TYPE.SPOT} />
            </div>
          </div>
        </div>
      </Wallet>
    </>
  );
};

export default FiatAndSpot;
