import React, { useRef, useState } from "react";
import Main from "./Main";
import Learn from "./Learn";
import Popular from "../../components/Popular";
import Download from "./Download";
import News from "../../components/News";
import Steps from "./Steps";
import Users from "./Main/Users";
import CONST from "../../common/const";
import ICO from "./Main/ICO";

const Home = () => {
  const scrollToRef = useRef(null);

  const [trendTab, setTrendTab] = useState(CONST.TREND_TAB.USER_TOKEN);

  return (
    <>
      <Main
        scrollToRef={scrollToRef}
        trendTab={trendTab}
        handleTrendTab={setTrendTab}
      />
      <ICO />
      {/* <Users /> */}
    </>
  );
};

export default Home;
