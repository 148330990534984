import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { useBitmaidoContext } from "../../../hooks/useBitmaidoContext";

const coinIcons = {
  BTC: {
    id: 0,
    currency: "USDT",
    content: "Tether USD",
    icon: "/images/content/currency/bitcoin.svg",
  },
  ETH: {
    id: 1,
    currency: "ETH",
    content: "Ethereum",
    icon: "/images/content/currency/ethereum.svg",
  },
  USDT: {
    id: 2,
    currency: "USDT",
    content: "Tether USD",
    icon: "/images/content/currency/tether.svg",
  },
  BNB: {
    id: 3,
    currency: "BNB",
    content: "BNB",
    icon: "/images/content/currency/bnb.svg",
    earn: "7.46% APR",
  },
  XRP: {
    id: 4,
    currency: "XRP",
    content: "Ripple",
    icon: "/images/content/currency/ripple.svg",
    earn: "7.46% APR",
  },
  TRX: {
    id: 5,
    currency: "TRX",
    content: "TRON",
    icon: "/images/content/currency/tron.svg",
  },
  LINK: {
    id: 6,
    currency: "LINK",
    content: "LINK",
    icon: "/images/content/currency/chainlink.svg",
  },
  EOS: {
    id: 7,
    currency: "EOS",
    content: "EOS.IO",
    icon: "/images/content/currency/eos.svg",
  },
  ADA: {
    id: 8,
    currency: "ADA",
    content: "Cardano",
    icon: "/images/content/currency/cardano.svg",
  },
  ONT: {
    id: 9,
    currency: "ONT",
    content: "Ontology",
    icon: "/images/content/currency/ontology.svg",
  },
  USDC: {
    id: 10,
    currency: "USDC",
    content: "USD Coin",
    icon: "/images/content/currency/usd-coin.svg",
  },
};

const Item = ({ item }) => {
  const [visible, setVisible] = useState(false);
  const { precisions } = useBitmaidoContext();

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} onClick={() => setVisible(!visible)}>
          <div className={styles.col}>
            <div className={styles.currency}>
              <div className={styles.icon}>
                {coinIcons[item.currency] && (
                  <img src={coinIcons[item.currency].icon} alt="Currency" />
                )}
              </div>
              <div className={styles.details}>
                <div className={styles.info}>{item.currency}</div>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>
              {parseFloat(item.balance_total).toFixed(
                precisions[item.currency]
              )}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>
              {(
                parseFloat(item.balance_total) - parseFloat(item.balance_order)
              ).toFixed(precisions[item.currency])}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>
              {parseFloat(item.balance_order).toFixed(
                precisions[item.currency]
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
