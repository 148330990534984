import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/app.sass";
import "./styles/global.css";
import Page from "./components/Page";
import Home from "./screens/Home";
import Market from "./screens/Market";
import LearnCrypto from "./screens/LearnCrypto";
import LearnCryptoDetails from "./screens/LearnCryptoDetails";
import Contact from "./screens/Contact";
import Notifications from "./screens/Notifications";
import Activity from "./screens/Activity";
import Exchange from "./screens/Exchange";
import WalletOverview from "./screens/WalletOverview";
import WalletOverviewDetails from "./screens/WalletOverviewDetails";
import WalletMargin from "./screens/WalletMargin";
import FiatAndSpot from "./screens/FiatAndSpot";
import DepositFiat from "./screens/DepositFiat";
import BuyCrypto from "./screens/BuyCrypto";
import SellCrypto from "./screens/SellCrypto";
import ProfileInfo from "./screens/ProfileInfo";
import Referrals from "./screens/Referrals";
import KYC from "./screens/KYC";
import ApiKeys from "./screens/ApiKeys";
import SessionsAndLoginHistory from "./screens/SessionsAndLoginHistory";
import TwoFa from "./screens/TwoFa";
import ChangePassword from "./screens/ChangePassword";
import SignIn from "./screens/SignIn";
import SignUp from "./screens/SignUp";
import ForgotPassword from "./screens/ForgotPassword";
import PageList from "./screens/PageList";
import {
  BitmaidoProvider,
  SimpleBitmaidoProvider,
  UserProvider,
} from "./Providers";
import { ThemeProvider } from "./ThemeProvider";
import CONST from "./common/const";
import TokenSettings from "./screens/TokenSettings";
import Recommends from "./screens/Recommends";
import User from "./screens/User";
import Blog from "./screens/Blog";
import YourBlogs from "./screens/Blogs";
import YourFAQ from "./screens/FAQ";
import NewToken from "./screens/TokenSettings/new";
import TokenDetails from "./screens/TokenSettings/detail";
import EditToken from "./screens/TokenSettings/edit";
import Sns from "./screens/User/Sns";
import { useEffect } from "react";
import React from 'react';

function App() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  useEffect(() => {
    if (userAgent.indexOf("line/") > 0) {
      window.location.href = "/?openExternalBrowser=1";
    }
  }, [userAgent]);

  return (
    <div>
      <ThemeProvider>
        <UserProvider>
          <Router>
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <Page>
                    <SimpleBitmaidoProvider>
                      <Home />
                    </SimpleBitmaidoProvider>
                  </Page>
                )}
              />
              <Route
                exact
                path="/market"
                render={() => (
                  <Page>
                    <SimpleBitmaidoProvider>
                      <Market />
                    </SimpleBitmaidoProvider>
                  </Page>
                )}
              />
              <Route
                exact
                path="/learn-crypto"
                render={() => (
                  <Page>
                    <LearnCrypto />
                  </Page>
                )}
              />
              <Route
                exact
                path="/learn-crypto-details"
                render={() => (
                  <Page>
                    <LearnCryptoDetails />
                  </Page>
                )}
              />
              <Route
                exact
                path="/contact"
                render={() => (
                  <Page>
                    <Contact />
                  </Page>
                )}
              />
              <Route
                exact
                path="/notifications"
                render={() => (
                  <Page>
                    <Notifications />
                  </Page>
                )}
              />
              <Route
                exact
                path="/activity"
                render={() => (
                  <Page>
                    <Activity />
                  </Page>
                )}
              />
              <Route
                exact
                path="/exchange/spot"
                render={() => (
                  <BitmaidoProvider type={CONST.TRADE_TYPE.SPOT}>
                    <Page headerWide footerHide>
                      <Exchange />
                    </Page>
                  </BitmaidoProvider>
                )}
              />
              <Route
                exact
                path="/exchange/margin"
                render={() => (
                  <BitmaidoProvider type={CONST.TRADE_TYPE.MARGIN}>
                    <Page headerWide footerHide>
                      <Exchange />
                    </Page>
                  </BitmaidoProvider>
                )}
              />
              <Route
                exact
                path="/wallet-overview"
                render={() => (
                  <BitmaidoProvider type={CONST.TRADE_TYPE.SPOT}>
                    <Page headerWide footerHide>
                      <WalletOverview />
                    </Page>
                  </BitmaidoProvider>
                )}
              />
              <Route
                exact
                path="/wallet-overview/:id"
                render={() => (
                  <Page headerWide footerHide>
                    <WalletOverviewDetails />
                  </Page>
                )}
              />
              <Route
                exact
                path="/wallet-margin"
                render={() => (
                  <BitmaidoProvider type={CONST.TRADE_TYPE.SPOT}>
                    <Page headerWide footerHide>
                      <WalletMargin />
                    </Page>
                  </BitmaidoProvider>
                )}
              />
              <Route
                exact
                path="/fiat-and-spot"
                render={() => (
                  <BitmaidoProvider type={CONST.TRADE_TYPE.SPOT}>
                    <Page headerWide footerHide>
                      <FiatAndSpot />
                    </Page>
                  </BitmaidoProvider>
                )}
              />
              <Route
                exact
                path="/profile-info"
                render={() => (
                  <Page>
                    <ProfileInfo />
                  </Page>
                )}
              />
              <Route
                exact
                path="/blogs"
                render={() => (
                  <Page>
                    <YourBlogs />
                  </Page>
                )}
              />
              <Route
                exact
                path="/token-settings"
                render={() => (
                  <Page>
                    <TokenSettings />
                  </Page>
                )}
              />
              <Route
                exact
                path="/token-settings/new"
                render={() => (
                  <Page>
                    <NewToken />
                  </Page>
                )}
              />
              <Route
                exact
                path="/token-settings/detail"
                render={() => (
                  <Page>
                    <TokenDetails />
                  </Page>
                )}
              />
              <Route
                exact
                path="/token-settings/edit"
                render={() => (
                  <Page>
                    <EditToken />
                  </Page>
                )}
              />
              <Route
                exact
                path="/user/faq"
                render={() => (
                  <Page>
                    <YourFAQ />
                  </Page>
                )}
              />

              <Route
                exact
                path="/recommends"
                render={() => (
                  <Page>
                    <Recommends />
                  </Page>
                )}
              />
              <Route
                exact
                path="/deposit"
                render={() => (
                  <Page>
                    <DepositFiat />
                  </Page>
                )}
              />
              <Route
                exact
                path="/buy-crypto"
                render={() => (
                  <Page>
                    <BuyCrypto />
                  </Page>
                )}
              />
              <Route
                exact
                path="/sell-crypto"
                render={() => (
                  <Page>
                    <SellCrypto />
                  </Page>
                )}
              />
              <Route
                exact
                path="/referrals"
                render={() => (
                  <Page>
                    <Referrals />
                  </Page>
                )}
              />
              <Route
                exact
                path="/kyc"
                render={() => (
                  <Page>
                    <KYC />
                  </Page>
                )}
              />
              <Route
                exact
                path="/api-keys"
                render={() => (
                  <Page>
                    <ApiKeys />
                  </Page>
                )}
              />
              <Route
                exact
                path="/login-history"
                render={() => (
                  <Page>
                    <SessionsAndLoginHistory />
                  </Page>
                )}
              />
              <Route
                exact
                path="/2fa"
                render={() => (
                  <Page>
                    <TwoFa />
                  </Page>
                )}
              />
              <Route
                exact
                path="/change-password"
                render={() => (
                  <Page>
                    <ChangePassword />
                  </Page>
                )}
              />
              <Route
                exact
                path="/sign-in"
                render={() => (
                  <BitmaidoProvider type={CONST.TRADE_TYPE.SPOT}>
                    <Page headerHide footerHide>
                      <SignIn />
                    </Page>
                  </BitmaidoProvider>
                )}
              />
              <Route
                exact
                path="/sign-up"
                render={() => (
                  <Page headerHide footerHide>
                    <SignUp />
                  </Page>
                )}
              />
              <Route
                exact
                path="/forgot-password"
                render={() => (
                  <Page headerHide footerHide>
                    <ForgotPassword />
                  </Page>
                )}
              />
              <Route exact path="/pagelist" component={PageList} />
              <Route
                exact
                path="/user"
                render={() => (
                  <Page>
                    <User />
                  </Page>
                )}
              />

              <Route
                exact
                path="/user/sns"
                render={() => (
                  <Page>
                    <Sns />
                  </Page>
                )}
              />
              <Route
                exact
                path="/blog"
                render={() => (
                  <Page>
                    <Blog />
                  </Page>
                )}
              />
            </Switch>
          </Router>
          <ToastContainer
            closeOnClick={true}
            pauseOnHover={true}
            draggable={false}
            progress={undefined}
            theme="colored"
          />
        </UserProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
