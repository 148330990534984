import React, { useEffect, useRef, useState } from "react";
import RestService from "../../adapter/base";
import { USER_TAB } from "../../common/const";
import Users from "../Home/Main/Users";
import Actions from "./Actions";
import Comment from "./Comment";
import FAQ from "./FAQ";
import Recommends from "./Recommends";
import SendComment from "./SendComment";
import SendRecommend from "./SendRecommend";
import Story from "./Story";
import Update from "./Update";
import UserDetail from "./UserDetail";
import UserHeader from "./UserHeader";

const User = () => {
  const [userTab, setUserTab] = useState(USER_TAB.STORY);
  const [showSendComment, setShowSendComment] = useState(false);
  const [showSendRecommend, setShowSendRecommend] = useState(false);

  const [story, setStory] = useState("");
  const [user, setUser] = useState();
  const [showAction, setShowAction] = useState(false);

  const userHeaderRef = useRef(null);

  const params = new URLSearchParams(window.location.search);

  const userID = params.get("user_id");
  const currency = params.get("currency");

  if (!userID) {
    window.location.href = "/";
  }

  useEffect(() => {
    setShowSendComment(false);
    setShowSendRecommend(false);
  }, [userTab]);

  useEffect(() => {
    if (!userID) {
      return;
    }

    RestService.get("/user", {
      id: userID,
      currency: currency,
    })
      .then((res) => {
        if (res.err) {
          return;
        }
        setShowAction(new Date() > new Date(res.lock_time));
        setUser(res);
        setStory(res.story);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userID]);

  useEffect(() => {
    if (showSendRecommend) {
      setShowSendComment(false);
    }
  }, [showSendRecommend]);

  useEffect(() => {
    if (showSendComment) {
      setShowSendRecommend(false);
    }
  }, [showSendComment]);

  return (
    <>
      <UserDetail handleShowRecommend={setShowSendRecommend} />
      <UserHeader
        selected={userTab}
        handleTab={setUserTab}
        token={user ? user.currency : ""}
        handleShowComment={setShowSendComment}
        handleShowRecommend={setShowSendRecommend}
        showAction={showAction}
      />
      {!showSendComment && !showSendRecommend && (
        <>
          {userTab === USER_TAB.STORY && <Story story={story} />}
          {userTab === USER_TAB.FAQ && <FAQ userID={userID} />}
          {userTab === USER_TAB.UPDATE && <Update user={user} />}
          {userTab === USER_TAB.COMMENT && (
            <Comment currency={user ? user.currency : ""} />
          )}
          {userTab === USER_TAB.RECOMMEND && (
            <Recommends currency={user ? user.currency : ""} />
          )}
          {showAction && <Actions token={user ? user.currency : ""} />}
        </>
      )}
      {showSendComment && (
        <SendComment
          currency={user ? user.currency : ""}
          handleShowComment={setShowSendComment}
        />
      )}
      {showSendRecommend && (
        <SendRecommend
          currency={user ? user.currency : ""}
          handleShowRecommend={setShowSendRecommend}
        />
      )}
      <Users />
    </>
  );
};

export default User;
