import React from "react";
import Crypto from "crypto";
import cn from "classnames";
import styles from "./Scan.module.sass";
import { sendRegisterationEmail } from "../../../common/mail";
import RestService from "../../../adapter/base";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Scan = ({ user }) => {
  const { t, i18n } = useTranslation();

  const onSendValidation = () => {
    RestService.post("/user/validate", {
      email: user.email,
      password: user.password,
      locale: i18n.language,
    }).then(() => {
      toast.success(
        t(
          "Register validation email sent.  " +
            "Please check your email and verify the register validation URL."
        )
      );
    });
  };

  return (
    <div className={styles.box}>
      <div className={styles.details}>
        {/* <div className={styles.code}>
          <img src="/images/content/qr-code.jpg" alt={t("Qr code")} />
        </div> */}
        <button
          className={cn("button-stroke", styles.button)}
          onClick={onSendValidation}
        >
          {t("Send Validation E-mail")}
        </button>
      </div>
    </div>
  );
};

export default Scan;
