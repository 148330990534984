import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Currency.module.sass";
import Icon from "../../../components/Icon";

import numeral from "numeral";

import CONST from "../../../common/const";
import { useBitmaidoContext } from "../../../hooks/useBitmaidoContext";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const navigation = ["All", "BTC", "USDT", "ETH"];

const Currency = (props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");
  const {
    symbols,
    refreshOrderTable,
    setRefreshOrderTable,
    setIgnoreSymbolParam,
  } = useBitmaidoContext();

  const onClickSymbol = (symbol) => {
    history.push("/exchange/spot?symbol=" + symbol.symbol);
    setRefreshOrderTable(refreshOrderTable + 1);
    setIgnoreSymbolParam(true);
    props.setSymbolInfo(symbol);
  };

  return (
    <div className={styles.currency}>
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      <div className={styles.form}>
        <input
          className={styles.input}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name={"search"}
          placeholder={t("Search")}
          required
        />
        <button className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </div>
      <div className={styles.tableField}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className="sorting">{t("Pair")}</div>
            </div>
            <div className={styles.col}>
              <div className="sorting">{t("Price")}</div>
            </div>
            <div className={styles.col}>
              <div className="sorting">{t("Volume")}</div>
            </div>
          </div>
          {symbols.map((x) => {
            let show = false;
            if (activeIndex === 0) {
              if (x.symbol.toLowerCase().includes(search.toLowerCase())) {
                show = true;
              }
            } else {
              if (
                x.symbol
                  .toLowerCase()
                  .includes(navigation[activeIndex].toLowerCase())
              ) {
                show = true;
              }
            }

            if (!show) {
              return;
            }

            let numeralFormat = "0,0.";

            for (let i = 0; i < x.precision; i++) {
              numeralFormat = numeralFormat + "0";
            }

            const symbol = {
              symbol: x.symbol,
              alias: x.alias,
              precision: x.precision,
              baseCurrency: x.base_currency,
              foreignCurrency: x.foreign_currency,
            };

            return (
              <div className={styles.row} key={x.id}>
                <div className={styles.col}>
                  <div className={styles.line}>
                    <button className={cn("favorite", styles.favorite)}>
                      <Icon name="star-outline" size="16" />
                    </button>
                    <div
                      className={styles.info}
                      onClick={() => onClickSymbol(symbol)}
                    >
                      {x.base_currency}
                      <span>/{x.foreign_currency}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.col}>
                  {x.quote.changeDir === CONST.CHANGE_DIRECTION.PLUS && (
                    <div className={styles.positive}>
                      {x.quote.price === 0
                        ? "-.--"
                        : numeral(x.quote.price).format(numeralFormat)}
                    </div>
                  )}
                  {x.quote.changeDir === CONST.CHANGE_DIRECTION.MINUS && (
                    <div className={styles.negative}>
                      {x.quote.price === 0
                        ? "-.--"
                        : numeral(x.quote.price).format(numeralFormat)}
                    </div>
                  )}
                </div>
                <div className={styles.col}>
                  {x.quote.volume === 0
                    ? "-.--"
                    : numeral(x.quote.volume).format("0,0.00")}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Currency;
