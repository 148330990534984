import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import TextInput from "../../../components/TextInput";
import RestService from "../../../adapter/base";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

const Form = ({ goFinish }) => {
  const { t } = useTranslation();

  const [user, setUser] = useState({});
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    try {
      RestService.get("/user").then((res) => {
        setUser(res);
      });
    } catch {
      setUser({});
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
  };

  const isChangeButtonEnabled = useMemo(() => {
    if (!password || !oldPassword || !confirmPassword) {
      return false;
    }

    if (password.length < 8) {
      return false;
    }

    const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (!password.match(passw)) {
      return false;
    }

    if (password !== confirmPassword) {
      return false;
    }
    return true;
  }, [password, oldPassword, confirmPassword]);

  const onUpdatePassword = () => {
    if (!password || !oldPassword || !confirmPassword) {
      return;
    }

    if (password.length < 8) {
      return;
    }

    const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (!password.match(passw)) {
      return;
    }

    if (password !== confirmPassword) {
      return;
    }

    RestService.put("/user/password", {
      old_password: oldPassword,
      new_password: password,
    }).then((res) => {
      if (!res.error) {
        toast.success(t("Password Successfully updated!"));
        goFinish();
      } else {
        if (res.error === "WRONG_OLD_PASSWORD") {
          toast.error(
            t("Password Update Failed! Reason: Old Password Incorrect")
          );
        } else {
          toast.error(
            t("Password Update Failed! Reason: ", t("Internal Server Error"))
          );
        }
      }
    });
  };

  const validatePasswordConfirm = (value) => {
    if (value !== password) {
      return {
        result: false,
        message: "Password and Confirm Password does not match!",
      };
    } else {
      return {
        result: true,
      };
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={cn("h3", styles.title)}>{t("New password")}</div>
      <TextInput
        className={styles.field}
        label={t("email")}
        name={"email"}
        type="email"
        placeholder={t("Email")}
        value={user.email}
        required
      />
      <TextInput
        className={styles.field}
        label={t("old password")}
        name={"old-password"}
        type="password"
        placeholder={t("Password")}
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        required
        view
      />
      <TextInput
        className={styles.field}
        label={t("password")}
        name={"password"}
        type="password"
        placeholder={t("Password")}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        validate={{
          length: 8,
          difficulty: true,
          messages: {
            length: t("Password must be longer than ", { length: "8" }),
            difficulty: t(
              "Password must contain at least one number and have a mixture of uppercase and lowercase letters"
            ),
          },
        }}
        required
        view
      />
      <TextInput
        className={styles.field}
        label={t("confirm password")}
        name={"confirm-password"}
        type="password"
        placeholder={t("Password")}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        validate={{
          func: validatePasswordConfirm,
        }}
        required
        view
      />
      <button
        className={cn("button", styles.button)}
        onClick={onUpdatePassword}
        disabled={!isChangeButtonEnabled}
      >
        {t("Change password")}
      </button>
    </form>
  );
};

export default Form;
