import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Charts.module.sass";
import Dropdown from "../../../components/Dropdown";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import useDarkMode from "use-dark-mode";
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer } from "recharts";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { useBitmaidoContext } from "../../../hooks/useBitmaidoContext";
import { useThemeContext } from "../../../hooks/useThemeContext";

import { useTranslation } from 'react-i18next';

import TradingView from "../../../components/TradingView";

const options = {
  chart: {
    type: 'area',
    zoomType: 'xy',
    height: '490px',
    backgroundColor: 'none'
  },
  title: {
      text: null
  },
  xAxis: {
      minPadding: 0,
      maxPadding: 0,
      // gridLineWidth: 1,
      // plotLines: [{
      //     color: '#777E90',
      //     value: 0,
      //     width: 1,
      //     label: {
      //         text: null,
      //         rotation: 90
      //     }
      // }],
      title: {
          text: null
      }
  },
  yAxis: [{
      lineWidth: 1,
      gridLineWidth: 1,
      title: null,
      tickWidth: 1,
      tickLength: 5,
      tickPosition: 'inside',
      gridLineColor: '#666666',
      labels: {
          align: 'left',
          x: 8
      }
  }, {
      opposite: true,
      linkedTo: 0,
      lineWidth: 1,
      gridLineWidth: 0,
      title: null,
      tickWidth: 1,
      tickLength: 5,
      tickPosition: 'inside',
      labels: {
          align: 'right',
          x: -8
      }
  }],
  legend: {
      enabled: false
  },
  plotOptions: {
      area: {
          fillOpacity: 0.2,
          lineWidth: 1,
          step: 'center'
      }
  },
  tooltip: {
      headerFormat: '<span style="font-size=10px;">Price: {point.key}</span><br/>',
      valueDecimals: 2
  },
  series: [{
      name: 'Bids',
      data: [],
      color: '#58BD7D'
  }, {
      name: 'Asks',
      data: [],
      color: '#FF6838'
  }]
};

const Actions = () => {
  const { t } = useTranslation();

  const dates = [t("1m"), t("5m"), t("15m"), t("30m"), t("1H"), t("1D")/*, t("1M")*/];
  const timeFrames = ["1", "5", "15", "30", "60", "1D"/*, "1M"*/];
  const navigation = [t("Trading view"), t("Depth")];

  const [activeIndexDates, setActiveIndexDates] = useState(0);
  const [activeIndexNav, setActiveIndexNav] = useState(0);
  const [date, setDate] = useState(dates[0]);

  const [highChartOption, setHighChartOption] = useState(options);

  const darkMode = useDarkMode(false);

  const lastRefreshTime = useRef();
  const { orders } = useBitmaidoContext();

  const [hideTopToolbar, setHideTopToolBar] = useState(true);

  const { isDark } = useThemeContext();
  const { symbolInfo } = useBitmaidoContext();

  const handleDateSelected = (value) => {
    for (let i in dates) {
      if (dates[i] === value) {
        setActiveIndexDates(i);
        setDate(value);
      }
    }
  }

  useEffect(() => {
    if (activeIndexNav === 1) {
      if (Date.now() - lastRefreshTime.current > parseInt(process.env.REACT_APP_DEPTH_REFRESH_DURATION)) {
        options.series[0].data = [];
        options.series[1].data = [];
  
        let orderAmount = 0;
        orders.bids.slice(1, 19).map((bid) => {
          orderAmount += parseFloat(bid[1]);
          options.series[0].data.push([parseFloat(bid[0]), orderAmount]);
        })
        options.series[0].data = options.series[0].data.slice(0, 19).reverse();
  
        orderAmount = 0;
        orders.asks.slice(0, 19).map((ask) => {
          orderAmount += parseFloat(ask[1]);
          options.series[1].data.push([parseFloat(ask[0]), orderAmount]);
        })
        setHighChartOption(options);
        lastRefreshTime.current = Date.now();
      }
    }
  }, [orders]); 

  useEffect(() => {
    lastRefreshTime.current = Date.now();

    if (activeIndexNav !== 0) {
      return;
    }

    const theme = isDark ? "dark" : "light"

    // window.initTradingView(symbolInfo.symbol, theme);
  }, [symbolInfo, isDark, activeIndexNav]);

  return (
    <div className={styles.charts}>
      <div className={styles.head}>
        <div className={styles.group}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={date}
            setValue={handleDateSelected}
            options={dates}
          />
          <div className={styles.nav}>
            {dates.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: index === activeIndexDates,
                })}
                onClick={() => setActiveIndexDates(index)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndexNav,
              })}
              onClick={() => setActiveIndexNav(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
      </div>
      {activeIndexNav === 0 && (
        <div className={styles.inner}>
          <div className={styles.iframe}>
            <TradingView
              symbol={symbolInfo.symbol}
              frame={timeFrames[activeIndexDates]}
              theme={isDark ? "dark" : "light"}
            />
            {/* <TradingViewWidget
              symbol="NASDAQ:AAPL"
              theme={darkMode.value ? Themes.DARK : Themes.LIGHT}
              locale="en"
              hide_top_toolbar={hideTopToolbar}
              studies={["Volume@tv-basicstudies"]}
              autosize
            /> */}
            {/* <div className="tradingview-widget-container" style={{height: "100%"}}>
              <div id="tradingview_5369c" style={{height: "100%"}}></div>
            </div> */}
          </div>
        </div>
      )}
      {activeIndexNav === 1 && (
        <div className={styles.chart}>
          <ResponsiveContainer width="100%" height="100%">
            {/* <AreaChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <defs>
                <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                  <stop offset={off} stopColor="#58BD7D" stopOpacity={1} />
                  <stop offset={off} stopColor="#FF6838" stopOpacity={1} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="price"
                stroke="#000"
                fill="url(#splitColor)"
              />
            </AreaChart> */}
            <HighchartsReact highcharts={Highcharts} options={options} updateArgs={[true]} />
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default Actions;
