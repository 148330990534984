import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./validatedeposit.module.sass";
import Icon from "../Icon";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import Checkbox from "../Checkbox";
import CONST from "../../common/const";
import RestService from "../../adapter/base";
import { toast } from "react-toastify";
import { useBitmaidoContext } from "../../hooks/useBitmaidoContext";

import { useTranslation } from "react-i18next";

const ValidateDeposit = ({ requestCode, tradeType }) => {
  const { t } = useTranslation();

  const [reqCode, setReqCode] = useState("");
  const [txId, setTxId] = useState("");

  useEffect(() => {
    setReqCode(requestCode);
  }, [requestCode]);

  const onClickRequest = () => {
    RestService.post("/deposit/verify", {
      requestCode: reqCode,
      txid: txId,
      tradeType: tradeType,
    }).then((res) => {
      if (!res.error) {
        if (res === false) {
          toast.error(t("Deposit is not validated yet."));
        } else {
          toast.success(t("Request Validate completed successfully."));
        }
      } else {
        toast.error(
          t("Request Validate Failed! Reason: ", {
            error: t("Internal Server Error"),
          })
        );
      }
    });
  };

  return (
    <div className={styles.withdraw}>
      <div className={cn("h4", styles.title)}>
        <Icon name="arrow-left" size="32" />
        {t("Validate Deposit")}
      </div>
      <TextInput
        className={styles.field}
        label={t("Transaction ID")}
        name={"address"}
        type="text"
        value={txId}
        onChange={(e) => setTxId(e.target.value)}
        required
      />
      <div className={styles.box}>
        <TextInput
          className={styles.field}
          label={t("Validation Code")}
          name={"amount"}
          type="text"
          value={reqCode}
          onChange={(e) => setReqCode(e.target.value)}
          required
        />
      </div>
      <button className={cn("button", styles.button)} onClick={onClickRequest}>
        {t("Request Validate")}
      </button>
    </div>
  );
};

export default ValidateDeposit;
