import React, { useEffect, useState } from "react";
import crypto from "crypto";
import { useSearchParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Form.module.sass";
import { toast } from "react-toastify";
import Checkbox from "../../../components/Checkbox";
import TextInput from "../../../components/TextInput";
import RestService from "../../../adapter/base";
import { useHistory } from "react-router";
import { sendRegisterationEmail } from "../../../common/mail";
import CONST from "../../../common/const";

import { useTranslation } from "react-i18next";

const Form = (props) => {
  const { t, i18n } = useTranslation();

  const [policy, setPolicy] = useState(true);
  const history = useHistory();
  const [referral, setReferral] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const refer = queryParams.get("referral");
    if (refer) {
      setReferral(refer);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const fields = {
      email: event.target.elements.email.value,
      password: event.target.elements.password.value,
    };

    if (policy === false) {
      toast.error(
        t(
          "Please check User Agreements, Privacy Policy, Cookie Policy, E-Sign Consent."
        )
      );
      return;
    }

    if (event.target.elements.referral.value) {
      const id = new Buffer(event.target.elements.referral.value, "base64");
      fields.referral = parseInt(id.toString("ascii"));
    }

    if (fields.password.length < 8) {
      return;
    }

    const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (!fields.password.match(passw)) {
      return;
    }

    if (fields.password !== event.target.elements.confirmPassword.value) {
      toast.error(t("Password and Confirm Password do not match!"), {
        postition: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
      });
      return;
    }

    fields.locale = i18n.language;

    RestService.post(`/user`, fields).then((res) => {
      if (!res.error) {
        history.push("/sign-in");
        toast.success(
          t(
            "User registered successfully.  " +
              "Please check your email and verify the register validation URL."
          )
        );
      } else {
        if (res.error === "USER_REGISTERED") {
          toast.error(
            t("User already exists! Please try again with different e-mail!"),
            {
              postition: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
            }
          );
        } else {
          toast.error(t("Sign Up Failed! Please try again!"), {
            postition: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
          });
        }
      }
    });
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.top}>
        <h3 className={cn("h3", styles.title)}>{t("Sign up")}</h3>
        {/*<div className={styles.info}>Use Your OpenID to Sign up</div>
        <div className={styles.btns}>
          <button className={cn("button", styles.button)}>
            <Icon name="google" size="16" />
            <span>Google</span>
          </button>
          <button className={cn("button-black", styles.button)}>
            <Icon name="apple" size="16" />
            <span>Apple</span>
          </button>
        </div>*/}
      </div>
      {/*<div className={styles.info}>Or continue with email</div>*/}
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label={t("email")}
          name={"email"}
          type="email"
          placeholder={t("Email address")}
          required
        />
        <TextInput
          className={styles.field}
          label={t("Password")}
          name={"password"}
          type="password"
          placeholder={t("Password")}
          validate={{
            length: 8,
            difficulty: true,
            messages: {
              length: t("Password must be longer than ", { length: "8" }),
              difficulty: t(
                "Password must contain at least one number and have a mixture of uppercase and lowercase letters"
              ),
            },
          }}
          required
          view
        />
        <TextInput
          className={styles.field}
          label={t("confirm password")}
          name={"confirmPassword"}
          type="password"
          placeholder={t("Password")}
          required
          view
        />
        <TextInput
          className={styles.field}
          label={t("Referral Code")}
          name={"referral"}
          type="text"
          placeholder={t("Referral Code")}
          value={referral}
          onChange={(e) => setReferral(e.target.value)}
        />
        <Checkbox
          className={styles.checkbox}
          value={policy}
          onChange={() => setPolicy(!policy)}
          content={
            "<span>" +
            t(
              "By signing up I agree that I’m 18 years of age or older, to the "
            ) +
            "<a href='/#' target='_blank' rel='noopener noreferrer'>" +
            t("User Agreements") +
            "</a>, <a href='/#' target='_blank' rel='noopener noreferrer'>" +
            t("Privacy Policy") +
            "</a>, <a href='/#' target='_blank' rel='noopener noreferrer'>" +
            t("Cookie Policy") +
            "</a>, <a href='/#' target='_blank' rel='noopener noreferrer'>" +
            t("E-Sign Consent") +
            "</a>.<span>"
          }
        />
      </div>
      <button className={cn("button", styles.button)}>{t("Sign up")}</button>
    </form>
  );
};

export default Form;
