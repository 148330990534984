import React, { useState } from "react";
import Bidding from "../../components/Bidding";
import SelectCurrency from "./SelectCurrency";
import ImportantNotes from "./ImportantNotes";
import PaymentDetails from "./PaymentDetails";
import Popular from "../../components/Popular";

import { useTranslation } from "react-i18next";

const DepositFiat = () => {
  const { t } = useTranslation();

  const steps = [t("Select currency"), t("Request Address")];

  const [activeIndex, setActiveIndex] = useState(0);
  const [method, setMethod] = useState("");
  const [type, setType] = useState("");
  const [price, setPrice] = useState(0);
  const [currency, setCurrency] = useState(0);
  const [address, setAddress] = useState(0);
  const [net, setNet] = useState(undefined);

  const goRequestAddress = (
    newMethod,
    newType,
    newPrice,
    newCurrency,
    newAddress,
    newNet
  ) => {
    setMethod(newMethod);
    setPrice(newPrice);
    setCurrency(newCurrency);
    setAddress(newAddress);
    setType(newType);
    setActiveIndex(1);
    setNet(newNet);
  };

  return (
    <>
      <Bidding title={t("Deposit")} items={steps} activeIndex={activeIndex}>
        {activeIndex === 0 && <SelectCurrency goNext={goRequestAddress} />}
        {activeIndex === 1 && (
          <PaymentDetails
            goNext={() => setActiveIndex(2)}
            method={method}
            type={type}
            price={price}
            currency={currency}
            address={address}
            net={net}
          />
        )}
        {activeIndex === 2 && (
          <ImportantNotes
            method={method}
            type={type}
            price={price}
            currency={currency}
            address={address}
            net={net}
          />
        )}
      </Bidding>
      <Popular classSection="section-bg section-mb0" />
    </>
  );
};

export default DepositFiat;
