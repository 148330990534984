import React from "react";
import cn from "classnames";
import styles from "./Popular.module.sass";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import Card from "./Card";

import { useTranslation } from "react-i18next";

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Popular = ({ classSection }) => {
  const { t } = useTranslation();

  const items = [
    {
      title: t("Buy & Sell Crypto"),
      content: t(
        "Create an account and start trading now. Having a Lovely Alien Club NFT makes some transaction fees easier."
      ),
      button: t("Deposit"),
      image: "/images/content/card-pic-1.png",
      image2x: "/images/content/card-pic-1@2x.png",
      url: "/deposit",
    },
    {
      title: t("Get Gems"),
      content: t(
        "Get new Gems that may grow in the future. The A41 will also focus on new coin excavation, listings and IEO."
      ),
      button: t("Trade now"),
      image: "/images/content/card-pic-2.png",
      image2x: "/images/content/card-pic-2@2x.png",
      url: "/exchange/spot",
    },
    {
      title: t("Trade NFTs"),
      content: t(
        "Trade NFTs on the A41 NFT Marketplace for new asset classes. Providing a new world for coin projects and artists."
      ),
      button: t("Contact now"),
      image: "/images/content/card-pic-3.png",
      image2x: "/images/content/card-pic-3@2x.png",
      url: "https://hc.a51.exchange/",
    },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          nextArrow: (
            <SlickArrow>
              <Icon name="arrow-next" size="18" />
            </SlickArrow>
          ),
          prevArrow: (
            <SlickArrow>
              <Icon name="arrow-prev" size="18" />
            </SlickArrow>
          ),
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: false,
          nextArrow: (
            <SlickArrow>
              <Icon name="arrow-next" size="18" />
            </SlickArrow>
          ),
          prevArrow: (
            <SlickArrow>
              <Icon name="arrow-prev" size="18" />
            </SlickArrow>
          ),
        },
      },
    ],
  };

  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>
            {t("Become a crypto trader in seconds")}
          </h2>
          <div className={styles.info}>
            {t("We've got everything you need to start trading.")}
          </div>
        </div>
        <div className={styles.wrapper}>
          <Slider className="popular-slider" {...settings}>
            {items.map((x, index) => (
              <Card className={styles.card} item={x} key={index} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Popular;
