import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Calendar.module.sass";
import Icon from "../Icon";
import OutsideClickHandler from "react-outside-click-handler";
import DatePicker from "react-datepicker";
import ja from "date-fns/locale/ja";

import { useTranslation } from "react-i18next";

const Calendar = ({ label, className, onChangeDate }) => {
  const { t, i18n } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const onChange = (date) => {
    setStartDate(date);
    if (onChangeDate) {
      onChangeDate(date);
      setVisible(false);
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(className, styles.calendar)}>
        <div className={styles.title}>{label}</div>
        <button
          className={cn(
            "button-stroke button-large",
            { [styles.active]: visible },
            styles.button
          )}
          onClick={() => setVisible(!visible)}
        >
          <span>{t(startDate.toLocaleDateString())}</span>
          <Icon name="calendar" size="16" />
        </button>
        <div className={cn(styles.body, { [styles.show]: visible })}>
          <div className={styles.datepicker}>
            <DatePicker
              locale={i18n.language === "jp" ? ja : undefined}
              selected={startDate}
              onChange={(date) => onChange(date)}
              monthsShown={1}
              dateFormatCalendar={"MMM yyyy"}
              inline
            />
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Calendar;
