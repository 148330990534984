import React from 'react';
import { useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Sns.module.sass";
import { useTranslation } from "react-i18next";
import RestService from "../../../../adapter/base";
import axios from "axios";
import ReactPaginate from "react-paginate";

const Twitter = ({ snsId }) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);

  const [userProfile, setUserProfile] = useState({});
  const [youtubeList, setYoutubeList] = useState([]);
  const [pageToken, setPageToken] = useState("trial");
  const [pageInfo, setPageInfo] = useState();
  const totalPage = useRef(0);
  const [pageIndex, setPageIndex] = useState(0);

  const [userThumbnail, setUserThumbnail] = useState(
    "/images/content/avatar-default.svg"
  );
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    RestService.get("/sns/twitter", {
      sns_name: snsId,
    }).then((res) => {
      setUserProfile(res.data);
      setUserThumbnail(res.data.profile_image_url);
      setUsername(res.data.name);
      setUserId("@" + res.data.username);
      setYoutubeList(res.includes.tweets);
    });
  }, [snsId]);

  const onPageChange = (event) => {
    if (event.selected > pageIndex) {
      setPageToken(pageInfo.next);
    } else {
      setPageToken(pageInfo.prev);
    }

    setPageIndex(event.selected);
  };

  return (
    <>
      <div className={styles.container_sm_fluid}>
        <div className={styles.user_photo}>
          <img src={`/images/content/twitter.png`} alt={t("Currency")} />
        </div>
        <div className={styles.detail_container}>
          <h1>X</h1>
        </div>
      </div>
      <div
        className={cn(styles.container, styles.videoList)}
        style={{
          marginTop: "10px",
        }}
      >
        {youtubeList.map((item, index) => (
          <a
            href={`https://x.com/${userProfile.username}/status/${item.id}`}
            target="_blank"
            className={styles.post_card}
          >
            <div className={styles.sns_profile}>
              <img src={userThumbnail} />
              <div className={styles.name_container}>
                <label className={styles.name}>{username}</label>
                <label className={styles.id}>{userId}</label>
              </div>
            </div>
            <span className={styles.allow_overflow}>
              {item.text.length > 60 ? item.text.substring(0, 60) : item.text}
              ...
            </span>
          </a>
        ))}
      </div>
      <ReactPaginate
        key="twitterPagination"
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        marginPagesDisplayed={0}
        pageRangeDisplayed={0}
        pageCount={totalPage.current}
        renderOnZeroPageCount={null}
        pageClassName="page-item page-item-hidden"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        forcePage={pageIndex}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default Twitter;
