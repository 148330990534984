import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Notifications.module.sass";
import Icon from "../../Icon";
import RestService from "../../../adapter/base";

import { useTranslation } from "react-i18next";

const Notifications = ({ className }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);

  useEffect(() => {
    RestService.get("/user/log").then((result) => {
      result = result.reverse();
      const tmpItems = [];

      result.forEach((item) => {
        if (tmpItems.length > 10) return;
        if (tmpItems.length === 0) {
          tmpItems.push({
            title: "Login attempted from new IP",
            date: `${item.loginned_at.substring(0, 19).replace("T", " ")} - ${
              item.ip_addr
            }`,
            new: true,
            ip_addr: item.ip_addr,
          });
        } else {
          if (tmpItems.find((x) => x.ip_addr == item.ip_addr) === undefined) {
            tmpItems.push({
              title: "Login attempted from new IP",
              date: `${item.loginned_at.substring(0, 19).replace("T", " ")} - ${
                item.ip_addr
              }`,
              ip_addr: item.ip_addr,
            });
          }
        }
      });
      setItems(tmpItems);
    });
  }, []);

  const [visible, setVisible] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(className, styles.notifications, {
          [styles.active]: visible,
        })}
      >
        <button
          className={cn(styles.head, styles.active)}
          onClick={() => setVisible(!visible)}
        >
          <Icon name="bell" size="24" />
        </button>
        <div className={styles.body}>
          <div className={styles.title}>{t("Notifications")}</div>
          <div className={styles.list}>
            {items.map((x, index) => (
              <Link
                className={cn(styles.item, { [styles.new]: x.new })}
                to="#"
                onClick={() => setVisible(!visible)}
                key={index}
              >
                <div className={styles.subtitle}>{t(x.title)}</div>
                <div className={styles.date}>{x.date}</div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Notifications;
