import React, { useState } from "react";
import cn from "classnames";
import styles from "./Actions.module.sass";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";

const Actions = ({ token }) => {
  const { t } = useTranslation();

  return (
    <div className={cn("section", styles.section)} style={{ height: "100px" }}>
      <div className={styles.center}>
        <button
          className={cn("button", styles.button)}
          style={{ marginTop: "5px" }}
          onClick={() =>
            (window.location.href = `/exchange/spot?symbol=${token}USDT`)
          }
        >
          {t("Go to trading")}
        </button>
      </div>
    </div>
  );
};

export default Actions;
