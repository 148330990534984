import React from 'react';
import { useEffect, useMemo, useReducer, useState } from "react";
import cn from "classnames";
import styles from "./Sns.module.sass";
import { useTranslation } from "react-i18next";
import { shortenText } from "../../../../common/helper";
import RestService from "../../../../adapter/base";
import axios from "axios";
import InstagramApi from "instagram-web-api";
import ReactPaginate from "react-paginate";

const Instagram = ({ snsId }) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);

  const [displayItems, setDisplayItems] = useState([]);
  const [items, setItems] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageToken, setPageToken] = useState("");

  const [userThumbnail, setUserThumbnail] = useState(
    "/images/content/avatar-default.svg"
  );
  const [username, setUsername] = useState("");

  useEffect(() => {
    axios
      .get(`https://graph.instagram.com/${snsId}`, {
        params: {
          access_token: process.env.REACT_APP_INSTAGRAM_API_TOKEN,
          fields: "id,username",
        },
      })
      .then((res) => {
        setUsername(res.data.username);
      });
    axios
      .get(`https://graph.instagram.com/${snsId}/media`, {
        params: {
          access_token: process.env.REACT_APP_INSTAGRAM_API_TOKEN,
          fields: "id,username,caption,permalink,media_url,thumbnail_url",
        },
      })
      .then((res) => {
        setItems(res.data.data);
        setTotalPage(Math.ceil(res.data.data.length / 6));
      });
  }, [snsId]);

  const onPageChange = (event) => {
    console.log("event");
    setDisplayItems([]);
    setPageIndex(event.selected);
  };

  useEffect(() => {
    setDisplayItems(
      items.slice(pageIndex * 6, Math.min((pageIndex + 1) * 6, items.length))
    );
  }, [items, pageIndex]);

  return (
    <>
      <div className={styles.container_sm_fluid}>
        <div className={styles.user_photo}>
          <img src={`/images/content/instagram.svg`} alt={t("Currency")} />
        </div>
        <div className={styles.detail_container}>
          <h1>Instagram</h1>
        </div>
      </div>
      <div
        className={cn(styles.container, styles.videoList)}
        style={{
          marginTop: "10px",
        }}
      >
        {displayItems.map((item, index) => (
          <a href={item.permalink} target="_blank" className={styles.post_card}>
            <div className={styles.sns_profile}>
              <img src={userThumbnail} />
              <div className={styles.name_container}>
                <label className={styles.name}>{username}</label>
                <label className={styles.id}>{snsId}</label>
              </div>
            </div>
            <span>{item?.caption ?? ""}</span>
            <img
              src={item.thumbnail_url ?? item.media_url}
              style={{
                marginBottom: "auto",
              }}
            />
          </a>
        ))}
      </div>
      <ReactPaginate
        key="instagramPagination"
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        marginPagesDisplayed={0}
        pageRangeDisplayed={0}
        pageCount={totalPage}
        renderOnZeroPageCount={null}
        pageClassName="page-item page-item-hidden"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        forcePage={pageIndex}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default Instagram;
