import React, { useState } from "react";
import cn from "classnames";
import styles from "./Users.module.sass";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../../components/Dropdown";
import { useEffect } from "react";
import RestService from "../../../../adapter/base";
import { TOKEN_CATEGORY } from "../../../../common/const";

const Users = (props) => {
  const { t } = useTranslation();
  const [ownerList, setOwnerList] = useState([]);
  

  useEffect(() => {
    RestService.get("/user/token_owner").then((res) => setOwnerList(res));
  }, []);

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.title_container}>
          <div className={styles.title}>{t("Users")}</div>
        </div>
        <div className={styles.users_container}>
          {ownerList
          .filter(
            (x) =>
              props.category === -1 ? true : x.category === props.category
          )
          .map((owner, index) => {
            return (
              <Link
                className={styles.user_card}
                to={`/user?user_id=${owner.user_id}&target=${owner.target_amount}&currency=${owner.currency}`}
                key={`tokenOwner_${index}`}
              >
                <div className={styles.user_photo}>
                  <img
                    src={`${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${owner.promotion_url}`}
                    onError={(e) => {
                      e.target.src = "/images/content/coin-default.svg";
                    }}
                    alt="Card preview"
                  />
                </div>
                <div className={styles.card_body}>
                  <div className={styles.card_avatar}>
                    <img
                      src={`${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${owner.avatar}`}
                      onError={(e) => {
                        e.target.src = "/images/content/avatar-default.svg";
                      }}
                      alt="Card Avatar"
                    />
                  </div>
                  <div className={styles.card_details}>
                    <div
                      className={styles.row}
                      style={{
                        width: "100%",
                      }}
                    >
                      <div className={styles.card_title}>
                        {owner.fullname ?? "Unknown"}
                      </div>
                      <div
                        className={cn("category-stroke-green", styles.status)}
                      >
                        {t(TOKEN_CATEGORY[owner.category])}
                      </div>
                    </div>

                    <div className={styles.card_author}>
                      {owner.user_description}
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Users;
