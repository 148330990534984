import React from "react";
import cn from "classnames";
import styles from "./Table.module.sass";
import CONST from "../../../common/const";
import { useTranslation } from "react-i18next";

const KycTable = ({ kycList }) => {
  const { t } = useTranslation();
  return (
    <div className={cn(styles.table)}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div>{t("Type")}</div>
        </div>
        {/* <div className={styles.col}>
          <div >{t("Front")}</div>
        </div>
        <div className={styles.col}>
          <div >{t("Back")}</div>
        </div>
        <div className={styles.col}>
          <div >{t("Self Camera")}</div>
  </div> */}
        <div className={styles.col}>
          <div>{t("Status")}</div>
        </div>
        <div className={styles.col}>
          <div>{t("Note")}</div>
        </div>
      </div>
      {kycList.map((kyc, index) => {
        return (
          <div className={styles.row}>
            <div className={styles.col}>{t(CONST.KYC_CATEGORY[kyc.type])}</div>
            {/* <div className={styles.col}>
            <img 
              className={styles.image}
              src={`${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${kyc.front_uri}`}/>
          </div>
          <div className={styles.col}>
            <img 
              className={styles.image}
              src={`${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${kyc.back_uri}`}/>
          </div>
          <div className={styles.col}>
            <img 
              className={styles.image}
              src={`${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${kyc.self_uri}`}/>
        </div> */}
            <div className={styles.col}>
              {kyc.status === 0 ? (
                <div className={cn("category-blue", styles.category)}>
                  {t("UNDER REVIEW")}
                </div>
              ) : kyc.status === 1 ? (
                <div className={cn("category-green", styles.category)}>
                  {t("APPROVED")}
                </div>
              ) : (
                <div className={cn("category-red", styles.category)}>
                  {t("REJECTED")}
                </div>
              )}
            </div>
            <div className={styles.col}>
              <div className={styles.comment}>{kyc.comment}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default KycTable;
