import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Buycontent.module.sass";
import Icon from "../../../components/Icon";
import { useTranslation } from "react-i18next";
import TextInput from "../../../components/TextInput";
import RestService from "../../../adapter/base";
import Successfully from "./Successfully";
import { toast } from "react-toastify";

const BuyContent = ({ currency }) => {
  const { t } = useTranslation();

  const [steps, setSteps] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const getBalance = () => {
    RestService.get("/accounts", {
      currency: "USDT",
    }).then((res) => {
      setUsdtBalance(
        parseFloat(res[0].balance_total) - parseFloat(res[0].balance_order)
      );
    });
  };

  useEffect(() => {
    getBalance();
  }, []);

  const [amount, setAmount] = useState();

  const onChangeAmount = (value) => {
    const tmpValue = parseInt(value);
    if (tmpValue * 0.01 > usdtBalance) {
      return;
    }
    setAmount(tmpValue);
  };

  const onBuyToken = () => {
    RestService.post("/tokenSale/new", {
      currency,
      amount,
    }).then((res) => {
      if (!res.error) {
        setSteps(1);
      } else {
        toast.error(t(res.error));
      }
    });
  };

  return (
    <>
      {steps === 0 ? (
        <div className={styles.withdraw}>
          <div className={cn("h4", styles.title)}>
            {t("Buy Now", { value: currency })}
          </div>

          <TextInput
            className={styles.field}
            label={t("Enter Amount")}
            name="balance"
            type="number"
            value={amount}
            onChange={(e) => onChangeAmount(e.target.value)}
            required
          />
          <div className={styles.wrap}>
            <div
              style={{
                display: "flex",
              }}
            >
              <div className={styles.category}>{t("Available")}</div>
              <div className={styles.details}>
                <div className={styles.currency}>
                  {usdtBalance.toFixed(2) ?? "--"} USD
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <div className={styles.category}>{t("Price")}</div>
              <div className={styles.details}>
                <div className={styles.currency}>0.01 USD</div>
              </div>
            </div>
            <span className={styles.divider} />
            <div
              style={{
                display: "flex",
              }}
            >
              <div className={styles.category}>{t("Amount to spend")}</div>
              <div className={styles.details}>
                <div className={styles.currency}>
                  {amount ? amount * 0.01 : "--"} USD
                </div>
              </div>
            </div>
          </div>
          <span>
            {t(
              "Notice: You can sell the token for more than 1.2 times the price you purchased."
            )}
          </span>
          <button
            className={cn("button-stroke", styles.button)}
            onClick={onBuyToken}
            style={{
              marginTop: 20,
            }}
          >
            {t("Buy Now", { value: currency })}
          </button>
        </div>
      ) : (
        <Successfully currency={currency} amount={amount} />
      )}
    </>
  );
};

export default BuyContent;
