import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Table.module.sass";
import { Link } from "react-router-dom";
import CONST from "../../../common/const";
import Modal from "../../../components/Modal";
import ValidateDeposit from "../../../components/ValidateDeposit";

import { useTranslation } from "react-i18next";
import RestService from "../../../adapter/base";

const coinIcons = {
  BTC: {
    id: 0,
    currency: "USDT",
    content: "Tether USD",
    icon: "/images/content/currency/bitcoin.svg",
  },
  ETH: {
    id: 1,
    currency: "ETH",
    content: "Ethereum",
    icon: "/images/content/currency/ethereum.svg",
  },
  USDT: {
    id: 2,
    currency: "USDT",
    content: "Tether USD",
    icon: "/images/content/currency/tether.svg",
  },
  BNB: {
    id: 3,
    currency: "BNB",
    content: "BNB",
    icon: "/images/content/currency/bnb.svg",
    earn: "7.46% APR",
  },
  XRP: {
    id: 4,
    currency: "XRP",
    content: "Ripple",
    icon: "/images/content/currency/ripple.svg",
    earn: "7.46% APR",
  },
  TRX: {
    id: 5,
    currency: "TRX",
    content: "TRON",
    icon: "/images/content/currency/tron.svg",
  },
  LINK: {
    id: 6,
    currency: "LINK",
    content: "LINK",
    icon: "/images/content/currency/chainlink.svg",
  },
  EOS: {
    id: 7,
    currency: "EOS",
    content: "EOS.IO",
    icon: "/images/content/currency/eos.svg",
  },
  ADA: {
    id: 8,
    currency: "ADA",
    content: "Cardano",
    icon: "/images/content/currency/cardano.svg",
  },
  ONT: {
    id: 9,
    currency: "ONT",
    content: "Ontology",
    icon: "/images/content/currency/ontology.svg",
  },
  USDC: {
    id: 10,
    currency: "USDC",
    content: "USD Coin",
    icon: "/images/content/currency/usd-coin.svg",
  },
};

const Table = ({
  className,
  items,
  startDate,
  endDate,
  search,
  activeIndex,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    RestService.get("/user").then((res) => {
      setUser(res);
    });
  }, []);

  const [visisbleValidate, setVisibleValidate] = useState(false);
  const [requestCode, setRequestCode] = useState("");
  const [tradeType, setTradeType] = useState("");
  const [historyItems, setHistoryItems] = useState([]);
  const [user, setUser] = useState({});

  useEffect(() => {
    for (let i = 0; i < items.length - 1; i++) {
      for (let j = i + 1; j < items.length; j++) {
        const date_i = new Date(items[i].created_at);
        const date_j = new Date(items[j].created_at);
        if (date_i < date_j) {
          const temp = items[i];
          items[i] = items[j];
          items[j] = temp;
        }
      }
    }
    setHistoryItems(items);
  }, [items]);

  const onClickValidate = (reqCode, trade_type) => {
    setVisibleValidate(true);
    setRequestCode(reqCode);
    setTradeType(trade_type);
  };

  return (
    <div className={cn(className, styles.table)}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className="sorting">{t("Coin")}</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">{t("Type")}</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">
            {activeIndex !== 2 ? t("Method") : t("Direction")}
          </div>
        </div>
        <div className={styles.col}>
          <div className="sorting">{t("Amount")}</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">{t("Status")}</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">
            {activeIndex !== 2 ? t("Address") : t("Referror")}
          </div>
        </div>
        <div className={styles.col}>
          <div className="sorting">{t("Transaction ID")}</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">{t("Date")}</div>
        </div>
      </div>
      {items.map((x, index) => {
        const date = new Date(x.created_at);
        if (!(date >= startDate && date <= endDate)) {
          return "";
        }
        if (search !== "") {
          if (
            x.user_address.indexOf(search) === -1 &&
            x.currency.indexOf(search) === -1
          ) {
            let flag = false;
            if ("pending".indexOf(search) !== -1) {
              if (x.status !== CONST.DEPOSIT_STATUS.PENDING) {
                return "";
              }
              flag = true;
            }
            if ("completed".indexOf(search) !== -1) {
              if (x.status !== CONST.DEPOSIT_STATUS.COMPLETED) {
                return "";
              }
              flag = true;
            }
            if ("reverted".indexOf(search) !== -1) {
              if (x.status !== CONST.DEPOSIT_STATUS.REVERTED) {
                return "";
              }
              flag = true;
            }
            if (flag === false) {
              return "";
            }
          }
        }
        return (
          <Link className={styles.row} key={index} to="#">
            <div className={styles.col}>
              <div className={styles.item}>
                <div className={styles.icon}>
                  {coinIcons[x.currency] && (
                    <img src={coinIcons[x.currency].icon} alt="Coin" />
                  )}
                </div>
                <div className={styles.currency}>{x.currency}</div>
              </div>
            </div>
            <div className={styles.col}>
              {x.trade_type === CONST.TRADE_TYPE.SPOT ? t("Spot") : t("Margin")}
            </div>
            <div className={styles.col}>
              {activeIndex !== 2
                ? x.method === CONST.TRADE_METHOD.DIRECT
                  ? t("Direct Transfer")
                  : t("Exchange Withdraw")
                : x.user_id === user.id
                ? t("To")
                : t("From")}
            </div>
            <div className={styles.col}>
              <div className={styles.label}>{t("Amount")}</div>
              {parseFloat(x.amount).toFixed(6)}
            </div>
            <div className={styles.col}>
              {x.status === CONST.DEPOSIT_STATUS.PENDING ? (
                <div className={cn("category-blue", styles.category)}>
                  {t("PENDING")}
                </div>
              ) : x.status === CONST.DEPOSIT_STATUS.COMPLETED ? (
                <div className={cn("category-green", styles.category)}>
                  {t("COMPLETED")}
                </div>
              ) : x.status === CONST.DEPOSIT_STATUS.PROCESSING ? (
                <div className={cn("category-blue", styles.category)}>
                  {t("PROCESSING")}
                </div>
              ) : x.status === CONST.DEPOSIT_STATUS.DECLINE ? (
                <div className={cn("category-red", styles.category)}>
                  {t("DECLINED")}
                </div>
              ) : x.status === CONST.DEPOSIT_STATUS.REVERT_PENDING ? (
                <div className={cn("category-blue", styles.category)}>
                  {t("REVERT PENDING")}
                </div>
              ) : (
                <div className={cn("category-gray", styles.category)}>
                  {t("REVERTED")}
                </div>
              )}
            </div>
            <div className={styles.col}>
              <div className={styles.label}>{t("Address")}</div>
              {activeIndex !== 2
                ? x.user_address
                  ? x.user_address.slice(0, 10) +
                    "..." +
                    x.user_address.slice(
                      x.user_address.length - 10,
                      x.user_address.length
                    )
                  : "---"
                : x.user_id === user.id
                ? x.client_id
                : x.user_id}
            </div>
            <div className={styles.col}>
              <div className={styles.label}>{t("Transaction ID")}</div>
              {x.tx_id
                ? x.tx_id.slice(0, 15) +
                  "..." +
                  x.tx_id.slice(x.tx_id.length - 10, x.tx_id.length)
                : "---"}
            </div>
            <div className={styles.col}>
              <div className={styles.label}>{t("Date")}</div>
              {new Date(x.created_at).toLocaleDateString()}
            </div>
            {/* <div className={styles.col}>
            <button
            className={cn(
              "button-xs",
              "button-green",
              styles.button
            )}
            onClick={() => onClickValidate(x.request_code, x.trade_type)}
            > 
              <span>{t("Request Validate")}</span>
            </button>
            </div> */}
          </Link>
        );
      })}
      <Modal
        visible={visisbleValidate}
        onClose={() => setVisibleValidate(false)}
      >
        <ValidateDeposit requestCode={requestCode} tradeType={tradeType} />
      </Modal>
    </div>
  );
};

export default Table;
