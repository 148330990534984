import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Calendar.module.sass";
import Icon from "../Icon";
import OutsideClickHandler from "react-outside-click-handler";
import DatePicker from "react-datepicker";
import ja from "date-fns/locale/ja";

import { useTranslation } from "react-i18next";

const Calendar = ({ className, onChangeDate, defaultValue }) => {
  const { t, i18n } = useTranslation();
  const dates = ["One day", "One week", "One month", "One year", "All time"];

  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [durationType, setDurationType] = useState(
    defaultValue ? defaultValue : "All time"
  );
  const onChange = (dates, custom = "Custom") => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (onChangeDate) {
      onChangeDate(start, end);
      setDurationType(custom);
      setVisible(false);
    }
  };

  const onClick = (type) => {
    if (type === "One day") {
      const today = new Date();
      const prevDay = new Date();
      prevDay.setDate(prevDay.getDate() - 1);
      onChange([prevDay, today], type);
    } else if (type === "One week") {
      const today = new Date();
      const prevDay = new Date();
      prevDay.setDate(prevDay.getDate() - 7);
      onChange([prevDay, today], type);
    } else if (type === "One month") {
      const today = new Date();
      const prevDay = new Date();
      prevDay.setMonth(prevDay.getMonth() - 1);
      onChange([prevDay, today], type);
    } else if (type === "One year") {
      const today = new Date();
      const prevDay = new Date();
      prevDay.setFullYear(prevDay.getFullYear() - 1);
      onChange([prevDay, today], type);
    } else if (type === "All time") {
      onChange([new Date(), null], type);
    }
  };

  useEffect(() => {
    onClick(durationType);
  }, []);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(className, styles.calendar)}>
        <button
          className={cn(
            "button-stroke button-small",
            { [styles.active]: visible },
            styles.button
          )}
          onClick={() => setVisible(!visible)}
        >
          <span>{t(durationType)}</span>
          <Icon name="calendar" size="16" />
        </button>
        <div className={cn(styles.body, { [styles.show]: visible })}>
          <div className={styles.datepicker}>
            <DatePicker
              locale={i18n.language === "jp" ? ja : undefined}
              selected={startDate}
              onChange={(date) => onChange(date)}
              startDate={startDate}
              endDate={endDate}
              monthsShown={2}
              dateFormatCalendar={"MMM yyyy"}
              selectsRange
              inline
            />
          </div>
          <div className={styles.variants}>
            {dates.map((x, index) => (
              <button
                className={cn("button-stroke button-small", styles.button)}
                key={index}
                onClick={() => onClick(x)}
              >
                {t(x)}
              </button>
            ))}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Calendar;
