import { useEffect, useRef } from "react";
import { useState } from "react";
import { useMemo } from "react";
import RestService from "../../adapter/base";
import cn from "classnames";
import styles from "./Blog.module.sass";
import React from 'react';

const Blog = () => {
  const blogRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const blogId = useMemo(() => queryParams.get("blog_id"), [queryParams]);
  const [blog, setBlog] = useState({});

  useEffect(() => {
    RestService.get("/blog", {
      blog_id: blogId,
    }).then((res) => {
      setBlog(res);
    });
  }, [blogId]);

  useEffect(() => {
    if (!blogRef.current) {
      return;
    }

    blogRef.current.innerHTML = blog.content;
  }, [blogRef, blog]);

  return (
    <div className={styles.blog}>
      <div className={styles.title}>{blog.title}</div>
      {blog.image && (
        <img
          src={`${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${blog.image}`}
          alt=""
          onError={(e) => {
            setBlog({ ...blog, image: undefined });
          }}
          className={styles.logo}
        />
      )}
      <div className={styles.content} ref={blogRef} />
    </div>
  );
};

export default Blog;
