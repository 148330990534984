import React, { useEffect, useState } from "react";
import cn from "classnames";
import jwt from "jsonwebtoken";
import { toast } from "react-toastify";
import styles from "./Table.module.sass";
import Dropdown from "../../../components/Dropdown";
import RestService from "../../../adapter/base";

import CONST from "../../../common/const";
import { useBitmaidoContext } from "../../../hooks/useBitmaidoContext";
import Calendar from "../../../components/Calendar";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Table = (props) => {
  const { t } = useTranslation();

  const navigation = [t("Open orders"), t("Order history")];

  const { symbolInfo, refreshOrderTable, setRefreshOrderTable, orders } =
    useBitmaidoContext();
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(navigation[0]);
  const [items, setItems] = useState([]);
  const [historyItems, setHistoryItems] = useState([]);
  const [user, setUser] = useState({});

  const [startDate, setStartDate] = useState(new Date("1999/1/1"));
  const [endDate, setEndDate] = useState(new Date("2100/1/1"));

  useEffect(() => {
    try {
      const token = window.localStorage.getItem("token");
      const userInfo = jwt.verify(token, process.env.REACT_APP_SALT);
      setUser(userInfo);
    } catch {
      setUser({});
    }
  }, []);

  useEffect(() => {
    if (user.id === undefined) return;
    RestService.get("/orders/list", {
      trade_type: 1,
      symbol: symbolInfo.symbol,
    }).then((orders) => {
      if (!orders) {
        return;
      }
      orders = orders.slice(0, orders.length).reverse();
      setItems(orders);
    });

    RestService.get("/orders/history", {
      trade_type: 1,
      symbol: symbolInfo.symbol,
    }).then((orders) => {
      if (!orders) {
        return;
      }
      orders = orders.slice(0, orders.length).reverse();
      setHistoryItems(orders);
    });
  }, [refreshOrderTable, user]);

  const cancelOrder = (orderId) => {
    RestService.post("/orders/cancel", {
      order_id: orderId,
      trade_type: 1,
      symbol: symbolInfo.symbol,
    }).then((data) => {
      if (data) {
        toast.success(t("Order cancelled successfully"));
        setTimeout(() => setRefreshOrderTable(refreshOrderTable + 1), 1000);
      } else {
        toast.error(t("Order Cancel failed"));
      }
    });
  };

  const onChangeDate = (start, end) => {
    if (start === null || end === null) {
      setStartDate(new Date("1999/1/1"));
      setEndDate(new Date("2100/1/1"));
      return;
    }
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    setActiveIndex(navigation.indexOf(sorting));
  }, [sorting]);

  return (
    <div className={styles.inner}>
      <Dropdown
        className={styles.dropdown}
        value={sorting}
        setValue={setSorting}
        options={navigation}
      />
      {activeIndex === 1 && (
        <div className={styles.calendarTablet}>
          <Calendar
            className={styles.calendar}
            onChangeDate={onChangeDate}
            defaultValue="One day"
          />
        </div>
      )}
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
        {activeIndex === 1 && (
          <div className={styles.calendarContainer}>
            <Calendar
              className={styles.calendar}
              onChangeDate={onChangeDate}
              defaultValue="One day"
            />
          </div>
        )}
      </div>
      {activeIndex === 0 ? (
        <div className={styles.tableField}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>
                <div>{t("Opened")}</div>
              </div>
              <div className={styles.col}>
                <div>
                  {t("Stop")} ({symbolInfo.foreignCurrency})
                </div>
              </div>
              <div className={styles.col}>
                <div>
                  {t("Price")} ({symbolInfo.foreignCurrency})
                </div>
              </div>
              <div className={styles.col}>
                <div>
                  {t("Amount")} ({symbolInfo.baseCurrency})
                </div>
              </div>
              <div className={styles.col}>
                <div>{t("Side")}</div>
              </div>
              <div className={styles.col}>
                <div>{t("Type")}</div>
              </div>
              <div className={styles.col}>
                <div>{t("Action")}</div>
              </div>
            </div>
            {items.map((x, index) => {
              if (parseInt(x.status) !== CONST.ORDER_STATUS.OPEN) return "";
              const time = x.created_at
                .replace("T", " ")
                .substring(0, 19)
                .split(/[- :]/);
              const date = new Date(
                Date.UTC(
                  time[0],
                  time[1] - 1,
                  time[2],
                  time[3],
                  time[4],
                  time[5]
                )
              );
              return (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    {moment(date).format("YYYY-MM-DD hh:mm:ss")}
                  </div>
                  <div className={styles.col}>
                    {x.type === CONST.SPOT_TYPE.STOP && x.stop
                      ? parseFloat(x.stop).toFixed(symbolInfo.precision)
                      : "-"}
                  </div>
                  <div className={styles.col}>
                    {x.type === CONST.SPOT_TYPE.MARKET
                      ? "-"
                      : parseFloat(x.price).toFixed(symbolInfo.precision)}
                  </div>
                  <div className={styles.col}>{parseFloat(x.quantity)}</div>
                  <div className={styles.col}>
                    {x.side === CONST.TRADE_SIDE.SELL ? t("Sell") : t("Buy")}
                  </div>
                  <div className={styles.col}>
                    {x.type === CONST.SPOT_TYPE.LIMIT
                      ? t("Limit")
                      : x.type === CONST.SPOT_TYPE.MARKET
                      ? t("Market")
                      : t("Stop Limit")}
                  </div>
                  <div className={styles.col}>
                    <button
                      className={styles.cancel}
                      onClick={() => cancelOrder(x.id)}
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className={styles.tableField}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>
                <div>{t("Opened")}</div>
              </div>
              <div className={styles.col}>
                <div>
                  {t("Open Price")} ({symbolInfo.foreignCurrency})
                </div>
              </div>
              <div className={styles.col}>
                <div>
                  {t("Close Price")} ({symbolInfo.foreignCurrency})
                </div>
              </div>
              <div className={styles.col}>
                <div>
                  {t("Amount")} ({symbolInfo.baseCurrency})
                </div>
              </div>
              <div className={styles.col}>
                <div>{t("Fee")}</div>
              </div>
              <div className={styles.col}>
                <div>{t("Side")}</div>
              </div>
              <div className={styles.col}>
                <div>{t("Type")}</div>
              </div>
              <div className={styles.col}>
                <div>{t("Status")}</div>
              </div>
              <div className={styles.col}>
                <div>{t("Closed")}</div>
              </div>
            </div>
            {historyItems.map((x, index) => {
              const time = x.updated_at
                .replace("T", " ")
                .substring(0, 19)
                .split(/[- :]/);
              const date = new Date(
                Date.UTC(
                  time[0],
                  time[1] - 1,
                  time[2],
                  time[3],
                  time[4],
                  time[5]
                )
              );

              const createTime = x.created_at
                .replace("T", " ")
                .substring(0, 19)
                .split(/[- :]/);

              const createDate = new Date(
                Date.UTC(
                  createTime[0],
                  createTime[1] - 1,
                  createTime[2],
                  createTime[3],
                  createTime[4],
                  createTime[5]
                )
              );

              if (!(date >= startDate && date <= endDate)) {
                return "";
              }
              return (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    {moment(createDate).format("YYYY-MM-DD hh:mm:ss")}
                  </div>
                  <div className={styles.col}>
                    {parseFloat(x.open_price).toFixed(symbolInfo.precision)}
                  </div>
                  <div className={styles.col}>
                    {x.status !== CONST.ORDER_HISTORY_STATUS.CANCELLED
                      ? parseFloat(x.close_price).toFixed(symbolInfo.precision)
                      : "-"}
                  </div>
                  <div className={styles.col}>{parseFloat(x.quantity)}</div>
                  <div className={styles.col}>
                    {x.status !== CONST.ORDER_HISTORY_STATUS.CANCELLED
                      ? `${parseFloat(x.fee_quantity)} ${
                          x.side === CONST.TRADE_SIDE.SELL
                            ? symbolInfo.foreignCurrency
                            : symbolInfo.baseCurrency
                        }`
                      : "-"}
                  </div>
                  <div
                    className={cn(
                      styles.col,
                      x.side === CONST.TRADE_SIDE.SELL
                        ? styles.colorR2
                        : styles.colorG1
                    )}
                  >
                    {x.side === CONST.TRADE_SIDE.SELL ? t("Sell") : t("Buy")}
                  </div>
                  <div className={styles.col}>
                    {x.type === CONST.SPOT_TYPE.LIMIT
                      ? t("Limit")
                      : x.type === CONST.SPOT_TYPE.MARKET
                      ? t("Market")
                      : t("Stop Limit")}
                  </div>
                  <div
                    className={cn(
                      styles.col,
                      x.status === CONST.ORDER_HISTORY_STATUS.CANCELLED
                        ? styles.colorR2
                        : styles.colorG1
                    )}
                  >
                    {x.status === CONST.ORDER_HISTORY_STATUS.CANCELLED
                      ? t("Cancelled")
                      : x.status === CONST.ORDER_HISTORY_STATUS.FILLED
                      ? t("Filled")
                      : t("Subfilled")}
                  </div>
                  <div className={cn(styles.col, styles.colorN4)}>
                    {moment(date).format("YYYY-MM-DD hh:mm:ss")}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
