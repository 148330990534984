import { useState, useRef } from "react";
import cn from "classnames";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import styles from "./Avatar.module.sass";
import React from 'react';

const Avatar = ({ image, setImage }) => {
  const [file, setFile] = useState();
  const [iconStatus, setIconStatus] = useState(false);
  const [cropper, setCropper] = useState();

  const getCroppedAvatar = async () => {
    if (cropper) {
      const cropped = await fetch(cropper.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], "newAvatar.png", { type: "image/png" });
        });
      setFile(undefined);
      setImage(cropped);
    }
  };
  const inputRef = useRef(null);

  const handleFileChange = (event) => {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function (e) {
      setFile(reader.result);
    };
  };

  const onSelectAvatar = () => {
    inputRef.current.click();
  };

  return (
    <div className={styles.avatar}>
      <img
        src={
          image
            ? `${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${image}`
            : "images/content/avatar-default.svg"
        }
        width={80}
        className={styles.avatarImg}
      />
      <input
        style={{ display: "none" }}
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
      />
      <button
        className={styles.avatarChange}
        onMouseEnter={() => setIconStatus(true)}
        onMouseLeave={() => setIconStatus(false)}
        onClick={onSelectAvatar}
      >
        {iconStatus && (
          <img
            src={
              image ? "images/content/pen.svg" : "images/content/plus-icon.svg"
            }
            width={30}
            height={30}
          />
        )}
      </button>
      {file && (
        <>
          <Cropper
            src={file}
            style={{
              height: "100vh",
              width: "100vw",
              position: "fixed",
              left: 0,
              top: 0,
              zIndex: 100,
            }}
            initialAspectRatio={4 / 3}
            minCropBoxHeight={100}
            minCropBoxWidth={100}
            guides={false}
            checkOrientation={false}
            onInitialized={(instance) => setCropper(instance)}
          />
          <button
            className={cn("button", styles.cropButton)}
            onClick={getCroppedAvatar}
          >
            OK
          </button>
        </>
      )}
    </div>
  );
};

export default Avatar;
