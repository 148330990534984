import React, { useState } from "react";
import cn from "classnames";
import { toast } from "react-toastify";
import styles from "./TwoFaEnabled.module.sass";
import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";
import RestService from "../../../adapter/base";

import { useTranslation } from "react-i18next";

const TwoFaEnabled = ({ user }) => {
  const { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");

  const goDisabled = () => {
    if (!user) toast.error(t("Update Error! Invalid User Information."));
    RestService.put("/user/mfa/update", {
      email: user.email,
      password: password,
      code: code,
      enabled: 0,
    }).then((res) => {
      if (!res.error) {
        window.location = "/2fa";
      } else {
        toast.error(
          t("Please scan the QR code and enter your password, 2FA code.")
        );
      }
    });
  };

  const onChangeCode = (e) => {
    try {
      const value = e.target.value;
      if (value === "") {
        setCode(value);
        return;
      }
      const num = parseInt(value);
      if (!isNaN(num) && num < 1000000) {
        setCode(value);
      }
    } catch {}
  };

  return (
    <div>
      <div className={cn("h3", styles.title)}>
        {t("2FA")} <span>{t("Enabled")}</span>
      </div>
      <div className={styles.text}>
        {t(
          'If you want to turn off 2FA, input your account password and the six-digit code provided by the Google Authenticator app below, then click "'
        )}
        <span>{t("Disable 2FA")}</span>".
      </div>
      <div className={styles.subtitle}>{t("Disable 2FA")}</div>
      <div className={styles.info}>
        {t(
          "Enter your password and the six-digit code provided by the Google Authenticator app to Disable the 2FA verification"
        )}
      </div>
      <div className={styles.email}>
        <Icon name="email" size="24" />
        {user && user.email}
      </div>
      <div className={styles.row}>
        <TextInput
          className={styles.field}
          label={t("password")}
          name={"password"}
          type="password"
          placeholder={t("Password")}
          required
          view
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextInput
          className={styles.field}
          label={t("2FA code")}
          name={"code"}
          type="text"
          required
          value={code}
          onChange={onChangeCode}
        />
      </div>
      <button className={cn("button-red", styles.button)} onClick={goDisabled}>
        {t("Disable 2FA")}
      </button>
    </div>
  );
};

export default TwoFaEnabled;
