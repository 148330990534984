import React, { useState } from "react";
import cn from "classnames";
import styles from "./UserHeader.module.sass";
import { useTranslation } from "react-i18next";
import { USER_TAB } from "../../../common/const";
import { isLoggedIn } from "../../../common/helper";

const UserHeader = ({
  selected,
  handleTab,
  token,
  handleShowComment,
  handleShowRecommend,
  showAction,
}) => {
  const { t } = useTranslation();

  return (
    <div
      id="user_header"
      className={cn("section", styles.section)}
      style={{ marginBottom: "0px" }}
    >
      <div className={styles.center}>
        <div className={styles.header_wrapper}>
          <div
            className={
              selected === USER_TAB.STORY
                ? styles.header_menu_selected
                : styles.header_menu
            }
            onClick={() => handleTab(USER_TAB.STORY)}
          >
            {t("Story")}
          </div>
          <div
            className={
              selected === USER_TAB.FAQ
                ? styles.header_menu_selected
                : styles.header_menu
            }
            onClick={() => handleTab(USER_TAB.FAQ)}
          >
            {t("FAQ")}
          </div>
          <div
            className={
              selected === USER_TAB.UPDATE
                ? styles.header_menu_selected
                : styles.header_menu
            }
            onClick={() => handleTab(USER_TAB.UPDATE)}
          >
            {t("Update")}
          </div>
          <div
            className={
              selected === USER_TAB.COMMENT
                ? styles.header_menu_selected
                : styles.header_menu
            }
            onClick={() => handleTab(USER_TAB.COMMENT)}
          >
            {t("Comment")}
          </div>
          <div
            className={
              selected === USER_TAB.RECOMMEND
                ? styles.header_menu_selected
                : styles.header_menu
            }
            onClick={() => handleTab(USER_TAB.RECOMMEND)}
          >
            {t("Recommends")}
          </div>
          <button
            className={cn("button", styles.button)}
            onClick={() => handleShowComment(true)}
          >
            {t("Comment")}
          </button>
          {isLoggedIn() && (
            <button
              className={cn("button", styles.button)}
              onClick={() => handleShowRecommend(true)}
            >
              {t("Recommend")}
            </button>
          )}
          {showAction && (
            <button
              className={cn("button", styles.button)}
              onClick={() =>
                (window.location.href = `/exchange/spot?symbol=${token}USDT`)
              }
            >
              {t("Go to trading")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserHeader;
