import React from "react";
import cn from "classnames";
import styles from "./ApiKeysDisabled.module.sass";
import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";

import { useTranslation } from "react-i18next";

const ApiKeysDisabled = ({ goNext }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.stage}>
        {t("Enable API access on your account to generate keys.")}
      </div>
      <div className={cn("h3", styles.title)}>
        {t("API Access is")}&nbsp;
        <span>{t("Disabled")}</span>
      </div>
      <div className={styles.email}>
        <Icon name="email" size="24" />
        support@a41.world
      </div>
      <div className={styles.subtitle}>{t("Enable API keys")}</div>
      <div className={styles.info}>
        {t("Enter your password and 2FA code to Enable the API keys")}
      </div>
      <div className={styles.row}>
        <TextInput
          className={styles.field}
          label={t("password")}
          name={"password"}
          type="password"
          placeholder={t("Password")}
          required
          view
        />
        <TextInput
          className={styles.field}
          label={t("2FA code")}
          name={"code"}
          type="text"
          required
        />
      </div>
      <button className={cn("button", styles.button)} onClick={goNext}>
        {t("Enable API keys")}
      </button>
    </div>
  );
};

export default ApiKeysDisabled;
