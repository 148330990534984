import React from "react";
import cn from "classnames";
import styles from "./ApiKeysEnabled.module.sass";
import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";

import { useTranslation } from "react-i18next";

const ApiKeysEnabled = ({ goNext }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.stage}>
        {t("Enable API access on your account to generate keys.")}
      </div>
      <div className={cn("h3", styles.title)}>{t("API Access is Enabled")}</div>
      <div className={styles.email}>
        <Icon name="email" size="24" />
        schinner@ui8.net
      </div>
      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.subtitle}>{t("Your API keys")}</div>
          <div className={styles.group}>
            <div className={styles.box}>
              <TextInput
                className={styles.field}
                classLabel={styles.label}
                classInput={styles.input}
                label={t("API key 1")}
                name={"key-1"}
                type="text"
              />
              <button className={styles.remove}>
                <Icon name="close" size="24" />
              </button>
              <div className={cn("category", styles.category)}>
                {t("copied")}
              </div>
            </div>
            <div className={styles.box}>
              <TextInput
                className={styles.field}
                classLabel={styles.label}
                classInput={styles.input}
                label={t("API key 2")}
                name={"key-2"}
                type="text"
              />
              <button className={styles.remove}>
                <Icon name="close" size="24" />
              </button>
              <div className={cn("category", styles.category)}>
                {t("copied")}
              </div>
            </div>
          </div>
          <button className={cn("button-stroke button-small", styles.button)}>
            <Icon name="plus" size="16" />
            <span>{t("Generate new API key")}</span>
          </button>
        </div>
        <div className={styles.item}>
          <div className={styles.subtitle}>{t("Disable API keys")}</div>
          <div className={styles.info}>
            {t("Enter your password and 2FA code to Disable the API keys")}
          </div>
          <div className={styles.row}>
            <TextInput
              className={styles.field}
              label={t("password")}
              name={"password"}
              type="password"
              placeholder={t("Password")}
              required
              view
            />
            <TextInput
              className={styles.field}
              label={t("2FA code")}
              name={"code"}
              type="text"
              required
            />
          </div>
          <button className={cn("button", styles.button)} onClick={goNext}>
            {t("Disable API keys")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApiKeysEnabled;
