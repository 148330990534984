import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./TextInput.module.sass";
import Icon from "../Icon";

import { useTranslation } from "react-i18next";

const TextInput = ({
  className,
  classLabel,
  classInput,
  label,
  empty,
  view,
  icon,
  note,
  validate,
  ...props
}) => {
  const { t } = useTranslation();

  const [type, setType] = useState(props.type);
  const [noteContent, setNoteContent] = useState(note);

  const showPassword = () => {
    if (type === "text") {
      setType("password");
    } else {
      setType("text");
    }
  };

  const onChange = (event) => {
    try {
      if (validate) {
        let isValidateSuccess = true;
        if (validate.difficulty) {
          const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
          if (!event.target.value.match(passw)) {
            setNoteContent(validate.messages.difficulty);
            isValidateSuccess = false;
          }
        }

        if (validate.length) {
          if (event.target.value.length < validate.length) {
            setNoteContent(validate.messages.length);
            isValidateSuccess = false;
          }
        }

        if (validate.empty) {
          if (event.target.value.length === 0) {
            setNoteContent(t("Field must not be empty"));
            isValidateSuccess = false;
          }
        }

        if (validate.larger !== undefined) {
          if (parseFloat(event.target.value) <= validate.larger) {
            setNoteContent(
              t("The field value must be larger than ", {
                value: validate.larger,
              })
            );
            isValidateSuccess = false;
          }
        }

        if (validate.func) {
          const result = validate.func(event.target.value);
          if (result.result === false) {
            setNoteContent(t(result.message));
          }
          isValidateSuccess = result.result;
        }

        if (isValidateSuccess === true) {
          setNoteContent(note);
        }
      }

      if (props.onChange) {
        props.onChange(event);
      }
    } catch {
      setNoteContent(t("Entered value is invalid"));
    }
  };

  useEffect(() => {
    if (note) setNoteContent(note);
  }, [note]);

  return (
    <div
      className={cn(
        styles.field,
        { [styles.empty]: empty },
        { [styles.view]: view },
        { [styles.icon]: icon },
        className
      )}
    >
      {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
      <div className={styles.wrap}>
        <input
          className={cn(classInput, styles.input)}
          {...props}
          type={type}
          onChange={onChange}
        />
        {view && (
          <button
            type="button"
            className={styles.toggle}
            onClick={showPassword}
          >
            <Icon name="eye" size="24" />
          </button>
        )}
        {icon && (
          <div className={styles.preview}>
            <Icon name={icon} size="24" />
          </div>
        )}
        {noteContent && noteContent === note ? (
          <div className={styles.note}>{noteContent}</div>
        ) : (
          <div className={`${styles.note} ${styles.noteDanger}`}>
            {noteContent}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextInput;
