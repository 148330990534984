import React from 'react';
import { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import styles from "./Sns.module.sass";
import { useTranslation } from "react-i18next";
import RestService from "../../../adapter/base";
import axios from "axios";
import { Link } from "react-router-dom";
import { shortenText } from "../../../common/helper";
import Youtube from "./youtube";
import Twitter from "./twitter";
import Instagram from "./instagram";

const Sns = () => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const userId = useMemo(() => queryParams.get("user_id"), [queryParams]);
  const currency = useMemo(() => queryParams.get("currency"), [queryParams]);
  const snsId = useMemo(() => queryParams.get("id"), [queryParams]);
  const type = useMemo(() => queryParams.get("type"), [queryParams]);

  const [user, setUser] = useState({});

  const [youtubeChannel, setYoutubeChannel] = useState({});

  useEffect(() => {
    RestService.get("/user/public", {
      user_id: userId,
      currency,
    }).then((res) => {
      setUser(res);
    });
  }, [userId]);

  useEffect(() => {
    if (type === "youtube") {
      axios
        .get("https://www.googleapis.com/youtube/v3/channels", {
          params: {
            part: "snippet,id",
            id: snsId, // should change this with real user sns account
            key: process.env.REACT_APP_YOUTUBE_API,
          },
        })
        .then((res) => {
          setYoutubeChannel(res.data.items[0]);
        });
    }
  }, [snsId, type]);

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head_container}>
          <img src={`/images/content/${type}.svg`} alt={t("Currency")} />
          <h1 className={cn("h1", styles.title)}>{user.fullname}</h1>
        </div>
        {type === "youtube" && <Youtube />}
        {type === "twitter" && <Twitter />}
        {type === "instagram" && <Instagram />}
      </div>
    </div>
  );
};

export default Sns;
