import React, { useEffect, useState, useRef, createRef } from "react";
import { widget } from '../../charting_library';

import { useBitmaidoContext } from "../../hooks/useBitmaidoContext";

import './TradingView.css';

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

const TradingView = ({symbol, frame, theme}) => {
  const ref = React.createRef();

	const { datafeed, setDatafeed } = useBitmaidoContext();

  const defaultProps = {
		symbol: 'AAPL',
		interval: '1',
		datafeedUrl: 'https://demo_feed.tradingview.com',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: false,
		studiesOverrides: {},
	};

  const [tvWidget, setTVWidget] = useState();

	useEffect(() => {
		let datafeed = new window.Datafeeds.UDFCompatibleDatafeed(process.env.REACT_APP_REST_ENDPOINT);
		setDatafeed(datafeed);
	}, [])
	

  useEffect(() => {
		if (datafeed === null) return;
		
    const widgetOptions = {
      symbol: symbol,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: datafeed,
      interval: frame,
      container: ref.current,
      library_path: defaultProps.libraryPath,
  
      locale: getLanguageFromURL() || 'en',
      disabled_features: ['use_localstorage_for_settings', 'header_symbol_search', 'header_resolutions', 'seconds_resolution', 'timeframes_toolbar'],
      enabled_features: [],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: false,
      autosize: false,
      studies_overrides: defaultProps.studiesOverrides,
      allow_symbol_change: false,
			theme: theme,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    let tvWidget = new widget(widgetOptions);
    tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to show a notification popup');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => tvWidget.showNoticeDialog({
					title: 'Notification',
					body: 'TradingView Charting Library API works correctly',
					callback: () => {
						console.log('Noticed!');
					},
				}));

				button.innerHTML = 'Check API';
			});
		});

    setTVWidget(tvWidget);
  }, [symbol, frame, theme, datafeed])

  return (
    <div
				ref={ ref }
				className={ 'TVChartContainer' }
			/>
  )
}

export default TradingView;