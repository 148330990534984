import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import RestService from "../../adapter/base";
import Profile from "../../components/Profile";
import TextInput from "../../components/TextInput";
import styles from "./NewToken.module.sass";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CONST, { TOKEN_CATEGORY } from "../../common/const";
import Dropdown from "../../components/Dropdown";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import lang from "suneditor/src/lang";
import Calendar from "../../components/CalendarDate";
import { useHistory } from "react-router-dom";
import TextArea from "../../components/TextArea";

const NewToken = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [precision, setPrecision] = useState("");
  const [category, setCategory] = useState(TOKEN_CATEGORY.ARTIST);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");
  const [story, setStory] = useState("");
  const [targetAmount, setTargetAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("100000000");
  const [lockTime, setLockTime] = useState();

  const [apiCompleted, setApiCompleted] = useState(false);

  const [pending, setPending] = useState(false);
  const [targetNote, setTargetNote] = useState("");

  const breadcrumbs = [
    {
      title: t("Home"),
      url: "/",
    },
    {
      title: t("Your token settings"),
    },
  ];

  const validate = () => {
    if (!name) {
      return false;
    }

    if (!description) {
      return false;
    }

    if (!precision || isNaN(precision)) {
      return false;
    }

    if (!file) {
      toast.error(t("Please select the file."));
      return false;
    }

    if (!story) {
      return false;
    }

    if (!targetAmount || isNaN(targetAmount)) {
      return false;
    }

    if (!totalAmount || isNaN(totalAmount)) {
      return false;
    }

    if (parseInt(targetAmount) > parseInt(totalAmount) / 2) {
      toast.error(
        t("Presale amount should be smaller than half of the total amount")
      );
      return false;
    }

    if (!lockTime) {
      toast.error(t("Please select the token release date."));
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }

    setPending(true);

    let data = new FormData();
    data.append("currency", name);
    data.append("description", description);
    data.append("precision", parseInt(precision));
    if (file) {
      data.append("promotion_url", file);
    }
    data.append("story", story);
    data.append("target_amount", parseInt(targetAmount));
    data.append("total_amount", parseInt(totalAmount));
    data.append(
      "lock_time",
      `${lockTime.getFullYear()}-${
        lockTime.getMonth() + 1
      }-${lockTime.getDate()}`
    );
    data.append("type", CONST.CURRENCY_TYPE.USER_DEPLOYED);
    data.append("user_id", parseInt(window.localStorage.getItem("user_id")));
    data.append("deposit_fee", 0);
    data.append("category", category);

    RestService.post("/currency/new", data).then((res) => {
      setPending(false);
      if (!res.error) {
        toast.success(t("Token is updated successfully."));
        history.push("/token-settings");
      } else {
        toast.error(
          `${t("Token update is failed.")} ${t("Internal Server Error")}`
        );
      }
    });
  };

  const getCategoryName = (category) => {
    switch (category) {
      case TOKEN_CATEGORY.ARTIST:
        return t("Artist");
      case TOKEN_CATEGORY.SPORTS:
        return t("Sports");
      case TOKEN_CATEGORY.ACTOR:
        return t("Actor");
      case TOKEN_CATEGORY.BUSINESS:
        return t("Business");
      case TOKEN_CATEGORY.MUSIC:
        return t("Music");
      case TOKEN_CATEGORY.OTHERS:
      default:
        return t("Others");
    }
  };

  const handleCategorySelected = (selected) => {
    switch (selected) {
      case t("Artist"):
        setCategory(TOKEN_CATEGORY.ARTIST);
        break;
      case t("Sports"):
        setCategory(TOKEN_CATEGORY.SPORTS);
        break;
      case t("Actor"):
        setCategory(TOKEN_CATEGORY.ACTOR);
        break;
      case t("Business"):
        setCategory(TOKEN_CATEGORY.BUSINESS);
        break;
      case t("Music"):
        setCategory(TOKEN_CATEGORY.MUSIC);
        break;
      case t("Others"):
      default:
        setCategory(TOKEN_CATEGORY.OTHERS);
        break;
    }
  };

  const getLanguage = () => {
    switch (i18n.language) {
      case "jp":
        return lang.ja;
      case "cn":
        return lang.zh_cn;
      case "en":
      default:
        return lang.en;
    }
  };

  const currentLanguage = useMemo(() => {
    switch (i18n.language) {
      case "jp":
        return lang.ja;
      case "cn":
        return lang.zh_cn;
      case "en":
      default:
        return lang.en;
    }
  }, [i18n.language]);

  useEffect(() => {
    setApiCompleted(false);
    setTimeout(() => setApiCompleted(true), 500);
  }, [currentLanguage]);

  useEffect(() => {
    if (!targetAmount) setTargetNote("");
    else
      setTargetNote(
        t(
          "Estimated XXX USDT value is given to your wallet when you complete selling the presale tokens you have set here.",
          { value: parseFloat(targetAmount) / 100 }
        )
      );
  }, [targetAmount]);

  return (
    <Profile title={t("Your token settings")} breadcrumbs={breadcrumbs}>
      <div className={styles.head}>{t("Your token settings")}</div>
      <div className={styles.row}>
        {t(
          "Create a profile for your project, and if you get a recommendation, tokens will be issued. Create engaging content."
        )}
      </div>
      <div className={styles.row}>
        <TextInput
          type="text"
          className={styles.col}
          label={t("Token Name")}
          value={name}
          onChange={(event) => setName(event.target.value)}
          validate={{ empty: true }}
        />
        <TextInput
          type="text"
          label={t("Total amount")}
          className={styles.col}
          value={totalAmount}
          onChange={(event) => setTotalAmount(event.target.value)}
          validate={{
            empty: true,
            func: (text) => {
              if (text.length === 0) {
                return {
                  result: false,
                  message: t("Field must not be empty"),
                };
              }
              if (isNaN(text)) {
                return {
                  result: false,
                  message: t("Please input digit string."),
                };
              }
              return {
                result: true,
              };
            },
          }}
          disabled
        />
      </div>
      <div className={styles.field}>
        <TextInput
          type="text"
          label={t("Token Description")}
          style={{ width: "100%" }}
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          validate={{
            empty: true,
            func: (text) => {
              if (text.length > 64) {
                return {
                  result: false,
                  message: t("Field must be shorter than 64 letters."),
                };
              }
              return {
                result: true,
              };
            },
          }}
        />
      </div>
      <div className={styles.field}>
        <TextInput
          type="text"
          label={t("Precision")}
          style={{ width: "100px" }}
          value={precision}
          onChange={(event) => setPrecision(event.target.value)}
          validate={{
            empty: true,
            func: (text) => {
              if (text.length === 0) {
                return {
                  result: false,
                  message: t("Field must not be empty"),
                };
              }
              if (isNaN(text)) {
                return {
                  result: false,
                  message: t("Please input digit string."),
                };
              }
              return {
                result: true,
              };
            },
          }}
        />
      </div>
      <div className={styles.field}>
        <Dropdown
          label={t("Category")}
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          classDropdownArrow={styles.dropdownArrow}
          classDropdownBody={styles.dropdownBody}
          classDropdownOption={styles.dropdownOption}
          value={getCategoryName(category)}
          setValue={(selected) => handleCategorySelected(selected)}
          options={[
            t("Artist"),
            t("Sports"),
            t("Actor"),
            t("Business"),
            t("Music"),
            t("Others"),
          ]}
        />
      </div>

      <div className={styles.row}>
        <TextInput
          type="text"
          className={styles.col}
          label={t("Target presale amount")}
          value={targetAmount}
          onChange={(event) => setTargetAmount(event.target.value)}
          validate={{
            empty: true,
            func: (text) => {
              if (text.length === 0) {
                return {
                  result: false,
                  message: t("Field must not be empty"),
                };
              }
              if (isNaN(text)) {
                return {
                  result: false,
                  message: t("Please input digit string."),
                };
              }
              return {
                result: true,
              };
            },
          }}
          note={targetNote}
        />

        <Calendar
          label={t("Token Release Date")}
          onChangeDate={(e) => setLockTime(e)}
        />
      </div>
      <div className={styles.field}>
        <div className={styles.title}>
          {t("Upload your video or image for promotion")}
        </div>
        {fileName && (
          <div className={styles.row}>
            <img
              src={`${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${fileName}`}
              alt={fileName}
              style={{
                width: "100%",
              }}
            />
          </div>
        )}
        <input
          type="file"
          data-listener-added_d1f3db46="true"
          onChange={(event) => {
            setFile(event.currentTarget.files[0]);
          }}
          accept="image/*"
        />
        <div className={styles.description}>
          {t("We recommend images with an aspect ratio of 3*4 to 4*3.")}
        </div>
      </div>
      <div className={styles.field}>
        <div className={styles.title}>{t("Write about your story")}</div>
        {/* <TextArea
          style={{
            height: 480,
          }}
          value={story}
          onChange={(event) => setStory(event.target.value)}
          validate={{ empty: true }}
        /> */}
        {apiCompleted && (
          <SunEditor
            setOptions={{
              height: "400px",
              buttonList: [...buttonList.complex],
              lang: currentLanguage,
            }}
            defaultValue={story}
            onChange={(content) => {
              setStory(content);
            }}
          />
        )}
      </div>

      <button
        className={cn(
          "button",
          styles.button,
          pending ? "button--loading" : ""
        )}
        onClick={() => handleSubmit()}
        disabled={pending}
      >
        {t("Save and start your project")}
      </button>
    </Profile>
  );
};

export default NewToken;
