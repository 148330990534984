import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Successfully = ({ amount, currency, status, address }) => {
  const { t } = useTranslation();

  const items = [
    {
      title: t("Status"),
      content: status,
      color: "#58BD7D",
    },
    {
      title: t("Transaction ID"),
      content: "-",
    },
    {
      title: t("Address"),
      content: address,
    },
  ];

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Yay!{" "}
          <span role="img" aria-label="firework">
            🎉
          </span>
        </div>
        <div className={styles.info}>
          {t("Your withdrawal request from A41 is successfully accepted", {
            amount: amount,
            currency: currency,
          })}
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content} style={{ color: x.color }}>
                {x.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Successfully;
