import React from 'react';
import cn from "classnames";
import styles from "./Trend.module.sass";
import CONST from "../../../common/const";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import numeral from "numeral";
import { useEffect, useState } from "react";
import RestService from "../../../adapter/base";
import axios from "axios";

const TrendItem = ({ x, trendTab, index, activeSns, setActivate }) => {
  const { t } = useTranslation();
  const [snsList, setSnsList] = useState([]);

  let numeralFormat = "0,0.";
  for (let i = 0; i < x.precision; i++) {
    numeralFormat += "0";
  }

  const addLink = async () => {
    // const tmpList = [...x.sns];
    try {
      const tmpList = [...x.sns];
      for (let i = 0; i < tmpList.length; i++) {
        if (tmpList[i].category === "twitter") {
          const result = await RestService.get("/sns/twitter", {
            sns_name: tmpList[i].sns_name,
          });
          tmpList[i].href = `https://x.com/${result?.data?.username}`;
        } else if (tmpList[i].category === "youtube") {
          tmpList[i].href = `https://youtube.com/channel/${tmpList[i].sns_name}`;
        } else if (tmpList[i].category === "instagram") {
          const result = await axios.get(
            `https://graph.instagram.com/${tmpList[i].sns_name}`,
            {
              params: {
                access_token: process.env.REACT_APP_INSTAGRAM_API_TOKEN,
                fields: "id,username",
              },
            }
          );
          tmpList[i].href = `https://instagram.com/${result?.data?.username}`;
        } else if (tmpList[i].category === "facebook") {
          tmpList[i].href = `https://facebook.com/${tmpList[i].sns_name}`;
        } else tmpList[i].href = "";
      }
      // console.log(tmpList);
      setSnsList(tmpList);
    } catch (e) {
      ;
    }

  };

  useEffect(() => {
    addLink();
  }, [x]);

  return (
    <>
      <Link
        className={styles.row}
        to={`/exchange/spot?symbol=${x.currency}USDT`}
        onMouseEnter={() => setActivate(index)}
      >
        <div className={styles.col}>{index + 1}</div>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.icon}>
              <img
                src={
                  trendTab === CONST.TREND_TAB.USER_TOKEN
                    ? x.image
                      ? `${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${x.image}`
                      : "/images/content/avatar-default.svg"
                    : x.image
                }
                onError={(e) =>
                  (e.target.src = "/images/content/avatar-default.svg")
                }
                alt={t("Currency")}
              />
            </div>
            {trendTab === CONST.TREND_TAB.USER_TOKEN
                ? <div className={styles.details}>
                    <span className={styles.subtitle}>{x.title}</span>
                    <span className={styles.currency}>{x.currency}</span>
                  </div>
                : <div className={styles.details2}>
                    <span className={styles.subtitle}>{x.title}</span>
                    <span className={styles.currency}>{x.currency}</span>
                  </div>
            }
            {/* <div className={styles.details}>
              <span className={styles.subtitle}>{x.title}</span>
              <span className={styles.currency}>{x.currency}</span>
            </div> */}
          </div>
        </div>
        <div className={cn(styles.col, styles.textNoWrap)}>
          {/* $ {parseFloat(x.price) === 0 ? "-.--" : x.price.toLocaleString()} */}
          $ {parseFloat(x.price) === 0
            ? "-.--"
            : numeral(parseFloat(x.price)).format(numeralFormat)}
        </div>
        <div className={styles.col}>
          {x.changePct >= 0 && (
            <div className={cn(styles.positive, styles.textNoWrap)}>
              {parseFloat(x.changePct) === 0
                ? "-.--"
                : parseFloat(x.changePct).toFixed(2)}
              %
            </div>
          )}
          {x.changePct < 0 && (
            <div className={cn(styles.negative, styles.textNoWrap)}>
              {parseFloat(x.changePct).toFixed(2)}%
            </div>
          )}
        </div>
        <div className={styles.col}>
          {x.changePct >= 0 && (
            <div className={cn(styles.positive, styles.textNoWrap)}>
              {parseFloat(x.changePct) === 0
                ? "-.--"
                : parseFloat(x.changePct).toFixed(2)}
              %
            </div>
          )}
          {x.changePct < 0 && (
            <div className={cn(styles.negative, styles.textNoWrap)}>
              {parseFloat(x.changePct).toFixed(2)}%
            </div>
          )}
        </div>
        <div className={styles.col}>
          <div className={styles.chart}>
            {parseFloat(x.money) === 0
              ? "-.--"
              : numeral(parseFloat(x.money)).format("0,0.00")}
          </div>
          {/* <div className={styles.chart}>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        width={500}
                        height={400}
                        data={data}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <defs>
                          <linearGradient
                            id="colorPrice"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="#45B36B"
                              stopOpacity={0.6}
                            />
                            <stop
                              offset="95%"
                              stopColor="#45B36B"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <Area
                          type="monotone"
                          dataKey="price"
                          stroke="#58BD7D"
                          fillOpacity={1}
                          fill="url(#colorPrice)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div> */}
        </div>
        <div className={styles.col}>
          <div className={styles.chart}>
            {parseFloat(x.money) === 0
              ? "-.--"
              : numeral(parseFloat(x.money)).format("0,0.00")}
          </div>
          {/* <div className={styles.chart}>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        width={500}
                        height={400}
                        data={data}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <defs>
                          <linearGradient
                            id="colorPrice"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="#45B36B"
                              stopOpacity={0.6}
                            />
                            <stop
                              offset="95%"
                              stopColor="#45B36B"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <Area
                          type="monotone"
                          dataKey="price"
                          stroke="#58BD7D"
                          fillOpacity={1}
                          fill="url(#colorPrice)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div> */}
        </div>
        <div className={styles.col}>
          {(x.type !== 1 || x.icoStatus === 1) && (
            <button
              className={cn("button-stroke button-small", styles.buyButton)}
            >
              {trendTab === CONST.TREND_TAB.ICO ? t("To ICO") : t("Buy")}
            </button>
          )}
        </div>
      </Link>
      
      { trendTab === CONST.TREND_TAB.USER_TOKEN
        ? <div
            className={cn(styles.sns_container, activeSns ? "" : styles.hidden)}
            onMouseEnter={() => setActivate(index)}
          >
            <div className={cn(styles.sns_list, activeSns ? "" : styles.hidden)}>
              {snsList.length > 0
                ? snsList.map((item, ind) => (
                    <a
                      href={item.href}
                      key={`mysns_${index}_${ind}`}
                      target="_blank"
                    >
                      <img
                        src={`/images/content/${item.category}.${
                          item.category === "twitter" ? "png" : "svg"
                        }`}
                        alt=""
                      />
                    </a>
                  ))
                : t("No Sns Found")}
            </div>
          </div>
        : []
      }
    </>
  );
};

export default TrendItem;
