import React, { useRef, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import styles from "./YourBlogs.module.sass";
import Profile from "../../components/Profile";

import { useTranslation } from "react-i18next";
import { ReactComponent as DeleteIcon } from "../../assets/svg/delete.svg";
import { ReactComponent as PenIcon } from "../../assets/svg/pen.svg";
import { ReactComponent as HiddenIcon } from "../../assets/svg/hidden.svg";
import TextInput from "../../components/TextInput";
import Icon from "../../components/Icon";
import Modal from "../../components/Modal";
import TextArea from "../../components/TextArea";
import RestService from "../../adapter/base";
import { toast } from "react-toastify";

import "suneditor/dist/css/suneditor.min.css";
import lang from "suneditor/src/lang";
import SunEditor, { buttonList } from "suneditor-react";

const YourBlogs = () => {
  const { t, i18n } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const inputRef = useRef(null);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState();

  const [fileSrc, setFileSrc] = useState();

  const [blogs, setBlogs] = useState([]);

  const [updateId, setUpdateId] = useState();

  const [editorRefresh, setEditorRefresh] = useState(false);

  const breadcrumbs = [
    {
      title: t("Home"),
      url: "/",
    },
    {
      title: t("Your Blogs"),
    },
  ];

  const onAddNew = () => {
    setFileSrc(undefined);
    setContent("");
    setTitle("");
    setUpdateId(undefined);
    setModalVisible(true);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = (event) => {
      setFileSrc(event.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const onSelectFile = () => {
    inputRef.current.click();
  };

  const onPublishOrUpdate = (id = undefined) => {
    if (title === "") {
      toast.error(t("Title must not be empty"));
      return;
    }

    if (content === "") {
      toast.error(t("Content must not be empty"));
      return;
    }

    const data = new FormData();

    if (file) data.append("image", file);
    data.append("title", title);
    data.append("content", content);
    if (id) {
      data.append("id", id);
    }

    RestService.post("/blog", data).then(() => {
      toast.success(t("Blog published successfully!"));
      setModalVisible(false);
      getBlogList();
    });
  };

  const getBlogList = () => {
    RestService.get("/blog/all").then((res) => {
      setBlogs(res.rows);
    });
  };

  useEffect(() => {
    getBlogList();
  }, []);

  const onUpdateBlog = (_id, _img, _title, _content) => {
    setFileSrc(`${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${_img}`);
    setTitle(_title);
    setContent(_content);
    setUpdateId(_id);
    setModalVisible(true);
  };

  const onRemoveItem = (_id) => {
    RestService.post("/blog/remove", {
      id: _id,
    }).then((res) => {
      if (res === true) {
        toast.success(t("Blog deleted successfully!"));
        getBlogList();
      }
    });
  };

  const currentLanguage = useMemo(() => {
    switch (i18n.language) {
      case "jp":
        return lang.ja;
      case "cn":
        return lang.zh_cn;
      case "en":
      default:
        return lang.en;
    }
  }, [i18n.language]);

  useEffect(() => {
    setEditorRefresh(false);
    setTimeout(() => setEditorRefresh(true), 500);
  }, [currentLanguage]);

  return (
    <Profile title={t("Your Blogs")} breadcrumbs={breadcrumbs}>
      <div className={styles.title}>{t("Your Blog List")}</div>
      <div className={styles.scrollArea}>
        <div className={styles.table}>
          <div className={cn(styles.table_row, styles.table_header)}>
            <div className={styles.table_col}>{t("")}</div>
            <div className={styles.table_col}>{t("Title")}</div>
            <div className={styles.table_col}>{t("Date")}</div>
            <div className={styles.table_col}>{t("Action")}</div>
          </div>
          {blogs.map((blog, index) => (
            <div className={styles.table_row} key={`blog_${index}`}>
              <div className={styles.table_col}>
                {blog.image && (
                  <img
                    src={`${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${blog.image}`}
                    style={{
                      width: 40,
                    }}
                  />
                )}
              </div>
              <div className={styles.table_col}>{blog.title}</div>
              <div className={styles.table_col}>
                {new Date(blog.created_at).toLocaleDateString()}
              </div>
              <div className={styles.table_col}>
                <PenIcon
                  width={24}
                  height={24}
                  className={styles.action}
                  onClick={() =>
                    onUpdateBlog(blog.id, blog.image, blog.title, blog.content)
                  }
                />
                {/* <HiddenIcon width={20} height={20} className={styles.action} /> */}
                <DeleteIcon
                  width={20}
                  height={20}
                  className={styles.action}
                  onClick={() => onRemoveItem(blog.id)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={onAddNew}>
          {t("Add New")}
        </button>
      </div>
      <Modal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        outerClassName={styles.modalLg}
        disableOuterClose={true}
      >
        <div className={styles.snsUpdate}>
          <div className={cn("h4", styles.title)}>
            <Icon name="arrow-left" size="32" />
            {updateId ? t("Update your blog") : t("Add new blog")}
          </div>
          {fileSrc && <img src={fileSrc} />}
          <TextInput
            className={styles.field}
            label={t("Blog Title")}
            name="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />

          {editorRefresh && (
            <SunEditor
              setOptions={{
                height: "200px",
                buttonList: [...buttonList.complex],
                lang: currentLanguage,
              }}
              defaultValue={content}
              onChange={(content) => {
                setContent(content);
              }}
            />
          )}
          <input
            type="file"
            hidden
            ref={inputRef}
            onChange={handleFileChange}
          />
          <div className={styles.buttonRow}>
            <button
              className={cn("button-red", styles.button)}
              onClick={onSelectFile}
            >
              <Icon name="image" size={20} />
              {t("Upload Image")}
            </button>

            <button
              className={cn("button", styles.button)}
              onClick={() => onPublishOrUpdate(updateId)}
            >
              {t("Publish")}
            </button>
          </div>
        </div>
      </Modal>
    </Profile>
  );
};

export default YourBlogs;
