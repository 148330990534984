import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./Play.module.sass";
import Icon from "../Icon";
import { useRef } from "react";
import { useState } from "react";

const Play = ({ className, small }) => {
  const video = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const onPlayVideo = () => {
    video.current.play();
    setIsPlaying(true);
  };

  useEffect(() => {
    onPlayVideo();
  });
  return (
    <>
      {isPlaying === false && (
        <button
          className={cn(className, styles.play, {
            [styles.small]: small,
          })}
          onClick={onPlayVideo}
        >
          <Icon name="play" size="24" />
        </button>
      )}
      <video
        ref={video}
        className={styles.video}
        style={{
          visibility: isPlaying ? "visible" : "hidden",
        }}
        loop
        autoPlay
        muted
        controls={false}
      >
        <source src="/images/content/promo2.mp4" type="video/mp4" />
      </video>
    </>
  );
};

export default Play;
