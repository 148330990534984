import React, { useState } from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import Icon from "../Icon";
import Breadcrumbs from "../Breadcrumbs";

import { useTranslation } from "react-i18next";

const Proflie = ({ title, breadcrumbs, children }) => {
  const { t } = useTranslation();

  const navigation = [
    {
      title: t("Profile"),
      icon: "user",
      url: "/profile-info",
    },
    {
      title: t("Your Blogs"),
      icon: "image",
      url: "/blogs",
    },
    {
      title: t("Your token settings"),
      icon: "star",
      url: "/token-settings",
    },
    {
      title: t("FAQ"),
      icon: "laptop",
      url: "/user/faq",
    },
    {
      title: t("Your recommends"),
      icon: "heart",
      url: "/recommends",
    },
    {
      title: t("Referrals"),
      icon: "share",
      url: "/referrals",
    },
    {
      title: t("API keys"),
      icon: "keyboard",
      url: "/api-keys",
    },
    {
      title: t("KYC"),
      icon: "cloud",
      url: "/kyc",
      separator: true,
    },
    {
      title: t("Login history"),
      icon: "laptop",
      url: "/login-history",
    },
    {
      title: t("2FA"),
      icon: "barcode",
      url: "/2fa",
    },
    {
      title: t("Change password"),
      icon: "lock",
      url: "/change-password",
    },
  ];

  const { pathname } = useLocation();
  const [visible, setVisible] = useState(false);

  const activeLink = navigation.find((x) => pathname.includes(x.url));

  return (
    <div className={styles.profile}>
      <div className={styles.head}>
        <div className={cn("container", styles.container)}>
          <h2 className={cn("h2", styles.title)}>{title}</h2>
          <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
        </div>
      </div>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.sidebar}>
            <div className={cn(styles.dropdown, { [styles.active]: visible })}>
              <div className={styles.top} onClick={() => setVisible(!visible)}>
                <Icon name={activeLink.icon} size="24" />
                {activeLink.title}
              </div>
              <div className={styles.group}>
                <div className={styles.menu}>
                  {navigation.map((item, index) => (
                    <NavLink
                      className={cn(styles.link, {
                        [styles.separator]: item.separator,
                      })}
                      activeclassname={styles.active}
                      key={index}
                      to={item.url}
                      exact
                      onClick={() => setVisible(false)}
                    >
                      <Icon name={item.icon} size="24" />
                      {item.title}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.wrapper}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Proflie);
