import React, { useState } from "react";
import cn from "classnames";
import { toast } from "react-toastify";
import styles from "./Form.module.sass";
import { Link } from "react-router-dom";
import TextInput from "../../../components/TextInput";
import RestService from "../../../adapter/base";

import { useTranslation } from "react-i18next";

const Form = ({ goNext }) => {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState("");
  const onClickContinue = () => {
    RestService.put(`/user/password/request`, {
      email: email,
      locale: i18n.language,
    }).then((res) => {
      if (!res.error) {
        goNext(email);
      } else {
        if (res.error === "INVALID_EMAIL") {
          toast.error(t("Invalid e-mail address!"), {
            postition: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
          });
        } else {
          toast.error(t("Reset Request failed! Please try again!"), {
            postition: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
          });
        }
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  };
  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.top}>
        <h3 className={cn("h3", styles.title)}>{t("Forgot password")}</h3>
        <div className={styles.info}>
          {t(
            "For security purposes, no withdrawals are permitted for 24 hours after password changed."
          )}
        </div>
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label={t("Enter the account email")}
          name={"email"}
          type="email"
          placeholder={t("Your email")}
          icon="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <button className={cn("button", styles.button)} onClick={onClickContinue}>
        {t("Continue")}
      </button>
      <div className={styles.foot}>
        <Link className={styles.link} to="/sign-in">
          {t("Nevermind, I got it")}
        </Link>
      </div>
    </form>
  );
};

export default Form;
