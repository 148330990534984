import emailjs from '@emailjs/browser';
import CONST from './const';

export const sendRegisterationEmail = (templateParams) => {
  emailjs.send(
    CONST.EMAIL_PLUGIN.SERVICE_ID, 
    CONST.EMAIL_PLUGIN.TEMPLATE_IDS.REGISTERATION,
    templateParams,
    CONST.EMAIL_PLUGIN.USER_ID)
    .then((res) => {
      console.log(res);
    })
}

export const sendResetPasswordEmail = (templateParams) => {
  emailjs.send(
    CONST.EMAIL_PLUGIN.SERVICE_ID, 
    CONST.EMAIL_PLUGIN.TEMPLATE_IDS.RESETPASSWORD,
    templateParams,
    CONST.EMAIL_PLUGIN.USER_ID)
    .then((res) => {
      console.log(res);
    })
}