import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./FileInput.module.sass";
import Icon from "../Icon";

const FileInput = ({
  className,
  classLabel,
  classInput,
  label,
  empty,
  view,
  icon,
  note,
  value,
  setValue
}) => {
  const [noteContent, setNoteContent] = useState(note);
  const inputFileRef = useRef();

  const onFileChange = (e) => {
    console.log(e.target.files[0]);
    setValue(e.target.files[0]);
  };

  const onUploadFile = () => {
    inputFileRef.current.click();
  }

  return (
    <div
      className={cn(
        styles.field,
        { [styles.empty]: empty },
        { [styles.view]: view },
        { [styles.icon]: icon },
        className
      )}
    >
      {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
      <div className={styles.wrap}>
        <input 
          className={cn(classInput, styles.input)} 
          type="file" 
          ref={inputFileRef} 
          onChange={onFileChange}
          hidden/>
        <input 
          className={cn(classInput, styles.input)} 
          type="text" 
          value={value.name}
          readOnly/>
        <button type="button" className={styles.toggle} onClick={onUploadFile}>
          <Icon name="cloud" size="24" />
        </button>
        {icon && (
          <div className={styles.preview}>
            <Icon name={icon} size="24" />
          </div>
        )}
        {noteContent && noteContent === note ? 
          (<div className={styles.note}>{noteContent}</div>) :
          (<div className={`${styles.note} ${styles.noteDanger}`}>{noteContent}</div>)}
      </div>
    </div>
  );
};

export default FileInput;
