import jwt from "jsonwebtoken";

export function isLoggedIn() {
  const token = window.localStorage.getItem("token");
  try {
    const userInfo = jwt.verify(token, process.env.REACT_APP_SALT);

    return userInfo !== null;
  } catch (err) {
    return false;
  }
}

export function numberWithCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function shortenText(value, length) {
  const index = value.indexOf(" ", length);
  if (index === -1) return value;
  return `${value.substring(0, index)}...`;
}
