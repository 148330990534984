import React, { useState } from "react";
import { useHistory } from "react-router";
import cn from "classnames";
import { toast } from "react-toastify";
import styles from "./NewPassword.module.sass";
import TextInput from "../../../components/TextInput";
import RestService from "../../../adapter/base";

import { useTranslation } from "react-i18next";

const NewPassword = ({ email, code }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onResetPassword = async () => {
    if (password.length < 8) {
      return;
    }

    const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (!password.match(passw)) {
      return;
    }

    if (password !== confirmPassword || !password) {
      toast.error(t("Password and confirm password do not match!"));
      return;
    }

    RestService.put(`/user/password/reset`, {
      email: email,
      code: code,
      new_password: password,
    }).then((res) => {
      if (!res.error) {
        toast.success(t("Password changed successfully!"));
        history.push("/sign-in");
      } else {
        if (res.error === "INVALID_EMAIL" || res.error === "CODE_NOT_MATCH") {
          toast.error(t("Invalid user address or verification code!"), {
            postition: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
          });
        } else {
          toast.error(t("Code check failed! Please try again!"), {
            postition: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
          });
        }
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <h3 className={cn("h3", styles.title)}>{t("New password")}</h3>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label={t("new password")}
          name={"new-password"}
          type="password"
          placeholder={t("Password")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          validate={{
            length: 8,
            difficulty: true,
            messages: {
              length: t("Password must be longer than ", { length: "8" }),
              difficulty: t(
                "Password must contain at least one number and have a mixture of uppercase and lowercase letters"
              ),
            },
          }}
          required
          view
        />
        <TextInput
          className={styles.field}
          label={t("confirm password")}
          name={"confirm-password"}
          type="password"
          placeholder={t("Password")}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          view
        />
      </div>
      <button className={cn("button", styles.button)} onClick={onResetPassword}>
        {t("Continue")}
      </button>
    </form>
  );
};

export default NewPassword;
