import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Balance.module.sass";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";

import numeral from "numeral";

import CONST from "../../../common/const";
import { useBitmaidoContext } from "../../../hooks/useBitmaidoContext";

import { useTranslation } from "react-i18next";
import { useExchangeContext } from "../../../hooks/useExchangeContext";

let counterOptions = [];

const Balance = () => {
  const { t } = useTranslation();

  const [counter, setCounter] = useState(0.1);
  const [numeralFormat, setNumeralFormat] = useState("0,0.0000");
  const { orders, symbolInfo, symbolQuote, setOrderDecimal } = useBitmaidoContext();
  const { setSelectedOrder } = useExchangeContext();

  useEffect(() => {
    let numeralFormat = "0,0.";
    for (let i = 0; i < symbolInfo.precision; i++) {
      numeralFormat = numeralFormat + "0";
    }
    setNumeralFormat(numeralFormat);

    counterOptions = [];

    for (let i = 0; i < 4; i++) {
      counterOptions.push(1 / Math.pow(10, symbolInfo.precision - i));
    }

    setCounter(counterOptions[0]);
  }, [symbolInfo]);

  useEffect(() => {
    for (let i = 0; i < 4; i++) {
      if (counter === counterOptions[i]) {
        setOrderDecimal(i);
      }
    }
  }, [counter])

  const handleOrderClicked = (side, order) => {
    setSelectedOrder({
      side: side,
      order: order
    });
  }

  return (
    <div className={styles.balance}>
      <div className={styles.head}>
        <div className={styles.sorting}>
          {/* {sorting.map((x, index) => (
            <button
              className={cn(styles.direction, { [styles.active]: index < 1 })}
              key={index}
            >
              <span style={{ backgroundColor: x.color1 }}></span>
              <span style={{ backgroundColor: x.color2 }}></span>
              <span style={{ backgroundColor: x.color3 }}></span>
            </button>
          ))} */}
          {t("Open Orders")}
        </div>
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          classDropdownArrow={styles.dropdownArrow}
          classDropdownBody={styles.dropdownBody}
          classDropdownOption={styles.dropdownOption}
          value={counter}
          setValue={setCounter}
          options={counterOptions}
        />
      </div>
      <div className={styles.top}>
        <div className={styles.price}>
          {t("Price")} ({symbolInfo.foreignCurrency})
        </div>
        <div className={styles.amount}>
          {t("Amounts")} ({symbolInfo.baseCurrency})
        </div>
        <div className={styles.total}>{t("Total")}</div>
      </div>
      <div className={styles.list}>
        <div className={cn(styles.sub_list, styles.upper_list)}>
          {orders.asks
            .slice(0, 19)
            .reverse()
            .map((x, index) => {
              return (
                <div
                  className={
                    index === 0
                      ? cn(
                          styles.item,
                          styles.negative,
                          styles.first_upper_item
                        )
                      : cn(styles.item, styles.negative)
                  }
                  key={`ask-${index}`}
                  onClick={() => handleOrderClicked(CONST.TRADE_SIDE.SELL, x)}
                >
                  <div className={styles.price}>
                    {numeral(x[0]).format(numeralFormat)}
                  </div>
                  <div className={styles.amount}>
                    {numeral(x[1]).format("0,0.0000")}
                  </div>
                  <div className={styles.total}>
                    {numeral(x[2]).format("0,0.0000")}
                  </div>
                  <div
                    className={styles.line}
                    style={{
                      width: `${parseInt((x[2] * 100) / orders.askTotal)}%`,
                    }}
                  ></div>
                </div>
              );
            })}
        </div>

        {orders.direction === CONST.CHANGE_DIRECTION.PLUS && (
          <div className={cn(styles.statistics, styles.positive)} key={`rate`}>
            <div className={styles.currency}>
              {symbolQuote.price === 0? '-.--': numeral(symbolQuote.price).format(numeralFormat)}
            </div>
            <Icon name="arrow-top" size="16" />
            {/* <div className={styles.money}>
              {numeral(orders.rate).format(numeralFormat)}
            </div> */}
          </div>
        )}
        {orders.direction === CONST.CHANGE_DIRECTION.MINUS && (
          <div className={cn(styles.statistics, styles.negative)} key={`rate`}>
            <div className={styles.currency}>
              {symbolQuote.price === 0? '-.--': numeral(symbolQuote.price).format(numeralFormat)}
            </div>
            <Icon name="arrow-bottom" size="16" />
            {/* <div className={styles.money}>
              {numeral(orders.rate).format(numeralFormat)}
            </div> */}
          </div>
        )}
        <div className={styles.sub_list}>
          {orders.bids.slice(0, 19).map((x, index) => {
            return (
              <div
                className={cn(styles.item, styles.positive)}
                key={`bid-${index}`}
                onClick={() => handleOrderClicked(CONST.TRADE_SIDE.BUY, x)}
              >
                <div className={styles.price}>
                  {numeral(x[0]).format(numeralFormat)}
                </div>
                <div className={styles.amount}>
                  {numeral(x[1]).format("0,0.0000")}
                </div>
                <div className={styles.total}>
                  {numeral(x[2]).format("0,0.0000")}
                </div>
                <div
                  className={styles.line}
                  style={{
                    width: `${parseInt((x[2] * 100) / orders.bidTotal)}%`,
                  }}
                ></div>
              </div>
            );
          })}
        </div>
        {/* {items.map((x, index) =>
          x.balance ? (
            x.balance[0].status ? (
              <div
                className={cn(styles.statistics, styles.positive)}
                key={index}
              >
                <div className={styles.currency}>{x.balance[0].currency}</div>
                <Icon name="arrow-top" size="16" />
                <div className={styles.money}>{x.balance[0].price}</div>
              </div>
            ) : (
              <div
                className={cn(styles.statistics, styles.negative)}
                key={index}
              >
                <div className={styles.currency}>{x.balance[0].currency}</div>
                <Icon name="arrow-bottom" size="16" />
                <div className={styles.money}>{x.balance[0].price}</div>
              </div>
            )
          ) : (
            <div
              className={cn(
                styles.item,
                { [styles.positive]: x.positive },
                { [styles.negative]: x.negative }
              )}
              key={index}
            >
              <div className={styles.price}>{x.price}</div>
              <div className={styles.amount}>{x.amount}</div>
              <div className={styles.total}>{x.total}</div>
              <div
                className={styles.line}
                style={{ width: `${x.percent}%` }}
              ></div>
            </div>
          )
        )} */}
      </div>
    </div>
  );
};

export default Balance;
