import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Story.module.sass";
import { useTranslation } from "react-i18next";

const Story = ({ story }) => {
  const { t } = useTranslation();

  const storyRef = useRef(null);

  useEffect(() => {
    if (!storyRef.current) {
      return;
    }
    storyRef.current.innerHTML = story;
  }, [storyRef, story]);

  return (
    <div className={cn("section", styles.section)}>
      <div className={styles.center}>
        <div className={styles.content}>
          <h2 className={cn("h2", styles.title)}>{t("Story")}</h2>
          <div ref={storyRef}></div>
        </div>
      </div>
    </div>
  );
};

export default Story;
