import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import Action from "./Action";
import { useMediaQuery } from "react-responsive";
import CONST from "../../../../common/const";
import Icon from "../../../../components/Icon";
import { useBitmaidoContext } from "../../../../hooks/useBitmaidoContext";

import { useTranslation } from "react-i18next";

const Form = ({
  contentBuy,
  contentSell,
  price,
  stop,
  limit,
  visible,
  setValue,
  balance,
}) => {
  const { t } = useTranslation();

  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
  const { symbolInfo } = useBitmaidoContext();
  return (
    <div className={styles.form}>
      {isTablet ? (
        <>
          {visible ? (
            <>
              <div className={styles.head}>
                <div className={styles.title}>{t("Place order")}</div>
                <button
                  className={styles.close}
                  onClick={() => setValue(false)}
                >
                  <Icon name="close-circle" size="24" />
                </button>
              </div>
              <Action
                title={t("Buy ", { currency: symbolInfo.baseCurrency })}
                content={`${
                  balance[symbolInfo.foreignCurrency]
                    ? balance[symbolInfo.foreignCurrency].balance_available
                    : 0.0
                } ${symbolInfo.foreignCurrency}`}
                type={CONST.TRADE_TYPE.SPOT}
                side={CONST.TRADE_SIDE.BUY}
                price={price}
                stop={stop}
                limit={limit}
                classButton="button-green"
                buttonText={`Buy ${symbolInfo.baseCurrency}`}
                symbolInfo={symbolInfo}
                balance={
                  balance[symbolInfo.foreignCurrency]
                    ? balance[symbolInfo.foreignCurrency].balance_available
                    : 0.0
                }
              />
            </>
          ) : (
            <>
              <div className={styles.head}>
                <div className={styles.title}>{t("Place order")}</div>
                <button
                  className={styles.close}
                  onClick={() => setValue(false)}
                >
                  <Icon name="close-circle" size="24" />
                </button>
              </div>
              <Action
                title={t("Sell ", { currency: symbolInfo.baseCurrency })}
                content={`${
                  balance[symbolInfo.baseCurrency]
                    ? balance[symbolInfo.baseCurrency].balance_available
                    : 0.0
                } ${symbolInfo.baseCurrency}`}
                type={CONST.TRADE_TYPE.SPOT}
                side={CONST.TRADE_SIDE.SELL}
                price={price}
                stop={stop}
                limit={limit}
                classButton="button-red"
                buttonText={t("Sell ", { currency: symbolInfo.baseCurrency })}
                symbol={symbolInfo}
                balance={
                  balance[symbolInfo.baseCurrency]
                    ? balance[symbolInfo.baseCurrency].balance_available
                    : 0.0
                }
              />
            </>
          )}
        </>
      ) : (
        <div className={styles.row}>
          <div className={styles.col}>
            <Action
              title={t("Buy ", { currency: symbolInfo.baseCurrency })}
              content={`${
                balance[symbolInfo.foreignCurrency]
                  ? balance[symbolInfo.foreignCurrency].balance_available
                  : 0.0
              } ${symbolInfo.foreignCurrency}`}
              type={CONST.TRADE_TYPE.SPOT}
              side={CONST.TRADE_SIDE.BUY}
              price={price}
              stop={stop}
              limit={limit}
              classButton="button-green"
              buttonText={t("Buy ", { currency: symbolInfo.baseCurrency })}
              symbol={symbolInfo}
              balance={
                balance[symbolInfo.foreignCurrency]
                  ? balance[symbolInfo.foreignCurrency].balance_available
                  : 0.0
              }
            />
          </div>
          <div className={styles.col}>
            <Action
              type={CONST.TRADE_TYPE.SPOT}
              side={CONST.TRADE_SIDE.SELL}
              price={price}
              stop={stop}
              limit={limit}
              classButton="button-red"
              buttonText={t("Sell ", { currency: symbolInfo.baseCurrency })}
              symbolInfo={symbolInfo}
              balance={
                balance[symbolInfo.baseCurrency]
                  ? balance[symbolInfo.baseCurrency].balance_available
                  : 0.0
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;
