import React, { useState } from "react";
import cn from "classnames";
import styles from "./AccountBalances.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import Transfer from "../../../components/Transfer";
import { numberWithCommas } from "../../../common/helper";
import { useBitmaidoContext } from "../../../hooks/useBitmaidoContext";

import { useTranslation } from "react-i18next";

const AccountBalances = ({ spot, margin, rate }) => {
  const { t } = useTranslation();

  const [visibleTransfer, setVisibleTransfer] = useState(false);
  const { precisions } = useBitmaidoContext();

  const items = [
    {
      title: t("Margin"),
      color: "#9757D7",
      currency: `$${numberWithCommas(margin.toFixed(precisions["USD"]))}`,
      price: `${
        rate !== 0.0
          ? (margin / rate).toFixed(2)
          : (0.0).toFixed(precisions["BTC"])
      } BTC`,
    },
    {
      title: t("Spot"),
      color: "#FFD166",
      currency: `$${numberWithCommas(spot.toFixed(precisions["USD"]))}`,
      price: `${
        rate !== 0.0
          ? numberWithCommas((spot / rate).toFixed(precisions["BTC"]))
          : (0.0).toFixed(precisions["BTC"])
      } BTC`,
    },
  ];

  return (
    <>
      <div className={styles.list}>
        {items.map((x, index) => (
          <div
            className={cn(styles.item, { [styles.soon]: x.soon })}
            key={index}
          >
            <div className={styles.head}>
              <div className={styles.title}>
                <div
                  className={styles.bg}
                  style={{ backgroundColor: x.color }}
                ></div>
                {x.title}
              </div>
              <div className={styles.details}>
                <div className={styles.currency}>{x.currency}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)}
      >
        <Transfer />
      </Modal>
    </>
  );
};

export default AccountBalances;
