import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const Successfully = ({ amount, currency }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Yay!{" "}
          <span role="img" aria-label="firework">
            🎉
          </span>
        </div>
        <div className={styles.info}>
          {t("Your buy request from A41 is successfully accepted", {
            amount: amount,
            currency: currency,
          })}
        </div>
        <button
          className={cn("button", styles.button)}
          onClick={() => history.push("wallet-overview")}
          style={{
            marginTop: 20,
          }}
        >
          {t("Check your Wallet")}
        </button>
      </div>
    </>
  );
};

export default Successfully;
