import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import styles from "./UserDetail.module.sass";
import { useTranslation } from "react-i18next";
import Cards from "./Cards";
import RestService from "../../../adapter/base";
import { isLoggedIn } from "../../../common/helper";

const UserDetail = ({ handleShowRecommend }) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const userId = useMemo(() => queryParams.get("user_id"), [queryParams]);
  const targetAmount = useMemo(() => queryParams.get("target"));
  const currency = useMemo(() => queryParams.get("currency"));
  const [user, setUser] = useState({});

  useEffect(() => {
    RestService.get("/user/public", {
      user_id: userId,
      currency,
    }).then((res) => {
      setUser(res);
    });
  }, [userId]);

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head_container}>
          <h1 className={cn("h1", styles.title)}>{user.fullname}</h1>
          <div className={styles.button_container}>
            {isLoggedIn() && (
              <button
                className={cn("button", styles.button)}
                onClick={() => {
                  handleShowRecommend(true);
                  const section = document.querySelector("#user_header");
                  section.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
              >
                {t("Recommend")}
              </button>
            )}
            <img src="/images/content/verified.png" height="30px" />
            {user.kyc_status === 1 ? t("Verified") : t("Not Verified")}
          </div>
        </div>
        <div className={styles.container_sm_fluid}>
          <div className={styles.user_photo}>
            <img
              src={
                user.avatar
                  ? `${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${user.avatar}`
                  : "/images/content/avatar-default.svg"
              }
              onError={(e) => (e.target.src = "")}
              alt=""
            />
          </div>
          <div className={styles.detail_container}>
            <h1>
              {user.sale_amount ? user.sale_amount.toLocaleString() : "-"}{" "}
              {user.currency}
            </h1>
            <p>
              pledged of{" "}
              {targetAmount ? parseInt(targetAmount).toLocaleString() : "-"}{" "}
              {user.currency} goal
            </p>
            {/* <h1 style={{ marginTop: "2rem" }}>7,432</h1>
            <p>{t("backers")}</p>
            <h1 style={{ marginTop: "2rem" }}>10</h1>
            <p>{t("days to go")}</p>*/}
            {user.ico_status !== 0 && (
              <div style={{ display: "grid", marginTop: "2rem" }}>
                <a
                  className={cn("button-small", styles.button)}
                  href={
                    new Date() >= new Date(user.lock_time)
                      ? `/exchange/spot?symbol=${currency}USDT`
                      : `/buy-crypto?currency=${currency}`
                  }
                >
                  {t("Back this project")}
                </a>
              </div>
            )}
          </div>
        </div>
        <h1 className={cn("h1", styles.sns_title)}>
          {t("someone_sns", { name: user.fullname ?? "Unknown" })}
        </h1>
        <Cards snsList={user.snsList ?? []} token={user.currency} />
      </div>
    </div>
  );
};

export default UserDetail;
