import React from "react";
import cn from "classnames";
import styles from "./Steps.module.sass";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const Steps = ({ scrollToRef }) => {
  const { t } = useTranslation();

  const items = [
    {
      title: t("Start A Fundraiser"),
      image2x: "/images/content/step-1@2x.png",
      image: "/images/content/step-1.png",
      content: t(
        "Sign up on A41 and create your fundraiser in minutes. You can register as an individual."
      ),
    },
    {
      title: t("Share Your Fundraiser"),
      image2x: "/images/content/step-2@2x.png",
      image: "/images/content/step-2.png",
      content: t(
        "Share your fundraiser via Email, Facebook, WhatsApp and other social media channels to reach as many donors as possible."
      ),
    },
    {
      title: t("Receive The Investment"),
      image2x: "/images/content/step-3@2x.png",
      image: "/images/content/step-3.png",
      content: t(
        "The investments are paid out weekly, monthly or directly on demand to your bank account."
      ),
    },
    {
      title: t("Thank Your Investors"),
      image2x: "/images/content/step-4@2x.png",
      image: "/images/content/step-4.png",
      content: t(
        "Customise the automated “Thank You” email or send a personal message to your donors via the A41 dashboard."
      ),
    },
  ];

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <div className={cn("section", styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>
            {t("How To Start A Fundraiser")}
          </h2>
        </div>
        <div className={styles.steps}>
          <img
            src={
              isMobile
                ? "/images/content/step-m.svg"
                : "/images/content/step.svg"
            }
            alt=""
          />
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <h3 className={cn("h3", styles.subtitle)}>{x.title}</h3>
                <div className={styles.content}>{x.content}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
