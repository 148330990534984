import React, { useState } from "react";
import cn from "classnames";
import styles from "./ForgotPassword.module.sass";
import Login from "../../components/Login";
import Form from "./Form";
import Verification from "./Verification";
import NewPassword from "./NewPassword";

import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const { t } = useTranslation();

  const [acitveIndex, setAcitveIndex] = useState(0);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');

  const onGoVerification = (userEmail) => {
    setEmail(userEmail);
    setAcitveIndex(1);
  }

  const onGoNewPassword = (userCode) =>{
    setCode(userCode)
    setAcitveIndex(2);
  }

  const onResendCode = () => {
    setAcitveIndex(0);
  }

  return (
    <Login
      content={t("Don’t have an account?")}
      linkText={t("Sign up for free")}
      linkUrl="/sign-up"
    >
      {acitveIndex === 0 && <Form goNext={onGoVerification}/>}
      {acitveIndex === 1 && <Verification goNext={onGoNewPassword} onResend={onResendCode} email={email}/>}
      {acitveIndex === 2 && <NewPassword email={email} code={code}/>}
    </Login>
  );
};

export default ForgotPassword;
