import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./AssetBalances.module.sass";
import { numberWithCommas } from "../../../common/helper";
import { useBitmaidoContext } from "../../../hooks/useBitmaidoContext";

import { useTranslation } from "react-i18next";

const coinIcons = {
  BTC: {
    id: 0,
    currency: "BTC",
    content: "Bitcoin",
    icon: "/images/content/currency/bitcoin.svg",
  },
  BCH: {
    id: 0,
    currency: "BCH",
    content: "Bitcoin Cash",
    icon: "/images/content/currency/bitcoin-cash.svg",
  },
  ETH: {
    id: 1,
    currency: "ETH",
    content: "Ethereum",
    icon: "/images/content/currency/ethereum.svg",
  },
  USDT: {
    id: 2,
    currency: "USDT",
    content: "Tether USD",
    icon: "/images/content/currency/tether.svg",
  },
  BNB: {
    id: 3,
    currency: "BNB",
    content: "BNB",
    icon: "/images/content/currency/bnb.svg",
    earn: "7.46% APR",
  },
  XRP: {
    id: 4,
    currency: "XRP",
    content: "Ripple",
    icon: "/images/content/currency/ripple.svg",
    earn: "7.46% APR",
  },
  TRX: {
    id: 5,
    currency: "TRX",
    content: "TRON",
    icon: "/images/content/currency/tron.svg",
  },
  LINK: {
    id: 6,
    currency: "LINK",
    content: "LINK",
    icon: "/images/content/currency/chainlink.svg",
  },
  LTC: {
    id: 0,
    currency: "LTC",
    content: "Litecoin",
    icon: "/images/content/currency/litecoin.svg",
  },
  EOS: {
    id: 7,
    currency: "EOS",
    content: "EOS.IO",
    icon: "/images/content/currency/eos.svg",
  },
  ADA: {
    id: 8,
    currency: "ADA",
    content: "Cardano",
    icon: "/images/content/currency/cardano.svg",
  },
  ONT: {
    id: 9,
    currency: "ONT",
    content: "Ontology",
    icon: "/images/content/currency/ontology.svg",
  },
  USDC: {
    id: 10,
    currency: "USDC",
    content: "USD Coin",
    icon: "/images/content/currency/usd-coin.svg",
  },
};

const AssetBalances = ({ accounts, currencies }) => {
  const { precisions } = useBitmaidoContext();
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>{t("Asset")}</div>
          <div className={styles.col}>{t("On orders")}</div>
          <div className={styles.col}>{t("Available balance")}</div>
          <div className={styles.col}>{t("Total balance")}</div>
        </div>
        {accounts.map((x, index) => {
          const currency = currencies.find((t) => t.currency === x.currency);
          return (
            <Link className={styles.row} key={index}>
              <div className={styles.col}>
                <div className={styles.currency}>
                  <div className={styles.icon}>
                    {(coinIcons[x.currency] || currency?.promotion_url) && (
                      <img
                        src={
                          coinIcons[x.currency]
                            ? coinIcons[x.currency].icon
                            : `${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${currency.promotion_url}`
                        }
                        alt={t("Currency")}
                      />
                    )}
                  </div>
                  <div className={styles.details}>
                    <div className={styles.info}>{x.currency}</div>
                  </div>
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.info}>
                  {numberWithCommas(
                    parseFloat(x.balance_order).toFixed(precisions[x.currency])
                  )}
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.info}>
                  {numberWithCommas(
                    (
                      parseFloat(x.balance_total) - parseFloat(x.balance_order)
                    ).toFixed(precisions[x.currency])
                  )}
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.info}>
                  {numberWithCommas(
                    parseFloat(x.balance_total).toFixed(precisions[x.currency])
                  )}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default AssetBalances;
