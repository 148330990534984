import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import styles from "./Trend.module.sass";
import { useSimpleBitmaidoContext } from "../../../hooks/useSimpleBitmaidoContext";
import CONST from "../../../common/const";
import { useTranslation } from "react-i18next";
import TrendItemMb from "./MobileItem";
import TrendItem from "./Item";

const Learn = ({ trendTab }) => {
  const { t } = useTranslation();
  const { items, currencies } = useSimpleBitmaidoContext();
  const [activeSns, setActiveSns] = useState(-1);

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.line}>
          {/* <h2 className={cn("h2", styles.title)}>{t("Market trend")}</h2> */}
          {/* <Link className={cn("button-stroke", styles.button)} to="/market">
            {t("View more")}
          </Link> */}
        </div>
        {/* <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div> */}
        {/* <div className={styles.dropdown}>
          <Dropdown
            className={styles.dropdown}
            value={sorting}
            setValue={setSorting}
            options={navigation}
          />
        </div> */}
        <div className={styles.overflow}>
          <div className={styles.table} onMouseLeave={() => setActiveSns(-1)}>
            <div className={styles.row}>
              <div className={styles.col}>#</div>
              <div className={styles.col}>{t("Name")}</div>
              <div className={styles.col}>{t("Price")}</div>
              <div className={styles.col}>{t("24h %")}</div>
              <div className={styles.col}>{t("7d %")}</div>
              <div className={styles.col}>{t("Marketcap")}</div>
              <div className={styles.col}>{t("Volume")}</div>
              <div className={styles.col}>{t("Trade")}</div>
            </div>
            {(trendTab === CONST.TREND_TAB.CRYPTO_CURRENCY
              ? items
              : trendTab === CONST.TREND_TAB.USER_TOKEN
              ? currencies.filter(
                  (x) => x.icoStatus === 1 && new Date(x.lockTime) < new Date()
                )
              : []
            ).map((x, index) => (
              <TrendItem
                x={x}
                trendTab={trendTab}
                key={`trendb_${index}`}
                index={index}
                activeSns={activeSns === index}
                setActivate={setActiveSns}
              />
            ))}
          </div>
        </div>
        <div className={styles.table_mobile}>
          <span style={{ gridRow: 1, gridColumn: 1, textAlign: "left" }}>
            {t("Currency")}
          </span>
          <span style={{ gridRow: 1, gridColumn: 2 }}>{t("Price")}</span>
          <span style={{ gridRow: 1, gridColumn: 3 }}>{t("24h %")}</span>
          <span style={{ gridRow: 1, gridColumn: 4 }}></span>
          {(trendTab === CONST.TREND_TAB.CRYPTO_CURRENCY
            ? items
            : trendTab === CONST.TREND_TAB.USER_TOKEN
            ? currencies.filter(
                (x) => x.icoStatus === 1 && new Date(x.lockTime) < new Date()
              )
            : []
          ).map((x, index) => (
            <TrendItemMb x={x} trendTab={trendTab} key={`trend_${index}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Learn;
