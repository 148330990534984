import React from 'react';
import { useEffect, useMemo, useReducer, useState } from "react";
import cn from "classnames";
import styles from "./Sns.module.sass";
import { useTranslation } from "react-i18next";
import { shortenText } from "../../../common/helper";
import axios from "axios";

const Instagram = ({ snsId }) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);

  const [instagramInfo, setinstagramInfo] = useState({});
  const [instagramList, setinstagramList] = useState([]);
  const [pageToken, setPageToken] = useState("trial");
  const [loadMore, setLoadMore] = useState(true);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_META_PROXY, {
        params: {
          url: btoa(
            `https://www.instagram.com/api/v1/feed/user/${snsId}/username/?count=12`
          ),
        },
      })
      .then((res) => {
        setinstagramList(res.data.items);
        if (!res.data.more_available) setPageToken("");
      });

    axios
      .get(process.env.REACT_APP_META_PROXY, {
        params: {
          url: btoa(
            `https://i.instagram.com/api/v1/users/web_profile_info/?username=${snsId}`
          ),
        },
      })
      .then((res) => {
        setinstagramInfo(res.data.data.user);
      });
  }, [snsId]);

  useEffect(() => {
    console.log(instagramList);
  }, [instagramList]);

  return (
    <>
      <div className={styles.container_sm_fluid}>
        <div className={styles.user_photo}>
          <img
            src={`${process.env.REACT_APP_META_PROXY}img?url=${btoa(
              instagramInfo?.profile_pic_url
            )}`}
            onError={(e) =>
              (e.target.src = "/images/content/avatar-default.svg")
            }
            alt=""
          />
        </div>
        <div className={styles.detail_container}>
          <h1>{instagramInfo?.full_name}</h1>
          <p>
            {instagramInfo?.snippet?.description
              ? shortenText(instagramInfo?.snippet?.description, 400)
              : ""}
          </p>
        </div>
      </div>
      <div
        className={cn(styles.container, styles.videoList)}
        style={{
          marginTop: "70px",
        }}
      >
        {instagramList.map((item, index) => (
          <a href={`https://instagram.com/p/${item.code}`} target="_blank">
            <img
              src={`${process.env.REACT_APP_META_PROXY}img?url=${btoa(
                item.image_versions2.candidates[0].url
              )}`}
            />
            <span>{item?.caption?.text ?? ""}</span>
          </a>
        ))}
      </div>
      {pageToken && (
        <span className={styles.loadMore} onClick={() => setLoadMore(true)}>
          {t("Load More")}...
        </span>
      )}
    </>
  );
};

export default Instagram;
