import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import Theme from "../../Theme";

import { useTranslation } from "react-i18next";
import { useUserContext } from "../../../hooks/useUserContext";

const User = ({ className }) => {
  const { user } = useUserContext();
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const onClickDefault = () => {
    setVisible(!visible);
  };

  const onClickLogout = () => {
    window.localStorage.removeItem("token");
    window.location = "/";
    setVisible(!visible);
  };

  const items = [
    {
      title: t("Profile"),
      icon: "user",
      content: t("Important account details"),
      url: "/profile-info",
    },
    {
      title: t("Referrals"),
      icon: "share",
      content: t("Invite your friends and earn rewards"),
      url: "/referrals",
    },
    {
      title: t("KYC"),
      icon: "cloud",
      content: t("Validate your personal identity"),
      url: "/kyc",
    },
    {
      title: t("Dark mode"),
      icon: "theme-dark",
      content: t("Switch dark/light mode"),
    },
    {
      title: t("Log out"),
      icon: "exit",
      url: "/",
      onClick: onClickLogout,
    },
  ];

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(className, styles.user, { [styles.active]: visible })}>
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img
            src={
              user?.avatar
                ? `${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${user?.avatar}`
                : "/images/content/avatar-default.svg"
            }
            onError={(e) =>
              (e.target.src = "/images/content/avatar-default.svg")
            }
            alt="Avatar"
          />
        </button>
        <div className={styles.body}>
          <div className={styles.menu}>
            {items.map((x, index) =>
              x.url ? (
                <Link
                  className={styles.item}
                  to={x.url}
                  onClick={x.onClick ? x.onClick : onClickDefault}
                  key={index}
                >
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="20" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.title}>{x.title}</div>
                    <div className={styles.content}>{x.content}</div>
                  </div>
                </Link>
              ) : (
                <div className={styles.item} key={index}>
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="20" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.line}>
                      <div className={styles.title}>{x.title}</div>
                      <Theme className={styles.theme} small />
                    </div>
                    <div className={styles.content}>{x.content}</div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
