import axios from 'axios';

process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0';

export async function getPublic(url, params) {
  return await axios.get(`${process.env.REACT_APP_OKX_END_POINT}${url}`, {
    query: params,
  });
}

export async function getMarket(symbol) {
  return await getPublic(`api/v5/market/ticker?instId=${symbol}`);
}