import React, { useEffect, useState } from "react";
import crypto from "crypto";
import GoogleLogin from "react-google-login";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import Login from "../../components/Login";
import Icon from "../../components/Icon";
import Form from "./Form";
import Scan from "./Scan";
import Mfa from "./Mfa";
import RestService from "../../adapter/base";
import { isLoggedIn } from "../../common/helper";
import { useBitmaidoContext } from "../../hooks/useBitmaidoContext";

import { useTranslation } from "react-i18next";

const SignIn = () => {
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);
  const [user, setUser] = useState({});
  const [qrcode, setQRCode] = useState({});
  const { setUserLoggedin } = useBitmaidoContext();

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("validate");
  const email = new URLSearchParams(search).get("user");

  const onValidate = (userInfo) => {
    setUser(userInfo);
    setIndex(1);
  };

  const onGoogleAuthenticate = (userInfo) => {
    setUser(userInfo);
    setIndex(2);

    /* RestService.get("/user/mfa/qrcode", {email: userInfo.email})
    .then((res) => {
      setQRCode(res);
    }) */
  };

  useEffect(() => {
    if (token && email) {
      RestService.put(`/user/enable`, {
        email: new Buffer(email, "base64").toString("ascii"),
        token: token,
      }).then((res) => {
        if (!res.error) {
          setUserLoggedin(true);
          window.localStorage.setItem("token", res.jwt_token);
          window.localStorage.setItem("user_id", res.id);
          window.location = "/exchange/spot";
        } else {
          toast.error(t("Validation Code does not match!"));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn()) {
      window.location = "/exchange/spot";
    }
  }, []);

  return (
    <Login
      content={t("Don’t have an account?")}
      linkText={t("Sign up for free")}
      linkUrl="/sign-up"
    >
      <div className={styles.login}>
        <div className={styles.top}>
          <h3 className={cn("h3", styles.title)}>
            {t("Sign in to A41 Exchange")}
          </h3>
          <div className={styles.info}>
            {t("Please ensure you are visiting the correct url.")}
          </div>

          <div className={styles.correct}>
            <Icon name="lock" size="24" />
            <div className={styles.url}>
              https://<span>all41.world/sign-in</span>
            </div>
          </div>
        </div>
        {index === 1 ? (
          <>
            <Scan user={user} />

            <div
              className={styles.info}
              style={{
                marginTop: 10,
              }}
            >
              {t(
                "If the validation not working, please try on updated web browsers."
              )}
            </div>
          </>
        ) : index === 2 ? (
          <Mfa goNext={() => {}} user={user} qrcode={qrcode} />
        ) : (
          <Form onValidate={onValidate} onGA={onGoogleAuthenticate} />
        )}
      </div>
    </Login>
  );
};

export default SignIn;
