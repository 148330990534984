import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwt from "jsonwebtoken";
import cn from "classnames";
import styles from "./Referrals.module.sass";
import Profile from "../../components/Profile";
import TextInput from "../../components/TextInput";

import { useTranslation } from "react-i18next";

const Referrals = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    {
      title: t("Home"),
      url: "/",
    },
    {
      title: t("Referrals"),
    },
  ];

  const [referralURL, setReferralURL] = useState("");
  const [referralCode, setReferralCode] = useState("");

  useEffect(() => {
    try {
      const token = window.localStorage.getItem("token");
      const userInfo = jwt.verify(token, process.env.REACT_APP_SALT);

      setReferralCode(new Buffer(userInfo.id.toString()).toString("base64"));
    } catch {
      setReferralCode("");
    }
  }, []);

  useEffect(() => {
    setReferralURL(`https://a51.exchange/sign-up?referral=${referralCode}`);
  }, [referralCode]);

  return (
    <Profile title="Referrals" breadcrumbs={breadcrumbs}>
      {/* <div className={styles.stage}>Total rewards</div>
      <div className={cn("h3", styles.price)}>
        1,056.00 <span className={styles.currency}>USDT</span>
      </div>
      <div className={styles.info}>
        You're earning 20% of the trading fees your referrals pay. Learn more
  </div> */}
      <div className={styles.stage}>
        <div className={styles.stageLabel}>{t("Total rewards")}</div>
        <div className={styles.stageBalance}>
          <div className={styles.value}>1,056.00&nbsp;</div>
          <div className={styles.asset}>USDT</div>
        </div>
        <div className={styles.description}>
          {t(
            "You're earning 20% of the trading fees your referrals pay. Learn more"
          )}
        </div>
      </div>
      <div className={styles.wrap}>
        <div className={styles.title}>{t("Invite friends to earn 20%")}</div>
        <div className={styles.row}>
          <div className={styles.col}>
            <TextInput
              className={styles.field}
              classLabel={styles.label}
              classInput={styles.input}
              label={t("Referral link")}
              name="referral-link"
              type="text"
              value={referralURL}
              required
            />
          </div>
          <div className={styles.col}>
            <TextInput
              className={styles.field}
              classLabel={styles.label}
              classInput={styles.input}
              label={t("Referral code")}
              name="referral-code"
              type="text"
              value={referralCode}
              required
            />
          </div>
        </div>
      </div>
      <Link
        className={cn("button-stroke", styles.button)}
        to="/wallet-overview"
      >
        {t("My wallet")}
      </Link>
    </Profile>
  );
};

export default Referrals;
