import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwt from "jsonwebtoken";
import cn from "classnames";
import styles from "./Actions.module.sass";
import Icon from "../../../components/Icon";
import Form from "./Form";
import RestService from "../../../adapter/base";
import { useBitmaidoContext } from "../../../hooks/useBitmaidoContext";

import { useTranslation } from 'react-i18next';
import { useExchangeContext } from "../../../hooks/useExchangeContext";

const Actions = (props) => {
  const { t } = useTranslation();

  const navigation = [t("Limit"), t("Stop-limit"), t("Market")];

  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleAction, setVisibleAction] = useState(false);
  const [visible, setVisible] = useState(false);
  const [accountBalance, setAccountBalance] = useState({});
  const [user, setUser] = useState({});
  const { accounts } = useBitmaidoContext();
  const { selectedOrder } = useExchangeContext();

  const handleClickBuy = () => {
    setVisibleAction(true);
    setVisible(true);
  };

  const handleClickSell = () => {
    setVisibleAction(false);
    setVisible(true);
  };

  useEffect(() => {
    try {
      const token = window.localStorage.getItem('token');
      const userInfo = jwt.verify(token, process.env.REACT_APP_SALT);
      setUser(userInfo);
    } catch {
      setUser({});
    }  
  }, []);



  const getUserBalance = () => {
    const balance = {};
    for(let i in accounts) {
        balance[accounts[i].currency] = accounts[i];
        balance[accounts[i].currency].balance_total = parseFloat(balance[accounts[i].currency].balance_total);
        balance[accounts[i].currency].balance_order = parseFloat(balance[accounts[i].currency].balance_order);
        balance[accounts[i].currency].balance_available = balance[accounts[i].currency].balance_total - balance[accounts[i].currency].balance_order;
    }
    setAccountBalance(balance);
  }

  useEffect(() => {
    getUserBalance();
  }, [accounts])

  useEffect(() => {
    if (user.id === undefined) return;
    getUserBalance();
  }, [user])

  useEffect(() => {
    if (selectedOrder === undefined) return;

    setActiveIndex(0);
  }, [selectedOrder])

  if (!props.user?.id) {
    return "";
  }

  return (
    <div className={styles.actions}>
      <div className={styles.head}>
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        {/* <div className={styles.info}>
          Crypto trading tutorial
          <Link to="/learn-crypto">
            Learn now <Icon name="arrow-right" size="20" />
          </Link>
        </div> */}
      </div>
      <div className={cn(styles.wrapper, { [styles.show]: visible })}>
        {activeIndex === 0 && (
          <Form
            price
            visible={visibleAction}
            setValue={setVisible}
            balance={accountBalance}
          />
        )}
        {activeIndex === 1 && (
          <Form
            stop
            limit
            visible={visibleAction}
            setValue={setVisible}
            balance={accountBalance}
          />
        )}
        {activeIndex === 2 && (
          <Form
            visible={visibleAction}
            setValue={setVisible}
            balance={accountBalance}
          />
        )}
      </div>
      <div className={styles.btns}>
        <button
          className={cn("button-green button-small", styles.button)}
          onClick={() => handleClickBuy()}
        >
          Buy
        </button>
        <button
          className={cn("button-red button-small", styles.button)}
          onClick={() => handleClickSell()}
        >
          Sell
        </button>
      </div>
    </div>
  );
};

export default Actions;
