import React from 'react';
import cn from "classnames";
import styles from "./Trend.module.sass";
import CONST from "../../../common/const";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
import RestService from "../../../adapter/base";
import axios from "axios";
import numeral from "numeral";

const TrendItem = ({ x, trendTab }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [snsList, setSnsList] = useState([]);

  // let numeralFormat = "0,0.00";
  // for (let i = 0; i < x.precision; i++) {
  //   numeralFormat += "0";
  // }
  let numeralFormat = "0,0.";
  for (let i = 0; i < x.precision; i++) {
    numeralFormat += "0";
  }

  const addLink = async () => {
    // const tmpList = [...x.sns];
    try {
      const tmpList = [...x.sns];
      for (let i = 0; i < tmpList.length; i++) {
        if (tmpList[i].category === "twitter") {
          const result = await RestService.get("/sns/twitter", {
            sns_name: tmpList[i].sns_name,
          });
          tmpList[i].href = `https://x.com/${result?.data?.username}`;
        } else if (tmpList[i].category === "youtube") {
          tmpList[i].href = `https://youtube.com/channel/${tmpList[i].sns_name}`;
        } else if (tmpList[i].category === "instagram") {
          const result = await axios.get(
            `https://graph.instagram.com/${tmpList[i].sns_name}`,
            {
              params: {
                access_token: process.env.REACT_APP_INSTAGRAM_API_TOKEN,
                fields: "id,username",
              },
            }
          );
          tmpList[i].href = `https://instagram.com/${result?.data?.username}`;
        } else if (tmpList[i].category === "facebook") {
          tmpList[i].href = `https://facebook.com/${tmpList[i].sns_name}`;
        } else tmpList[i].href = "";
      }
      // console.log(tmpList);
      setSnsList(tmpList);
    } catch (e) {
      ;
    }
  };

  useEffect(() => {
    addLink();
  }, [x]);

  return (
    <>
      <div style={{ textAlign: "left" }}>
        <div className={styles.market_item}>
          <div className={styles.market_icon}>
            <img
              src={
                trendTab === CONST.TREND_TAB.USER_TOKEN
                  ? x.image
                    ? `${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${x.image}`
                    : "/images/content/avatar-default.svg"
                  : x.image
              }
              style={{ width: "100%" }}
            />
          </div>
          <div className={styles.market_details}>
            {x.currency}
            { trendTab === CONST.TREND_TAB.USER_TOKEN
                ? <div className={styles.sns_list_mb}>
                    {snsList.length > 0
                      ? snsList.map((item, ind) => (
                          <a href={item.href} key={`mysns_${ind}`} target="_blank">
                            <img
                              src={`/images/content/${item.category}.${
                                item.category === "twitter" ? "png" : "svg"
                              }`}
                              alt=""
                            />
                          </a>
                        ))
                      : t("No Sns Found")}
                  </div>
                : []
            }
          </div>
        </div>
      </div>
      <div className={styles.market_price}>
        {/* $ {parseFloat(x.price) === 0 ? "-.--" : x.price.toLocaleString()} */}
        $ {parseFloat(x.price) === 0
          ? "-.--"
          : numeral(parseFloat(x.price)).format(numeralFormat)}
      </div>
      <div>
        {x.changePct >= 0 && (
          <div className={styles.market_positive}>
            {parseFloat(x.changePct) === 0
              ? "-.--"
              : parseFloat(x.changePct).toFixed(2)}
            %
          </div>
        )}
        {x.changePct < 0 && (
          <div className={styles.market_negative}>
            {parseFloat(x.changePct).toFixed(2)}%
          </div>
        )}
      </div>

      <button
        className={cn("button-stroke button-small", styles.button)}
        onClick={() => history.push(`/exchange/spot?symbol=${x.currency}USDT`)}
      >
        {t("Buy")}
      </button>
    </>
  );
};

export default TrendItem;
