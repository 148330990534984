import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./WalletMargin.module.sass";
import { Link } from "react-router-dom";
import Wallet from "../../components/Wallet";
import Main from "./Main";
import Funds from "../../components/Funds";
import CONST from "../../common/const";
import RestService from "../../adapter/base";
import { useBitmaidoContext } from "../../hooks/useBitmaidoContext";

import { useTranslation } from 'react-i18next';

const WalletMargin = () => {
  const { t } = useTranslation();

  const [totalMarginBalance, setTotalMarginBalance] = useState(0.0);
  const [BTCUSDRate, setBTCUSDRate] = useState(0.0);
  const { symbols, accounts } = useBitmaidoContext();

  useEffect(() => {
    let total = 0.0;
    for(let i in symbols) {
      if (symbols[i].symbol === "BTCUSDT") {
        setBTCUSDRate(symbols[i].quote.price);
      }
      if (symbols[i].foreign_currency !== "USDT")
        continue;
      for(let j in accounts) {
        if (accounts[j].currency === "USDT") {
          if (accounts[j].type == CONST.TRADE_TYPE.MARGIN) {
            total += parseFloat(accounts[j].balance_total);
          }
          break;
        }

        if (symbols[i].base_currency === accounts[j].currency) {
          if (accounts[j].type == CONST.TRADE_TYPE.MARGIN) {
            total += parseFloat(accounts[j].balance_total) * symbols[i].quote.price;
          }
          break;
        }
      }
    }
    setTotalMarginBalance(total);
  }, [accounts, symbols]);

  return (
    <>
      <Wallet>
        <Main totalMarginBalance={totalMarginBalance} BTCUSDRate={BTCUSDRate}/>
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.head}>{t("Funds")}</div>
            <div className={styles.body}>
              <Funds accounts={accounts} type={CONST.TRADE_TYPE.MARGIN}/>
            </div>
          </div>
        </div>
      </Wallet>
    </>
  );
};

export default WalletMargin;
