import React, { useState } from "react";
import cn from "classnames";
import styles from "./SendRecommend.module.sass";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../components/Dropdown";
import { RECOMMEND_RELATION } from "../../../common/const";
import TextArea from "../../../components/TextArea";
import RestService from "../../../adapter/base";
import { toast } from "react-toastify";

const SendRecommend = ({ currency, handleShowRecommend }) => {
  const { t } = useTranslation();

  const [relation, setRelation] = useState(RECOMMEND_RELATION.FAMILY);
  const [message, setMessage] = useState("");

  const getRelationString = () => {
    switch (relation) {
      case RECOMMEND_RELATION.FAMILY:
        return t("Family");
      case RECOMMEND_RELATION.FRIENDS:
        return t("Friends");
      case RECOMMEND_RELATION.COWORKER:
        return t("Coworker");
      case RECOMMEND_RELATION.ADVISER:
        return t("Adviser");
      case RECOMMEND_RELATION.SUPPORTER:
      default:
        return t("Suppoter");
    }
  };

  const handleRelationChanged = (value) => {
    switch (value) {
      case t("Family"):
        setRelation(RECOMMEND_RELATION.FAMILY);
        break;
      case t("Friends"):
        setRelation(RECOMMEND_RELATION.FRIENDS);
        break;
      case t("Coworker"):
        setRelation(RECOMMEND_RELATION.COWORKER);
        break;
      case t("Adviser"):
        setRelation(RECOMMEND_RELATION.ADVISER);
        break;
      case t("Supporter"):
      default:
        setRelation(RECOMMEND_RELATION.SUPPORTER);
        break;
    }
  };

  const handleSubmitRecommend = () => {
    RestService.post("/recommend/new", {
      relation: relation,
      message: message,
      opponent_id: currency,
    }).then((res) => {
      if (res.error) {
        toast.error(t("Sending message is failed."));
      } else {
        toast.success(t("Message is sent successfully."));
        handleShowRecommend(false);
      }
    });
  };

  return (
    <div
      className={cn("section", styles.section)}
      style={{ marginTop: "100px" }}
    >
      <div className={styles.center}>
        <div className={styles.content}>
          <h2 className={cn("h4", styles.title)}>{t("Send Recommend")}</h2>
          <div className={styles.field}>
            <div className={styles.field_label}>
              {t("What is your relationship?")}
            </div>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              classDropdownArrow={styles.dropdownArrow}
              classDropdownBody={styles.dropdownBody}
              classDropdownOption={styles.dropdownOption}
              value={getRelationString()}
              setValue={(value) => handleRelationChanged(value)}
              options={[
                t("Family"),
                t("Friends"),
                t("Coworker"),
                t("Adviser"),
                t("Supporter"),
              ]}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.field_label}>{t("Message")}</div>
            <TextArea
              style={{
                height: 240,
              }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div style={{ whiteSpace: "pre-line", marginBottom: "40px" }}>
            {t("Comment_Description")}
          </div>
          <div className={styles.comment_btns}>
            <button
              className={cn("button", styles.button)}
              onClick={() => handleSubmitRecommend()}
            >
              {t("Send Message")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendRecommend;
