import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwt from "jsonwebtoken";
import { toast } from "react-toastify";
import cn from "classnames";
import styles from "./KYC.module.sass";
import Profile from "../../components/Profile";
import Dropdown from "../../components/Dropdown";
import FileInput from "../../components/FileInput";
import RestService from "../../adapter/base";
import CONST from "../../common/const";

import { useTranslation } from "react-i18next";
import KycTable from "./Table";

const KYC = () => {
  const [user, setUser] = useState({});
  const [kycList, setKycList] = useState([]);
  const { t } = useTranslation();

  const breadcrumbs = [
    {
      title: t("Home"),
      url: "/",
    },
    {
      title: t("KYC"),
    },
  ];

  const [file, setFile] = useState({});
  const [backFile, setBackFile] = useState({});
  const [selfFile, setSelfFile] = useState({});
  const [type, setType] = useState("");
  const [status, setStatus] = useState("unknown");
  const [visibleUpload, setVisibleUpload] = useState(false);

  const getVerifiedStatus = () => {
    if (user.kyc_status === 0) {
      setStatus("KYC not verified");
    } else if (user.kyc_status === 1) {
      setStatus("KYC Verified");
    } else if (user.kyc_status === 2) {
      setStatus("KYC pending");
    } else {
      setStatus("KYC rejected");
    }
  };

  const onRefreshUser = () => {
    try {
      RestService.get("/user").then((res) => {
        if (!res.error) {
          setUser(res);
        }
      });
    } catch {
      setUser({});
    }
  };

  const getDocumentInfo = async () => {
    RestService.get("/kyc/documents").then((res) => {
      setKycList(res.rows);
    });
  };

  useEffect(() => {
    onRefreshUser();
  }, []);

  useEffect(() => {
    getVerifiedStatus();
    if (user.kyc_status >= 1 || !visibleUpload) {
      getDocumentInfo();
    }
  }, [user, visibleUpload]);

  const onUploadDocument = () => {
    const data = new FormData();
    console.log(file);
    if (!file || !file.name) {
      toast.error(t("Please select correct front image!"));
      return;
    }
    if (!backFile || !backFile.name) {
      toast.error(t("Please select correct back image!"));
      return;
    }
    if (!selfFile || !selfFile.name) {
      toast.error(t("Please select correct self image!"));
      return;
    }

    data.append("front_file", file);
    data.append("back_file", backFile);
    data.append("self_file", selfFile);

    if (!type || !CONST.KYC_TYPE[type]) {
      toast.error(t("Please select the document type!"));
      return;
    }
    data.append("type", CONST.KYC_TYPE[type]);
    RestService.post("/kyc/new", data).then((res) => {
      if (!res.error) {
        toast.success(t("Validation File Uploaded Successfully!"));
        setVisibleUpload(false);
      } else {
        toast.error(
          t("Upload Failed! Reason: ", { error: t("Internal Server Error") })
        );
      }
    });
  };

  return (
    <Profile title={t("KYC")} breadcrumbs={breadcrumbs}>
      <div className={styles.row}>
        <div className={styles.title}>{t("Validation Status")}</div>
        <div className={styles.level}>{status}</div>
      </div>
      {user.kyc_status > 0 || !visibleUpload ? (
        <div>
          <KycTable kycList={kycList} />
          {user.kyc_status !== 1 && (
            <button
              className={cn("button-stroke", styles.button)}
              onClick={() => setVisibleUpload(true)}
            >
              {t("Upload New")}
            </button>
          )}
        </div>
      ) : (
        user.kyc_status !== undefined && (
          <div className={styles.wrap}>
            <div className={styles.title}>
              {t("Upload your document to validate your identity")}
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <FileInput
                  className={styles.field}
                  classInput={styles.input}
                  label={t("Front Image")}
                  name={"referral-link"}
                  value={file}
                  setValue={setFile}
                  required
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <FileInput
                  className={styles.field}
                  classInput={styles.input}
                  label={t("Back Image")}
                  name={"referral-link"}
                  value={backFile}
                  setValue={setBackFile}
                  required
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <FileInput
                  className={styles.field}
                  classInput={styles.input}
                  label={t("Self Image")}
                  name={"referral-link"}
                  value={selfFile}
                  setValue={setSelfFile}
                  required
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <Dropdown
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  label={t("Document Type")}
                  value={type}
                  setValue={setType}
                  options={[
                    t("Passport"),
                    t("Driver License"),
                    t("Other ID Card"),
                  ]}
                  validate={{
                    empty: true,
                  }}
                />
              </div>
            </div>
            <button
              className={cn("button-stroke", styles.button)}
              onClick={onUploadDocument}
            >
              {t("Upload Document")}
            </button>
          </div>
        )
      )}
    </Profile>
  );
};

export default KYC;
