import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import styles from "./Users.module.sass";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TOKEN_CATEGORY } from "../../../../common/const";
import { useSimpleBitmaidoContext } from "../../../../hooks/useSimpleBitmaidoContext";
import Dropdown from "../../../../components/Dropdown";
import Users from "../Users/index"

const ICO = () => {
  const { t } = useTranslation();
  const { currencies } = useSimpleBitmaidoContext();

  const CATEGORY_LIST = [
    "All",
    "Artist",
    "Sports",
    "Actor",
    "Business",
    "Music",
    "Others",
  ];
  const CATEGORY_LIST_2 = [
    t("All"),
    t("Artist"),
    t("Sports"),
    t("Actor"),
    t("Business"),
    t("Music"),
    t("Others"),
  ];

  const [category, setCategory] = useState(-1);

  const [page, setPage] = useState(0);
  const total = useMemo(() => {
    return currencies.filter(
      (x) =>
        new Date() < new Date(x.lockTime) &&
        x.icoStatus === 1 &&
        (category === -1 ? true : x.category === category)
    ).length;
  }, [currencies, category]);

  const isMobile = () => {
    const regex =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  };

  const onMovePage = (index) => {
    if (index >= 0 && index <= total / 6) setPage(index);

    if (isMobile()) {
      const element = document.getElementById("userBox");
      element.scrollIntoView();
    }
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)} id="userBox">
        <div className={styles.title_container}>
          <h2 className={styles.title}>{t("ICO")}</h2>
        </div>
        <div className={styles.search_container}>
          <div className={styles.category_content}>
            {CATEGORY_LIST.map((data, index) => (
              <span
                className={cn(
                  (
                    category === -1
                      ? index === 0
                      : TOKEN_CATEGORY[data.toUpperCase()] === category
                  )
                    ? "button-gray button-xs"
                    : "",
                  styles.button
                )}
                key={`category_${index}`}
                onClick={() =>
                  setCategory(
                    index === 0 ? -1 : TOKEN_CATEGORY[data.toUpperCase()]
                  )
                }
              >
                {t(data)}
              </span>
            ))}
          </div>
          <div className={styles.category_content_mobile}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              classDropdownArrow={styles.dropdownArrow}
              classDropdownBody={styles.dropdownBody}
              classDropdownOption={styles.dropdownOption}
              value={category === -1 ? t("All") : t(TOKEN_CATEGORY[category])}
              setValue={(e) =>
                e === t("All")
                  ? setCategory(-1)
                  : e === t("Others") ? setCategory(0)
                  : setCategory(CATEGORY_LIST_2.indexOf(e))
              }
              options={CATEGORY_LIST_2}
            />
          </div>
        </div>
        <div className={styles.users_container}>
          {currencies
            .filter(
              (x) =>
                new Date() < new Date(x.lockTime) &&
                x.icoStatus === 1 &&
                (category === -1 ? true : x.category === category)
            )
            .slice(0, page * 6 + 6)
            .map((owner, index) => {
              const leftDays = Math.floor(
                (new Date(owner.lockTime).getTime() - new Date().getTime()) /
                  (3600 * 1000 * 24)
              );
              return (
                <Link
                  className={styles.user_card}
                  to={`/buy-crypto?currency=${owner.currency}`}
                  key={`tokenICO_${index}`}
                >
                  <div className={styles.user_photo}>
                    <img
                      src={`${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${owner.promotion_url}`}
                      onError={(e) => {
                        e.target.src = "/images/content/coin-default.svg";
                      }}
                      alt="Card preview"
                    />
                  </div>
                  <div
                    className={styles.card_body}
                    style={{
                      paddingBottom: 0,
                    }}
                  >
                    <div className={styles.card_avatar}>
                      <img
                        src={`${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${owner.avatar}`}
                        onError={(e) => {
                          e.target.src = "/images/content/avatar-default.svg";
                        }}
                        alt="Card Avatar"
                      />
                    </div>
                    <div className={styles.card_details}>
                      <div
                        className={styles.row}
                        style={{
                          width: "100%",
                        }}
                      >
                        <div className={styles.card_title}>
                          {owner.currency ?? "Unknown"}
                        </div>
                        <div
                          className={cn("category-stroke-green", styles.status)}
                        >
                          {t(TOKEN_CATEGORY[owner.category])}
                        </div>
                      </div>
                      <div className={styles.card_author}>{owner.title}</div>
                    </div>
                  </div>

                  <div
                    className={styles.progressLabel}
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <label></label>
                    <label>{t("Days left", { value: leftDays })}</label>
                  </div>
                  <div className={styles.progressBar}>
                    <span
                      className={styles.progress}
                      style={{
                        width: `${Math.floor(
                          (owner.saleAmount / owner.target) * 100
                        )}%`,
                      }}
                    >
                      {`${Math.floor(
                        (owner.saleAmount / owner.target) * 100
                      )}% sold`}
                    </span>
                  </div>
                </Link>
              );
            })}
        </div>
        <div className={styles.pagination}>
          {page * 6 + 6 < total && (
            <button onClick={() => onMovePage(page + 1)}>{"Load more"}</button>
          )}
        </div>
      </div>
      <Users category={category} />
    </div>
  );
};

export default ICO;
