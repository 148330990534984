import React, { useEffect, useState } from "react";
import jwt from "jsonwebtoken";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink } from "react-router-dom";
import Image from "../Image";
import Dropdown from "./Dropdown";
import Settings from "./Settings";
import Icon from "../Icon";
import Notifications from "./Notifications";
import Theme from "../Theme";
import User from "./User";
import RestService from "../../adapter/base";

import { isLoggedIn } from "../../common/helper";

import { useTranslation } from "react-i18next";

const Header = ({ headerWide }) => {
  const { t } = useTranslation();

  const navigation = [
    {
      title: t("Exchange"),
      dropdown: [
        {
          title: t("Spot"),
          icon: "chart",
          url: "/exchange/spot",
        },
        {
          title: t("Margin"),
          icon: "chart",
          url: "/exchange/margin",
        },
      ],
    },
    {
      title: t("How it works"),
      dropdown: [
        {
          title: t("Tutorial"),
          icon: "user",
          url: "/",
        },
        {
          title: t("Help Center"),
          icon: "image",
          url: "https://hc.a51.exchange/",
        },
      ],
    },
    {
      title: t("Market News"),
      url: "/news",
      user: false,
    },
    // {
    //   title: t("Market News"),
    //   url: "https://media.all41.world/",
    //   user: false,
    // },
  ];

  const [visibleNav, setVisibleNav] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    try {
      const token = window.localStorage.getItem("token");
      const userInfo = jwt.verify(token, process.env.REACT_APP_SALT);
      setUser(userInfo);
      RestService.token(token);
    } catch {
      setUser({});
    }
  }, []);

  return (
    <header className={cn(styles.header, { [styles.wide]: headerWide })}>
      <div className={cn("container", styles.container)}>
        <Link
          className={styles.logo}
          to="/"
          onClick={() => setVisibleNav(false)}
        > 
          <Image
            className={styles.picDesktop}
            src="/images/logo3.png"
            alt="A41"
          />
          {/* <Image
            className={styles.picDesktop}
            src="/images/logo-light.svg"
            srcDark="/images/logo-dark.svg"
            alt="A41"
          /> */}
          {/* <img
            className={styles.picMobile}
            src="/images/logo-light.svg"
            alt="A41"
          />
          <img
            className={styles.picMobileDark}
            src="/images/logo-dark.svg"
            alt="A41"
          /> */}
        </Link>
        <div className={styles.wrapper}>
          <div className={cn(styles.wrap, { [styles.visible]: visibleNav })}>
            <nav className={styles.nav}>
              {navigation.map((x, index) => {
                if (x.user && !user.id) {
                  return "";
                }
                return x.dropdown ? (
                  <Dropdown
                    className={styles.dropdown}
                    key={index}
                    item={x}
                    setValue={setVisibleNav}
                  />
                ) : x.url.indexOf("://") === -1 ? (
                  <NavLink
                    className={styles.item}
                    activeclassname={styles.active}
                    onClick={() => setVisibleNav(false)}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ) : (
                  <a
                    className={styles.item}
                    key={index}
                    href={x.url}
                    target="_blank"
                  >
                    {x.title}
                  </a>
                );
              })}
            </nav>
            <NavLink
              className={cn("button-stroke", styles.button)}
              activeclassname={styles.active}
              to="/wallet-overview"
              onClick={() => setVisibleNav(false)}
            >
              {t("Wallet")}
            </NavLink>
          </div>
          <div style={{ display: "flex", marginLeft: "auto" }}>
            <Settings className={styles.settings} />
            {/* <div className={styles.control}>
              <Theme className={styles.theme} icon />
            </div> */}
            {user.id ? (
              <div className={styles.control}>
                <NavLink
                  className={styles.activity}
                  activeclassname={styles.active}
                  to="/activity"
                >
                  <Icon name="lightning" size="24" />
                </NavLink>
                <Notifications className={styles.notifications} />
                <NavLink
                  className={cn("button-stroke button-small", styles.button)}
                  activeclassname={styles.active}
                  to="/wallet-overview"
                >
                  {t("Wallet")}
                </NavLink>
                <User className={styles.user} />
              </div>
            ) : (
              <div className={styles.btns}>
                <Link
                  className={cn("button-small", styles.button)}
                  activeclassname={styles.active}
                  to="/sign-up"
                  onClick={() => setVisibleNav(false)}
                >
                  {t("Sign Up")}
                </Link>
                <Link
                  className={cn("button-stroke button-small", styles.button)}
                  activeclassname={styles.active}
                  to="/sign-in"
                  onClick={() => setVisibleNav(false)}
                >
                  {t("Login")}
                </Link>
              </div>
            )}
          </div>
          <button
            className={cn(styles.burger, { [styles.active]: visibleNav })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </div>
    </header>
  );
};

export default Header;
