import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Form from "../Form";
import Icon from "../Icon";
import Image from "../Image";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  const menu = [
    {
      title: t("Trading"),
      url: "/exchange/spot",
    },
    {
      title: t("How it works"),
      url: "/",
    },
    {
      title: t("Learn crypto"),
      url: "/",
    },
    {
      title: t("Contact"),
      url: "",
    },
  ];

  const socials = [
    {
      title: "facebook",
      size: "16",
      url: "https://www.facebook.com/ui8.net/",
    },
    {
      title: "twitter",
      size: "18",
      url: "https://twitter.com/ui8",
    },
    {
      title: "instagram",
      size: "16",
      url: "https://www.instagram.com/ui8net/",
    },
    {
      title: "dribbble",
      size: "16",
      url: "https://dribbble.com/ui8",
    },
    {
      title: "behance",
      size: "20",
      url: "https://www.behance.net/ui8",
    },
  ];

  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.col}>
            <Link className={styles.logo} to="/">
              <Image
                className={styles.picDesktop}
                src="/images/logo.png"
                alt="A41"
              />
              {/* <Image
                className={styles.picDesktop}
                src="/images/logo-light.svg"
                srcDark="/images/logo-dark.svg"
                alt="A41"
              /> */}
              {/* <img
                className={styles.picMobile}
                src="/images/logo-dark.svg"
                alt="A41"
              /> */}
              {/* <Image
                className={styles.picMobile}
                src="/images/logo-light.svg"
                srcDark="/images/logo-dark.svg"
                alt="A41"
              /> */}
            </Link>
            <div className={cn(styles.item, { [styles.active]: visible })}>
              <div
                className={styles.category}
                onClick={() => setVisible(!visible)}
              >
                {t("footer nav")}
                <Icon name="arrow-down" size="24" />
              </div>
              <div className={styles.menu}>
                {menu.map((x, index) => (
                  <a
                    className={styles.link}
                    href={x.url}
                    key={index}
                    target={x.url.indexOf("://") === -1 ? "_self" : "_blank"}
                  >
                    {x.title}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>{t("ABOUT")}</div>
            <div className={styles.info}>
              <p>
                {t(
                  "ALL41 is a marketplace that offers everyone the opportunity to list and provides value to individuals."
                )}
              </p>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>{t("newsletter")}</div>
            <div className={styles.info}>
              {t(
                "Subscribe our newsletter to get more free design course and resource."
              )}
            </div>
            <Form
              className={styles.form}
              value={email}
              setValue={setEmail}
              onSubmit={() => handleSubmit()}
              placeholder={t("Enter your email")}
              type="email"
              name="email"
              icon="arrow-next"
            />
          </div>
        </div>
      </div>
      <div className={styles.foot}>
        <div className={cn("container", styles.container)}>
          <div className={styles.copyright}>
            {t("Copyright © 2023 ALL41.WORLD. All rights reserved")}
          </div>
          {/* <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
