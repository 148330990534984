import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ImportantNotes.module.sass";
import RestService from "../../../adapter/base";
import CONST from "../../../common/const";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

const ImportantNotes = ({ method, type, price, currency, address, net }) => {
  const { t } = useTranslation();

  const [depositAddress, setDepositAddress] = useState("");
  const [requestCode, setRequestCode] = useState("");
  useEffect(() => {
    RestService.get("/deposit/address", {
      method:
        method === t("Direct Transfer")
          ? CONST.TRADE_METHOD.DIRECT
          : CONST.TRADE_METHOD.WITHDRAW,
      trade_type:
        type === "Spot" ? CONST.TRADE_TYPE.SPOT : CONST.TRADE_TYPE.MARGIN,
      currency: currency,
      address: address,
      amount: price,
    }).then((res) => {
      if (!res.error) {
        toast.success(t("Deposit Address returned successfully!"));
        if (net === undefined) {
          setRequestCode(res.requestCode);
          setDepositAddress(res.address);
        } else {
          const deposits = JSON.parse(res);
          setDepositAddress(deposits[currency][net]);
        }
      } else {
        toast.error(
          t("Get Deposit Address Failed! Reason: ", {
            error: t("Internal Server Error"),
          })
        );
      }
    });
  }, []);

  const onContinue = () => {
    window.location = "/activity";
  };

  if (net === undefined && depositAddress) {
    return (
      <div className={styles.item}>
        <div className={styles.title}>{t("Deposit Address")}</div>
        <div className={styles.text}>
          {t("SEND your coin to deposit address Below:")}
        </div>
        <div className={styles.code}>{depositAddress}</div>
        <div className={styles.text}>
          {t(
            "You MUST keep your Reference Code in other to request validate for your deposit"
          )}
          <br></br>
          {t("Reference Code:")}
        </div>
        <div className={styles.code}>{requestCode}</div>
        <div className={styles.btns}>
          <button className={cn("button", styles.button)} onClick={onContinue}>
            {t("Continue")}
          </button>
        </div>
      </div>
    );
  }
  if (net !== undefined && depositAddress) {
    return (
      <div className={styles.item}>
        <div className={styles.title}>{t("Deposit Address")}</div>
        <div className={styles.text}>
          {t("SEND your coin to deposit address Below:")}
        </div>
        <div className={styles.code}>{depositAddress}</div>
        <div className={styles.btns}>
          <button className={cn("button", styles.button)} onClick={onContinue}>
            {t("Continue")}
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.item}>
        <div className={styles.title}>
          {t("Get Deposit Address Failed! Please contact to the manager!")}
        </div>
        <div className={styles.btns}>
          <button
            className={cn("button", styles.button)}
            onClick={() => (window.location.href = "/contact")}
          >
            {t("Contact Us")}
          </button>
        </div>
      </div>
    );
  }
};

export default ImportantNotes;
