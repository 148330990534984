import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import RestService from "../../adapter/base";
import Profile from "../../components/Profile";
import styles from "./TokenSettings.module.sass";
import CONST, { TOKEN_CATEGORY } from "../../common/const";
import { useTranslation } from "react-i18next";
import { ReactComponent as PenIcon } from "../../assets/svg/pen.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/delete.svg";
import { ReactComponent as DetailIcon } from "../../assets/svg/detail.svg";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

const TokenList = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const breadcrumbs = [
    {
      title: t("Home"),
      url: "/",
    },
    {
      title: t("Your token settings"),
    },
  ];
  const [tokens, setTokens] = useState([]);

  const getTokens = () => {
    RestService.get("/currency/list", {
      where: {
        type: CONST.CURRENCY_TYPE.USER_DEPLOYED,
        user_id: parseInt(window.localStorage.getItem("user_id") + ""),
      },
      limit: 10,
      offset: 0,
      orderBy: ["created_at", "asc"],
    }).then((currencies) => {
      setTokens(currencies.rows);
    });
  };
  useEffect(() => {
    getTokens();
  }, []);

  const onAddNew = () => {
    history.push("/token-settings/new");
  };

  const onRemoveItem = (cur) => {
    RestService.post("/currency/delete", {
      id: cur,
    }).then((result) => {
      console.log(result);
      if (result !== true) {
        toast.error(t(result.error));
      } else {
        getTokens();
      }
    });
  };

  return (
    <Profile title={t("Your token settings")} breadcrumbs={breadcrumbs}>
      <div className={styles.title}>{t("Your token settings")}</div>
      <div className={styles.description}>
        {t("Adding more than 3 tokens should be approved by administrator.")}
      </div>
      <div className={styles.scrollArea}>
        <div className={styles.table}>
          <div className={cn(styles.table_row, styles.table_header)}>
            <div className={styles.table_col}></div>
            <div className={styles.table_col}>{t("Currency")}</div>
            <div className={styles.table_col}>{t("Total Amount")}</div>
            <div className={styles.table_col}>{t("Date")}</div>
            <div className={styles.table_col}>{t("Status")}</div>
            <div className={styles.table_col}>{t("Action")}</div>
          </div>
          {tokens.map((token, index) => (
            <div className={styles.table_row} key={`token_${index}`}>
              <div className={styles.table_col}>
                {token.avatar && (
                  <img
                    src={`${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${token.promotion_url}`}
                    style={{
                      width: 40,
                    }}
                  />
                )}
              </div>
              <div className={styles.table_col}>{token.currency}</div>
              <div className={styles.table_col}>{token.total_amount}</div>
              <div className={styles.table_col}>
                {token.updated_at.substring(0, 19).replace("T", " ")}
              </div>
              <div className={cn(styles.table_col, styles.status_cell)}>
                <span
                  className={
                    token.status === 0 ? styles.disabled : styles.enabled
                  }
                >
                  {token.status === 0 ? t("approve required") : t("enabled")}
                </span>
                {token.ico_status === 1 &&
                  new Date() < new Date(token.lock_time) && (
                    <span className={styles.disabled}>ICO</span>
                  )}
              </div>
              <div className={styles.table_col}>
                {token.ico_status === 0
                  ? token.status !== 0 && (
                      <>
                        <PenIcon
                          width={24}
                          height={24}
                          className={styles.action}
                          onClick={() => {
                            history.push(
                              `/token-settings/edit?currency=${token.currency}`
                            );
                          }}
                        />
                        <DeleteIcon
                          width={20}
                          height={20}
                          className={styles.action}
                          onClick={() => onRemoveItem(token.id)}
                        />
                      </>
                    )
                  : token.status !== 0 && (
                      <DetailIcon
                        width={22}
                        height={22}
                        className={styles.action}
                        onClick={() => {
                          history.push(
                            `/token-settings/detail?currency=${token.currency}`
                          );
                        }}
                      />
                    )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={onAddNew}>
          {t("Add New")}
        </button>
      </div>
    </Profile>
  );
};

export default TokenList;
