import React from 'react';
import { useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Sns.module.sass";
import { useTranslation } from "react-i18next";
import RestService from "../../../../adapter/base";
import axios from "axios";
import ReactPaginate from "react-paginate";

const Youtube = ({ snsId }) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);

  const [youtubeChannel, setYoutubeChannel] = useState({});
  const [youtubeList, setYoutubeList] = useState([]);
  const [pageToken, setPageToken] = useState("trial");
  const [pageInfo, setPageInfo] = useState();
  const totalPage = useRef(0);
  const [pageIndex, setPageIndex] = useState(0);

  const [userThumbnail, setUserThumbnail] = useState(
    "/images/content/avatar-default.svg"
  );
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    axios
      .get("https://www.googleapis.com/youtube/v3/channels", {
        params: {
          part: "snippet,id",
          id: snsId, // should change this with real user sns account
          key: process.env.REACT_APP_YOUTUBE_API,
        },
      })
      .then((res) => {
        setYoutubeChannel(res.data.items[0]);
      });
  }, [snsId]);

  useEffect(() => {
    if (!youtubeChannel.id) return;
    setUserThumbnail(youtubeChannel.snippet.thumbnails.default.url);
    setUsername(youtubeChannel.snippet.title);
    setUserId(youtubeChannel.snippet.customUrl);
  }, [youtubeChannel]);

  useEffect(() => {
    if (!youtubeChannel.id) return;

    axios
      .get("https://www.googleapis.com/youtube/v3/search", {
        params: {
          part: "snippet,id",
          channelId: youtubeChannel.id,
          key: process.env.REACT_APP_YOUTUBE_API,
          maxResults: 3,
          order: "date",
          pageToken: pageToken && pageToken !== "trial" ? pageToken : "",
        },
      })
      .then((res) => {
        setYoutubeList(res.data.items);
        setPageInfo({
          next: res.data.nextPageToken,
          prev: res.data.prevPageToken,
        });

        if (res.data.nextPageToken) {
          totalPage.current++;
        }
      });
  }, [youtubeChannel, pageToken]);

  const onPageChange = (event) => {
    if (event.selected > pageIndex) {
      setPageToken(pageInfo.next);
    } else {
      setPageToken(pageInfo.prev);
    }

    setPageIndex(event.selected);
  };

  return (
    <>
      <div className={styles.container_sm_fluid}>
        <div className={styles.user_photo}>
          <img src={`/images/content/youtube.svg`} alt={t("Currency")} />
        </div>
        <div className={styles.detail_container}>
          <h1>Youtube</h1>
        </div>
      </div>
      <div
        className={cn(styles.container, styles.videoList)}
        style={{
          marginTop: "10px",
        }}
      >
        {youtubeList.map(
          (item, index) =>
            item.id.videoId && (
              <a
                href={`https://www.youtube.com/watch?v=${item.id.videoId}`}
                className={styles.post_card}
                target="_blank"
              >
                <div className={styles.sns_profile}>
                  <img src={userThumbnail} />
                  <div className={styles.name_container}>
                    <label className={styles.name}>{username}</label>
                    <label className={styles.id}>{userId}</label>
                  </div>
                </div>
                <span>{item.snippet.title}</span>
                <img src={item.snippet.thumbnails.medium.url} />
              </a>
            )
        )}
      </div>
      <ReactPaginate
        key="youtubePagination"
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        marginPagesDisplayed={0}
        pageRangeDisplayed={0}
        pageCount={totalPage.current}
        renderOnZeroPageCount={null}
        pageClassName="page-item page-item-hidden"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        forcePage={pageIndex}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default Youtube;
