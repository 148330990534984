import axios from "axios";

let token = "";
let errorCb = null;

export const RestService = {
  token(tok) {
    token = tok || "";
  },

  err(cb) {
    errorCb = cb;
  },

  getToken() {
    return token;
  },

  query(resource, params) {
    return axios.get(resource, params).catch((error) => {
      throw new Error(`RestService ${error}`);
    });
  },

  get(resource, params) {
    return new Promise((resolve) => {
      axios
        .get(`${process.env.REACT_APP_REST_ENDPOINT}${resource}`, {
          params,
          headers: {
            Authorization: token,
          },
        })
        .then((ret) => {
          if (ret.data.error) {
            resolve(ret.data);
            return;
          }

          resolve(ret.data.data);
        })
        .catch((error) => {
          throw new Error(`RestService ${error}`);
        });
    });
  },

  post(resource, params) {
    return new Promise((resolve) => {
      return axios
        .post(`${process.env.REACT_APP_REST_ENDPOINT}${resource}`, params, {
          headers: {
            Authorization: token,
          },
        })
        .then((ret) => {
          if (ret.data.error) {
            resolve(ret.data);
            return;
          }

          resolve(ret.data.data);
        })
        .catch((error) => {
          throw new Error(`RestService ${error}`);
        });
    });
  },

  put(resource, params) {
    return new Promise((resolve) => {
      return axios
        .put(`${process.env.REACT_APP_REST_ENDPOINT}${resource}`, params, {
          headers: {
            Authorization: token,
          },
        })
        .then((ret) => {
          if (ret.data.error) {
            resolve(ret.data);
            return;
          }

          resolve(ret.data.data);
        })
        .catch((error) => {
          throw new Error(`RestService ${error}`);
        });
    });
  },

  delete(resource) {
    return new Promise((resolve) => {
      return axios
        .delete(`${process.env.REACT_APP_REST_ENDPOINT}${resource}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((ret) => {
          if (ret.data.error && errorCb !== null) {
            errorCb(ret.data.error);
            return;
          }

          resolve(ret.data.data);
        })
        .catch((error) => {
          throw new Error(`RestService ${error}`);
        });
    });
  },
};

export default RestService;
