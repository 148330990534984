import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Finish.module.sass";

import { useTranslation } from 'react-i18next';

const Finish = () => {
  const { t } = useTranslation();

  const onClickGetBack = () => {
    window.localStorage.removeItem('token');
  }
  return (
    <div>
      <div className={styles.wrap}>
        <div className={cn("h3", styles.title)}>{t("New password")}</div>
        <div className={styles.info}>{t("Your new password has been set")}</div>
        <Link className={cn("button-stroke", styles.button)} onClick={onClickGetBack} to="/sign-in">
          {t("Get home")}
        </Link>
      </div>
    </div>
  );
};

export default Finish;
