import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Recommends.module.sass";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../components/Checkbox";
import Icon from "../../../components/Icon";
import { useMediaQuery } from "react-responsive";
import { RECOMMEND_RELATION } from "../../../common/const";
import RestService from "../../../adapter/base";

const Recommends = ({ currency }) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [family, setFamily] = useState(true);
  const [friends, setFriends] = useState(true);
  const [coworker, setCowokrer] = useState(true);
  const [adviser, setAdviser] = useState(true);
  const [supporter, setSupporter] = useState(true);

  const [showFilter, setShowFilter] = useState(isMobile ? false : true);

  const [recommends, setRecommends] = useState([]);

  const [showLoadMore, setShowLoadMore] = useState(true);

  const getCategoryClassName = (category) => {
    switch (category) {
      case RECOMMEND_RELATION.FAMILY:
        return "category-stroke-orange";
      case RECOMMEND_RELATION.FRIENDS:
        return "category-stroke-blue";
      case RECOMMEND_RELATION.COWORKER:
        return "category-stroke-red";
      case RECOMMEND_RELATION.ADVISER:
        return "category-stroke-purple";
      case RECOMMEND_RELATION.SUPPORTER:
      default:
        return "category-stroke-blue";
    }
  };

  const getRecommendList = (refresh = false) => {
    let filters = [];

    if (family) {
      filters.push(`relation = ${RECOMMEND_RELATION.FAMILY}`);
    }

    if (friends) {
      filters.push(`relation = ${RECOMMEND_RELATION.FRIENDS}`);
    }

    if (coworker) {
      filters.push(`relation = ${RECOMMEND_RELATION.COWORKER}`);
    }

    if (adviser) {
      filters.push(`relation = ${RECOMMEND_RELATION.ADVISER}`);
    }

    if (supporter) {
      filters.push(`relation = ${RECOMMEND_RELATION.SUPPORTER}`);
    }

    RestService.get("/recommend/list", {
      where: {
        opponent_id: currency,
        raw: filters.length ? filters.join(" OR ") : "relation = -1",
      },
      limit: 10,
      offset: refresh ? 0 : recommends.length,
      orderBy: ["created_at", "asc"],
    }).then((result) => {
      if (!result) {
        return;
      }

      if (refresh) {
        setRecommends(result.rows);
        setShowLoadMore(result.count !== result.rows.length);
      } else {
        setRecommends([...recommends, ...result.rows]);
        setShowLoadMore(
          result.count !== recommends.length + result.rows.length
        );
      }
    });
  };

  useEffect(() => {
    getRecommendList(true);
  }, [family, friends, coworker, adviser, supporter]);

  const getCategoryName = (category) => {
    switch (category) {
      case RECOMMEND_RELATION.FAMILY:
        return t("Family");
      case RECOMMEND_RELATION.FRIENDS:
        return t("Friends");
      case RECOMMEND_RELATION.COWORKER:
        return t("Coworker");
      case RECOMMEND_RELATION.ADVISER:
        return t("Adviser");
      case RECOMMEND_RELATION.SUPPORTER:
      default:
        return t("Supporter");
    }
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={styles.notification_body}>
        <div className={styles.center}>
          <div className={styles.notification_top}>
            <h2 className={cn("h2", styles.title)}>{t("Recommends")}</h2>
            <div className={styles.btn_group}>
              <button
                className={cn("button-small button-stroke", styles.button)}
                style={{ marginRight: "16px" }}
              >
                {t("Mark all as read")}
              </button>
              <button
                className={cn("button-small button-stroke", styles.button)}
              >
                {t("Clear all")}
              </button>
            </div>
          </div>
          <div className={styles.show_filter_btn}>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => setShowFilter(!showFilter)}
            >
              {t("Advanced Filter")}
            </button>
          </div>
          <div className={styles.notification_row}>
            <div
              className={styles.notification_filter}
              style={{ display: showFilter ? "block" : "none" }}
            >
              <div className={styles.notification_info}>{t("Filters")}</div>
              <div className={styles.notification_group}>
                <Checkbox
                  className={styles.checkbox}
                  content={t("Family")}
                  value={family}
                  onChange={() => setFamily(!family)}
                  note={undefined}
                />
                <Checkbox
                  className={styles.checkbox}
                  content={t("Friends")}
                  value={friends}
                  onChange={() => setFriends(!friends)}
                  note={undefined}
                />
                <Checkbox
                  className={styles.checkbox}
                  content={t("Coworker")}
                  value={coworker}
                  onChange={() => setCowokrer(!coworker)}
                  note={undefined}
                />
                <Checkbox
                  className={styles.checkbox}
                  content={t("Adviser")}
                  value={adviser}
                  onChange={() => setAdviser(!adviser)}
                  note={undefined}
                />
                <Checkbox
                  className={styles.checkbox}
                  content={t("Supporter")}
                  value={supporter}
                  onChange={() => setSupporter(!supporter)}
                  note={undefined}
                />
              </div>
              <div className={styles.notification_control}>
                <button
                  className={cn("button-small button-stroke", styles.button)}
                  style={{ marginRight: "16px" }}
                  onClick={() => {
                    setFamily(true);
                    setFriends(true);
                    setCowokrer(true);
                    setAdviser(true);
                    setSupporter(true);
                  }}
                >
                  {t("Select all")}
                </button>
                <button
                  className={cn("button-small button-stroke", styles.button)}
                  onClick={() => {
                    setFamily(false);
                    setFriends(false);
                    setCowokrer(false);
                    setAdviser(false);
                    setSupporter(false);
                  }}
                >
                  {t("Unselect all")}
                </button>
              </div>
            </div>
            <div className={styles.notification_wrap}>
              {recommends.map((recommend, index) => {
                return (
                  <div className={styles.notification_item} key={index}>
                    <div className={styles.crypto_icon}>
                      <img
                        src={`${process.env.REACT_APP_REST_ENDPOINT}/kyc/document/${recommend.avatar}`}
                        alt="User"
                        onError={(e) =>
                          (e.target.src = "/images/content/avatar-default.svg")
                        }
                      />
                    </div>
                    <div className={styles.notification_details}>
                      <div className={styles.notification_line}>
                        <div className={styles.notification_subtitle}>
                          {recommend.fullname}
                          <div
                            className={cn(
                              getCategoryClassName(recommend.relation),
                              styles.category
                            )}
                            style={{ marginLeft: "12px" }}
                          >
                            {getCategoryName(recommend.relation)}
                          </div>
                        </div>
                        <div className={styles.notification_date}>
                          {recommend.created_at.replace("T", " ").slice(0, 16)}
                        </div>
                      </div>
                      <div className={styles.notification_content}>
                        {recommend.message}
                      </div>
                    </div>
                  </div>
                );
              })}
              {showLoadMore && (
                <div className={styles.notification_btns}>
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                    onClick={() => getRecommendList(false)}
                  >
                    {t("Load more")}
                    <Icon name="calendar" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recommends;
