import { useContext } from "react";
import { UserContext } from "../context/userContext";

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(
      "Make sure to only call userContext within a  <UserProvider>"
    );
  }
  return context;
};
