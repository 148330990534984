import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./TwoFa.module.sass";
import Profile from "../../components/Profile";
import TwoFaEnabled from "./TwoFaEnabled";
import TwoFaDisabled from "./TwoFaDisabled";
import RestService from "../../adapter/base";

import { useTranslation } from 'react-i18next';

const TwoFa = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    {
      title: t("Home"),
      url: "/",
    },
    {
      title: t("2FA"),
    },
  ];
  
  const [disabled, setDisabled] = useState(false);
  const [user, setUser] = useState({});

  const onRefreshUser = () => {
    try {
      RestService.get('/user')
      .then((res) => {
        if (!res.error) {
          setUser(res);
        }
      })
    } catch {
      setUser({});
    }
  }

  useEffect(() => {
    onRefreshUser();
  }, []);

  useEffect(() => {
    if (user.email) {
      setDisabled(!user.ga_enabled);
    }
  }, [user])

  return (
    <Profile title={t("2FA")} breadcrumbs={breadcrumbs}>
      {disabled ? (
        <TwoFaDisabled user={user}/>
      ) : (
        <TwoFaEnabled user={user}/>
      )}
    </Profile>
  );
};

export default TwoFa;
