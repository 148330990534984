import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

import { useTranslation } from "react-i18next";
import { useBitmaidoContext } from "../../../hooks/useBitmaidoContext";

const Main = ({ total, rate }) => {
  const { t } = useTranslation();
  const { precisions } = useBitmaidoContext();

  const items = [
    {
      title: t("balance"),
      content: "0.13894652",
      currency: "BTC",
      price: "$10,098.36",
    },
  ];

  return (
    <div className={styles.main}>
      <h4 className={cn("h4", styles.title)}>{t("Spot")}</h4>
      <div className={styles.list}>
        {items.map((x, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.info}>{x.title}</div>
            <div className={styles.line}>
              <div className={styles.currency}>
                {total.toFixed(precisions["USDT"])}
              </div>
              <div className={cn("category-green", styles.category)}>USDT</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Main;
