import React, { useState } from "react";
import cn from "classnames";
import styles from "./Funds.module.sass";
import Icon from "../Icon";
import Item from "./Item";

import { useTranslation } from "react-i18next";

const Funds = ({ accounts, type }) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
  };

  const onClickSearch = () => {};

  return (
    <div className={styles.wrap}>
      <div className={styles.line}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <input
            className={styles.input}
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            name="search"
            placeholder={t("Search coin")}
            required
          />
          <button className={styles.result} onClick={onClickSearch}>
            <Icon name="search" size="20" />
          </button>
        </form>
      </div>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>{t("Asset")}</div>
          <div className={styles.col}>{t("Total balance")}</div>
          <div className={styles.col}>{t("Available balance")}</div>
          <div className={styles.col}>{t("On Order")}</div>
        </div>
        {accounts.map((x, index) => {
          if (search.length > 0 && x.currency.indexOf(search) === -1) return;

          if (x.type === type)
            return <Item className={styles.item} item={x} key={index} />;
        })}
      </div>
    </div>
  );
};

export default Funds;
