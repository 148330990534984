import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Settings.module.sass";
import OutsideClickHandler from "react-outside-click-handler";
import Icon from "../../Icon";
import axios from "axios";

import { useTranslation } from "react-i18next";
import i18n from "i18next";

import CONST from "../../../common/const";

const languages = [
  {
    title: "English",
    flag: "EN",
    lang: "en",
  },
  {
    title: "简体中文",
    flag: "CN",
    lang: "cn",
  },
  {
    title: "日本語",
    flag: "JP",
    lang: "jp",
  },
];

const currency = ["USD", "RUB", "EUR", "JPY", "BTC"];

const Settings = ({ className }) => {
  const { t } = useTranslation();

  const getLang = async () => {
    let ret = localStorage.getItem(CONST.STORAGE_KEY.LANG);
    if (!ret) {
      ret = await axios
        .get(
          `https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`
        )
        .then((res) => {
          if (res.data.country === "JP") {
            i18n.changeLanguage("jp");
            localStorage.setItem(CONST.STORAGE_KEY.LANG, "jp");
            return "jp";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return ret ? ret : "en";
  };

  const [visible, setVisible] = useState(false);
  const [lang, setLang] = useState("en");

  const initializeLang = async () => {
    const ret = await getLang();
    i18n.changeLanguage(ret);
    setLang(ret);
  };

  useEffect(() => {
    initializeLang();
  }, []);

  const getLanguage = (lang) => {
    for (let index in languages) {
      if (languages[index].lang === lang) {
        return languages[index];
      }
    }
  };

  const setLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang);
    localStorage.setItem(CONST.STORAGE_KEY.LANG, lang);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(className, styles.settings, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          {getLanguage(lang).flag}
          <Icon name="arrow-down" size="16" />
        </button>
        <div className={styles.body}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.category}>{t("Language")}</div>
              <div className={styles.menu}>
                {languages.map((x, index) => (
                  <div
                    className={cn(styles.language, {
                      [styles.active]: lang === x.lang,
                    })}
                    key={index}
                    onClick={() => setLanguage(x.lang)}
                  >
                    <span className={styles.flag}>{x.flag}</span>
                    {x.title}
                  </div>
                ))}
              </div>
            </div>
            {/* <div className={styles.col}>
              <div className={styles.category}>Currency</div>
              <div className={styles.menu}>
                {currency.map((x, index) => (
                  <div
                    className={cn(styles.currency, {
                      [styles.active]: index === 0,
                    })}
                    key={index}
                  >
                    {x}
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Settings;
