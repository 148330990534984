import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Trades.module.sass";
import Icon from "../../../components/Icon";

import numeral from "numeral";

import CONST from "../../../common/const";
import { useBitmaidoContext } from "../../../hooks/useBitmaidoContext";

import { useTranslation } from "react-i18next";

const Trades = () => {
  const { t } = useTranslation();

  const navigation = [t("Market trades")];

  const [activeIndex, setActiveIndex] = useState(0);
  const { trades, symbolInfo } = useBitmaidoContext();

  return (
    <div className={styles.trades}>
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            {t("Price")} ({symbolInfo.foreignCurrency})
          </div>
          <div className={styles.col}>
            {t("Amount")} ({symbolInfo.baseCurrency})
          </div>
          <div className={styles.col}>{t("Time")}</div>
        </div>
        {trades.map((x, index) => {
          if (x.symbol !== symbolInfo.symbol) {
            return;
          }

          let numeralFormat = "0,0.";
          for (let i = 0; i < symbolInfo.precision; i++) {
            numeralFormat = numeralFormat + "0";
          }

          return (
            <div className={styles.row} key={index}>
              <div className={styles.col}>
                {x.side === CONST.TRADE_SIDE.BUY && (
                  <div className={styles.positive}>
                    {numeral(x.price).format(numeralFormat)}
                  </div>
                )}
                {x.side === CONST.TRADE_SIDE.SELL && (
                  <div className={styles.negative}>
                    {numeral(x.price).format(numeralFormat)}
                  </div>
                )}
              </div>
              <div className={styles.col}>
                {numeral(x.quantity).format("0,0.0000")}
              </div>
              <div className={styles.col}>
                {new Date(x.time).toLocaleTimeString()}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Trades;
