import React from 'react';
import { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import styles from "./Sns.module.sass";
import { useTranslation } from "react-i18next";
import RestService from "../../../adapter/base";
import axios from "axios";
import { Link } from "react-router-dom";
import { shortenText } from "../../../common/helper";

const Youtube = () => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const snsId = useMemo(() => queryParams.get("id"), [queryParams]);
  const type = useMemo(() => queryParams.get("type"), [queryParams]);

  const [youtubeChannel, setYoutubeChannel] = useState({});
  const [youtubeList, setYoutubeList] = useState([]);
  const [pageToken, setPageToken] = useState("trial");
  const [loadMore, setLoadMore] = useState(true);

  useEffect(() => {
    if (type === "youtube") {
      axios
        .get("https://www.googleapis.com/youtube/v3/channels", {
          params: {
            part: "snippet,id",
            id: snsId, // should change this with real user sns account
            key: process.env.REACT_APP_YOUTUBE_API,
          },
        })
        .then((res) => {
          setYoutubeChannel(res.data.items[0]);
        });
    }
  }, [snsId, type]);

  useEffect(() => {
    if (!youtubeChannel.id) return;
    if (!loadMore) return;

    axios
      .get("https://www.googleapis.com/youtube/v3/search", {
        params: {
          part: "snippet,id",
          channelId: youtubeChannel.id,
          key: process.env.REACT_APP_YOUTUBE_API,
          maxResults: 20,
          order: "date",
          pageToken: pageToken && pageToken !== "trial" ? pageToken : "",
        },
      })
      .then((res) => {
        setLoadMore(false);
        setPageToken(res.data.nextPageToken);
        setYoutubeList([...youtubeList, ...res.data.items]);
      });
  }, [youtubeChannel, youtubeList, pageToken, loadMore]);

  return (
    <>
      <div className={styles.container_sm_fluid}>
        <div className={styles.user_photo}>
          <img
            src={youtubeChannel?.snippet?.thumbnails?.medium?.url}
            onError={(e) =>
              (e.target.src = "/images/content/avatar-default.svg")
            }
            alt=""
          />
        </div>
        <div className={styles.detail_container}>
          <h1>{youtubeChannel?.snippet?.title}</h1>
          <p>
            {youtubeChannel?.snippet?.description
              ? shortenText(youtubeChannel?.snippet?.description, 400)
              : ""}
          </p>
        </div>
      </div>
      <p className={styles.description}>
        {youtubeChannel?.snippet?.description
          ? shortenText(youtubeChannel?.snippet?.description, 200)
          : ""}
      </p>
      <div
        className={cn(styles.container, styles.videoList)}
        style={{
          marginTop: "70px",
        }}
      >
        {youtubeList.map(
          (item, index) =>
            item.id.videoId && (
              <a
                href={`https://www.youtube.com/watch?v=${item.id.videoId}`}
                target="_blank"
              >
                <img src={item.snippet.thumbnails.medium.url} />
                <span>{item.snippet.title}</span>
              </a>
            )
        )}
      </div>
      {pageToken && (
        <span className={styles.loadMore} onClick={() => setLoadMore(true)}>
          {t("Load More")}...
        </span>
      )}
    </>
  );
};

export default Youtube;
