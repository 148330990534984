import cn from "classnames";
import styles from "./signin.module.sass";
import { useHistory } from "react-router";
import React from 'react';

import { useTranslation } from 'react-i18next';

const SignInCmb = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const onClick = () => {
    history.push("/sign-in");
  }
  return (
    <div className={styles.div}>
    <button className={cn("button-green", styles.button)} onClick={onClick}>
      {t("Sign-in")}
    </button>
    </div>
  )
}

export default SignInCmb;