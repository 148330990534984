import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import jwt from "jsonwebtoken";
import styles from "./Exchange.module.sass";
import Main from "./Main";
import Balance from "./Balance";
import Currency from "./Currency";
import Trades from "./Trades";
import Table from "./Table";
import Actions from "./Actions";
import Charts from "./Charts";
import SignInCmb from "../../components/common/signinCmb";
import { useMediaQuery } from "react-responsive";

import io from "socket.io-client";

import CONST from "../../common/const";
import { useBitmaidoContext } from "../../hooks/useBitmaidoContext";

import { useTranslation } from "react-i18next";
import { ExchangeProvider } from "../../Providers";

const Exchange = () => {
  const { t } = useTranslation();

  const navigation = [t("Chart"), t("Order books"), t("Symbols")];

  const [activeIndex, setActiveIndex] = useState(1);
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
  const { setSymbolInfo, symbols, ignoreSymbolParam } = useBitmaidoContext();

  const [user, setUser] = useState({});

  const queryParam = new URLSearchParams(window.location.search);

  useEffect(() => {
    try {
      const token = window.localStorage.getItem("token");
      const userInfo = jwt.verify(token, process.env.REACT_APP_SALT);
      setUser(userInfo);
    } catch {
      setUser({});
    }
  }, []);

  useEffect(() => {
    if (ignoreSymbolParam) {
      return;
    }

    let symbolName = queryParam.get("symbol");

    if (!symbolName) {
      symbolName = "BTCUSDT";
    }

    for (let i = 0; i < symbols.length; i++) {
      if (symbols[i].symbol === symbolName) {
        setSymbolInfo({
          symbol: symbols[i].symbol,
          alias: symbols[i].alias,
          precision: symbols[i].precision,
          baseCurrency: symbols[i].base_currency,
          foreignCurrency: symbols[i].foreign_currency,
        });
        break;
      }
    }
  }, [symbols]);

  return (
    <div className={styles.exchange}>
      <Main />
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      {isTablet ? (
        <ExchangeProvider>
          <Actions user={user} />
          {activeIndex === 0 && (
            <div className={styles.box}>
              <Charts />
              <Table />
              {user.id === undefined && <SignInCmb />}
            </div>
          )}
          {activeIndex === 1 && (
            <div className={styles.box}>
              <Balance />
            </div>
          )}
          {activeIndex === 2 && (
            <div className={styles.box}>
              <Currency setSymbolInfo={setSymbolInfo} />
              <Trades />
            </div>
          )}
        </ExchangeProvider>
      ) : (
        <ExchangeProvider>
          <div className={styles.row}>
            <div className={styles.col}>
              <Balance />
            </div>
            <div className={styles.col}>
              <Charts />
              <Actions user={user} />
              <Table />
              {user.id === undefined && <SignInCmb />}
            </div>
            <div className={styles.col}>
              <Currency setSymbolInfo={setSymbolInfo} />
              <Trades />
            </div>
          </div>
        </ExchangeProvider>
      )}
    </div>
  );
};

export default Exchange;
