import React from 'react';
import { useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Sns.module.sass";
import { useTranslation } from "react-i18next";
import RestService from "../../../../adapter/base";
import axios from "axios";
import ReactPaginate from "react-paginate";

const Facebook = ({ snsId }) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);

  const [postList, setPostList] = useState([]);
  const totalPage = useRef(0);
  const [pageIndex, setPageIndex] = useState(0);

  const [userThumbnail, setUserThumbnail] = useState(
    `https://graph.facebook.com/v19.0/${snsId}/picture?access_token=${process.env.REACT_APP_FACEBOOK_API_TOKEN}`
  );
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    axios
      .get(`https://graph.facebook.com/v19.0/${snsId}`, {
        params: {
          access_token: process.env.REACT_APP_FACEBOOK_API_TOKEN,
        },
      })
      .then((res) => {
        setUsername(res.data.name);
        setUserId(res.data.id);
      });

    axios
      .get(`https://graph.facebook.com/v19.0/${snsId}/posts`, {
        params: {
          access_token: process.env.REACT_APP_FACEBOOK_API_TOKEN,
          field: ["permalink_url"],
        },
      })
      .then((res) => {
        const tmpData = [];
        for (let i in res.data.data) {
          if (res.data.data[i].message) {
            tmpData.push(res.data.data[i]);
          }
        }
        setPostList(tmpData);
        totalPage.current = Math.ceil(tmpData.length / 6);
      });
  }, [snsId]);

  const onPageChange = (event) => {
    setPageIndex(event.selected);
  };

  return (
    <>
      <div className={styles.container_sm_fluid}>
        <div className={styles.user_photo}>
          <img src={`/images/content/facebook.svg`} alt={t("Currency")} />
        </div>
        <div className={styles.detail_container}>
          <h1>Facebook</h1>
        </div>
      </div>
      <div
        className={cn(styles.container, styles.videoList)}
        style={{
          marginTop: "10px",
        }}
      >
        {postList.slice(pageIndex * 6, pageIndex * 6 + 6).map((item, index) => (
          <a
            href={`https://www.facebook.com/${item.id}`}
            target="_blank"
            className={styles.post_card}
          >
            <div className={styles.sns_profile}>
              <img src={userThumbnail} />
              <div className={styles.name_container}>
                <label className={styles.name}>{username}</label>
                <label className={styles.id}>{userId}</label>
              </div>
            </div>
            <span className={styles.allow_overflow}>
              {item.message.length > 60
                ? `${item.message.substring(0, 60)}
              ...`
                : item.message}
            </span>
          </a>
        ))}
      </div>
      <ReactPaginate
        key="facebookPagination"
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        marginPagesDisplayed={0}
        pageRangeDisplayed={0}
        pageCount={totalPage.current}
        renderOnZeroPageCount={null}
        pageClassName="page-item page-item-hidden"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        forcePage={pageIndex}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default Facebook;
