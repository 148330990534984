import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

import { useTranslation } from 'react-i18next';

const Main = () => {
  const { t } = useTranslation();

  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn("h1", styles.title)}>
            {t("Today’s Cryptocurrency prices")}
          </h1>
          <div className={styles.text}>
            {t("The global crypto market cap is ", {value: 1.86})}
          </div>
        </div>
        <div className={styles.bg}>
          <img
            srcSet="/images/content/market-pic@2x.png 2x"
            src="/images/content/market-pic.png"
            alt={t("Cards")}
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
