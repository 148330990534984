import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RestService from "../../../adapter/base";
import Icon from "../../../components/Icon";
import styles from "./FAQ.module.sass";

const FAQItem = ({ index, item }) => {
  const [showContent, setShowContent] = useState(false);

  const getDigitString = (number) => {
    return Math.floor(number / 10) + "" + (number % 10);
  };

  return (
    <div className={styles.faq_item}>
      <div
        className={styles.faq_head}
        onClick={() => setShowContent(!showContent)}
      >
        <div className={styles.faq_number}>{getDigitString(index + 1)}</div>
        <div className={styles.faq_subtitle}>{item.question}</div>
        <div className={styles.faq_arrow}>
          <Icon
            name="arrow-down"
            style={showContent ? { transform: "rotate(180deg)" } : {}}
          />
        </div>
      </div>
      {showContent && <div className={styles.faq_body}>{item.answer}</div>}
    </div>
  );
};

const TAB = {
  ALL: 0,
  SUPPORT: 1,
  HOSTING: 2,
  PRODUCT: 3,
};

const FAQ = ({ userID }) => {
  const { t } = useTranslation();

  const [faqs, setFaqs] = useState([]);

  const [tab, setTab] = useState(TAB.ALL);

  const getFAQList = () => {
    RestService.get("/faq/list", {
      where: {
        user_id: parseInt(userID),
      },
      limit: 100,
      offset: 0,
      orderBy: ["created_at", "asc"],
    }).then((faqs) => {
      if (faqs.error) {
        return;
      }

      setFaqs(faqs.rows);
    });
  };

  useEffect(() => {
    getFAQList();
  }, []);

  const getFAQListByTab = () => {
    if (tab === TAB.ALL) {
      return faqs;
    }

    let ret = [];
    faqs.map((faq) => {
      if (faq.category === tab) {
        ret.push(faq);
      }
    });
    return ret;
  };

  return (
    <div
      className={cn("section", styles.section)}
      style={{ marginTop: "100px" }}
    >
      <div className={styles.center}>
        <div className={styles.content}>
          <h2 className={cn("h2", styles.title)}>
            {t("Frequently asked\nquestions")}
          </h2>
        </div>
        <div className={styles.category_content}>
          {tab === TAB.ALL ? (
            <span
              className={cn("button-gray button-xs", styles.button)}
              style={{ marginRight: "12px", cursor: "pointer" }}
            >
              {t("All")}
            </span>
          ) : (
            <span
              className={styles.button}
              style={{ marginRight: "12px", cursor: "pointer" }}
              onClick={() => setTab(TAB.ALL)}
            >
              {t("All")}
            </span>
          )}

          {tab === TAB.SUPPORT ? (
            <span
              className={cn("button-gray button-xs", styles.button)}
              style={{ marginRight: "12px", cursor: "pointer" }}
            >
              {t("Support")}
            </span>
          ) : (
            <span
              className={styles.button}
              style={{ marginRight: "12px", cursor: "pointer" }}
              onClick={() => setTab(TAB.SUPPORT)}
            >
              {t("Support")}
            </span>
          )}
          {tab === TAB.HOSTING ? (
            <span
              className={cn("button-gray button-xs", styles.button)}
              style={{ marginRight: "12px", cursor: "pointer" }}
            >
              {t("Hosting")}
            </span>
          ) : (
            <span
              className={styles.button}
              style={{ marginRight: "12px", cursor: "pointer" }}
              onClick={() => setTab(TAB.HOSTING)}
            >
              {t("Hosting")}
            </span>
          )}
          {tab === TAB.PRODUCT ? (
            <span
              className={cn("button-gray button-xs", styles.button)}
              style={{ cursor: "pointer" }}
            >
              {t("Product")}
            </span>
          ) : (
            <span
              className={styles.button}
              style={{ cursor: "pointer" }}
              onClick={() => setTab(TAB.PRODUCT)}
            >
              {t("Product")}
            </span>
          )}
        </div>
        <div className={styles.faq_list}>
          {getFAQListByTab().map((faq, index) => {
            return <FAQItem key={index} index={index} item={faq} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
