const SYMBOL_STATUS = {
  NONE: 0,
  ACTIVE: 1,
};

const TRADE_TYPE = {
  SPOT: 1,
  MARGIN: 2,
};

const TRADE_METHOD = {
  DIRECT: 1,
  WITHDRAW: 2,
};

const CHANGE_DIRECTION = {
  MINUS: -1,
  PLUS: 1,
};

const TRADE_SIDE = {
  SELL: 1,
  BUY: 2,
};

const TRADE_SIDE_STR = {
  SELL: "Sell",
  BUY: "Buy",
};

const SPOT_TYPE = {
  LIMIT: 1,
  MARKET: 2,
  STOP: 3,
};

const ORDER_STATUS = {
  PENDING: 0,
  OPEN: 1,
};

const ORDER_HISTORY_STATUS = {
  FILLED: 1,
  SUBFILLED: 2,
  CANCELLED: 3,
  SERVER_CANCELLED: 4,
};

const DEPOSIT_STATUS = {
  PENDING: 0,
  COMPLETED: 1,
  PROCESSING: 2,
  DECLINE: 3,
  REVERT_PENDING: 4,
  REVERTED: 5,
};

const EMAIL_PLUGIN = {
  SERVICE_ID: "service_2ufg4zv",
  USER_ID: "user_BxdLg0mi5ddRY2Pj9GPFH",
  ACCESS_TOKEN: "e5a41a4ca83d5051dcbf32f44b0c30a3",
  TEMPLATE_IDS: {
    REGISTERATION: "template_iejrxpe",
    RESETPASSWORD: "template_vqe8hnp",
  },
};

const AUTH_TOKEN = {
  TOKEN: "a1b2c3d4e5f6",
};

const KYC_TYPE = {
  Passport: 1,
  "Driver License": 2,
  "Other ID Card": 3,
};

const KYC_CATEGORY = {
  1: "Passport",
  2: "Driver License",
  3: "Other ID Card",
};

const STORAGE_KEY = {
  LANG: "lang",
};

const CURRENCY_TYPE = {
  BINANCE: 0,
  USER_DEPLOYED: 1,
  OKX: 2,
};

const TREND_TAB = {
  CRYPTO_CURRENCY: 1,
  USER_TOKEN: 2,
  DEFI: 3,
  ICO: 4,
};

export default {
  SYMBOL_STATUS,
  TRADE_TYPE,
  CHANGE_DIRECTION,
  TRADE_SIDE,
  TRADE_SIDE_STR,
  ORDER_STATUS,
  SPOT_TYPE,
  ORDER_HISTORY_STATUS,
  DEPOSIT_STATUS,
  EMAIL_PLUGIN,
  AUTH_TOKEN,
  KYC_TYPE,
  STORAGE_KEY,
  TRADE_METHOD,
  KYC_CATEGORY,
  CURRENCY_TYPE,
  TREND_TAB,
};

export const USER_TAB = {
  STORY: 1,
  FAQ: 2,
  UPDATE: 3,
  COMMENT: 4,
  RECOMMEND: 5,
};

export const FAQ_CATEGORY = {
  SUPPORT: 1,
  HOSTING: 2,
  PRODUCT: 3,
};

export const TOKEN_CATEGORY = {
  ARTIST: 1,
  SPORTS: 2,
  ACTOR: 3,
  BUSINESS: 4,
  MUSIC: 5,
  OTHERS: 0,
  1: "Artist",
  2: "Sports",
  3: "Actor",
  4: "Business",
  5: "Music",
  0: "Others",
};

export const COMMENT_JOB = {
  CREATOR: 1,
  RECOMMENDER: 2,
  SUPER_BAKER: 3,
  BAKER: 4,
  OTHERS: 5,
};

export const RECOMMEND_RELATION = {
  FAMILY: 1,
  FRIENDS: 2,
  COWORKER: 3,
  ADVISER: 4,
  SUPPORTER: 5,
};
