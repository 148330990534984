import React, { useRef, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import styles from "./FAQ.module.sass";
import Profile from "../../components/Profile";

import { useTranslation } from "react-i18next";
import { ReactComponent as DeleteIcon } from "../../assets/svg/delete.svg";
import { ReactComponent as PenIcon } from "../../assets/svg/pen.svg";
import TextInput from "../../components/TextInput";
import Icon from "../../components/Icon";
import Modal from "../../components/Modal";
import TextArea from "../../components/TextArea";
import RestService from "../../adapter/base";
import { toast } from "react-toastify";
import { FAQ_CATEGORY } from "../../common/const";
import Dropdown from "../../components/Dropdown";

const YourFAQ = () => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [category, setCategory] = useState(FAQ_CATEGORY.SUPPORT);

  const [faqs, setFaqs] = useState([]);

  const [faqID, setFAQID] = useState(0);

  const [isUpdate, setIsUpdate] = useState(false);

  const [pending, setPending] = useState(false);

  const breadcrumbs = [
    {
      title: t("Home"),
      url: "/",
    },
    {
      title: t("FAQ"),
    },
  ];

  const onAddNewOrUpdate = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setIsUpdate(false);
    setFAQID(0);
    setQuestion("");
    setAnswer("");
    setCategory(FAQ_CATEGORY.SUPPORT);
  };

  const onPublishOrUpdate = () => {
    setPending(true);
    const data = new FormData();
    data.append("question", question);
    data.append("answer", answer);
    data.append("user_id", parseInt(window.localStorage.getItem("user_id")));
    data.append("category", category);
    if (isUpdate) {
      data.append("id", faqID);
      RestService.put("/faq/update", data).then((res) => {
        setPending(false);
        if (!res.error) {
          toast.success(t("FAQ is published successfully."));
          handleCloseModal();
          getFAQList();
        } else {
          toast.error(t("FAQ publishment is failed."));
        }
      });
    } else {
      RestService.post("/faq/new", data).then((res) => {
        setPending(false);
        if (!res.error) {
          toast.success(t("FAQ is published successfully."));
          handleCloseModal();
          getFAQList();
        } else {
          toast.error(t("FAQ publishment is failed."));
        }
      });
    }
  };

  const getFAQList = () => {
    RestService.get("/faq/list", {
      where: {
        user_id: parseInt(window.localStorage.getItem("user_id")),
      },
      limit: 100,
      offset: 0,
      orderBy: ["created_at", "asc"],
    }).then((faqs) => {
      if (!faqs) {
        return;
      }

      setFaqs(faqs.rows);
    });
  };

  useEffect(() => {
    getFAQList();
  }, []);

  const onUpdateFAQ = (index) => {
    setQuestion(faqs[index].question);
    setAnswer(faqs[index].answer);
    setFAQID(faqs[index].id);
    setCategory(faqs[index].category);
    setModalVisible(true);
    setIsUpdate(true);
  };

  const handleDelete = (index) => {
    setPending(true);
    const data = new FormData();
    data.append("id", faqs[index].id);
    RestService.post("/faq/delete", data).then((res) => {
      setPending(false);
      if (!res.error) {
        toast.success(t("FAQ is removed successfully."));
        getFAQList();
      } else {
        toast.error(t("Removing FAQ is failed."));
      }
    });
  };

  const getCategoryName = (faq) => {
    switch (faq.category) {
      case FAQ_CATEGORY.SUPPORT:
        return t("Support");
      case FAQ_CATEGORY.HOSTING:
        return t("Hosting");
      case FAQ_CATEGORY.PRODUCT:
        return t("Product");
    }
    return "";
  };

  const getCategoryString = (category) => {
    switch (category) {
      case FAQ_CATEGORY.SUPPORT:
        return t("Support");
      case FAQ_CATEGORY.HOSTING:
        return t("Hosting");
      case FAQ_CATEGORY.PRODUCT:
        return t("Product");
    }
    return "";
  };

  const handleCategorySelected = (value) => {
    switch (value) {
      case t("Hosting"):
        setCategory(FAQ_CATEGORY.HOSTING);
        break;
      case t("Product"):
        setCategory(FAQ_CATEGORY.PRODUCT);
        break;
      case t("Support"):
      default:
        setCategory(FAQ_CATEGORY.SUPPORT);
        break;
    }
  };

  return (
    <Profile title={t("FAQ")} breadcrumbs={breadcrumbs}>
      <div className={styles.title}>{t("Your FAQ List")}</div>
      <div className={styles.scrollArea}>
        <div className={styles.table}>
          <div className={cn(styles.table_row, styles.table_header)}>
            <div className={styles.table_col}>{t("")}</div>
            <div className={styles.table_col}>{t("Category")}</div>
            <div className={styles.table_col}>{t("Question")}</div>
            <div className={styles.table_col}>{t("Answer")}</div>
            <div className={styles.table_col}>{t("Action")}</div>
          </div>
          {faqs.map((faq, index) => (
            <div className={styles.table_row} key={`faq_${index}`}>
              <div className={styles.table_col}>{index + 1}</div>
              <div className={styles.table_col}>{getCategoryName(faq)}</div>
              <div className={styles.table_col}>{faq.question}</div>
              <div className={styles.table_col}>{faq.answer}</div>
              <div className={styles.table_col}>
                <PenIcon
                  width={24}
                  height={24}
                  className={styles.action}
                  onClick={() => onUpdateFAQ(index)}
                  style={{ cursor: "pointer" }}
                />
                <DeleteIcon
                  width={20}
                  height={20}
                  className={styles.action}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!pending) {
                      handleDelete(index);
                    }
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.btns}>
        <button
          className={cn("button", styles.button)}
          onClick={onAddNewOrUpdate}
        >
          {t("Add New")}
        </button>
      </div>
      <Modal
        visible={modalVisible}
        onClose={() => handleCloseModal()}
        outerClassName={styles.modalLg}
      >
        <div className={styles.snsUpdate}>
          <div className={cn("h4", styles.title)}>
            <Icon name="arrow-left" size="32" />
            {t("Add new FAQ")}
          </div>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            classDropdownArrow={styles.dropdownArrow}
            classDropdownBody={styles.dropdownBody}
            classDropdownOption={styles.dropdownOption}
            value={getCategoryString(category)}
            setValue={(value) => handleCategorySelected(value)}
            options={[t("Support"), t("Hosting"), t("Product")]}
            label={t("Category")}
          />
          <TextInput
            className={styles.field}
            label={t("Question")}
            name="title"
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            required
          />
          <TextArea
            label={t("Answer")}
            style={{
              height: 240,
            }}
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
          <div className={styles.buttonRow}>
            <button
              className={cn(
                "button",
                styles.button,
                pending ? "button--loading" : ""
              )}
              onClick={onPublishOrUpdate}
              disabled={pending}
            >
              {t("Publish")}
            </button>
          </div>
        </div>
      </Modal>
    </Profile>
  );
};

export default YourFAQ;
