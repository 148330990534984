import React, { createRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import cn from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./Form.module.sass";
import Dropdown from "../../../components/Dropdown";
import TextInput from "../../../components/TextInput";
import RestService from "../../../adapter/base";
import { useBitmaidoContext } from "../../../hooks/useBitmaidoContext";

import { useTranslation } from "react-i18next";

const optionsPhone = ["🇺🇸 +1", "🇻🇳 +3", "🇷🇺 +7"];

const Form = ({ onValidate, onGA }) => {
  const { t } = useTranslation();
  const recaptchaInstance = createRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const [phone, setPhone] = useState(optionsPhone[0]);
  const [verified, setVerified] = useState(false);
  const [validateError, setValidateError] = useState(false);
  const { setUserLoggedin } = useBitmaidoContext();
  const [pending, setPending] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!verified) {
      setValidateError(true);
      return;
    }
    setPending(true);
    RestService.post(`/auth`, {
      email: email,
      password: password,
    })
      .then((res) => {
        if (!res.error) {
          if (res.enabled === 1) {
            if (res.ga_enabled === 0) {
              setUserLoggedin(true);
              window.localStorage.setItem("token", res.jwt_token);
              window.localStorage.setItem("user_id", res.id);
              window.location = "/exchange/spot";
            } else {
              onGA(res);
            }
          } else {
            onValidate(res);
          }
        } else {
          if (res.error === "WRONG_PASSWORD") {
            toast.error(
              t("Wrong Password or E-mail. Please check your input!"),
              {
                postition: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
              }
            );
          } else {
            toast.error(t("Login failed! Please try again!"), {
              postition: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
            });
          }
        }
      })
      .catch(() => {})
      .finally(() => setPending(false));
  };

  const verifyCallback = (response) => {
    setVerified(true);
    setValidateError(false);
  };

  return (
    <form className={styles.form} type="submit" onSubmit={handleSubmit}>
      <div className={styles.nav}>
        {/*navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
            type="button"
          >
            {x}
          </button>
          ))*/}
      </div>
      <div className={styles.container}>
        {activeIndex === 0 && (
          <TextInput
            className={styles.field}
            label={t("email")}
            name={"email"}
            type="email"
            placeholder={t("Email address")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        )}
        {activeIndex === 1 && (
          <div className={styles.line}>
            <div className={styles.field}>
              <Dropdown
                className={styles.dropdown}
                label={t("mobile")}
                value={phone}
                setValue={setPhone}
                options={optionsPhone}
              />
            </div>
            <TextInput
              className={styles.field}
              name={"phone"}
              type="tel"
              required
            />
          </div>
        )}
      </div>
      <TextInput
        className={styles.field}
        label={t("Password")}
        name={"password"}
        type="password"
        placeholder={t("Password")}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        view
      />
      <div className={styles.foot}>
        <Link className={styles.link} to="/forgot-password">
          {t("Forgot password?")}
        </Link>
      </div>
      <div className={styles.recaptcha}>
        <ReCAPTCHA
          ref={recaptchaInstance}
          sitekey="6LcmgXggAAAAAHjANSmlwM56mK6f8Fa2A9FYjK9G"
          onChange={verifyCallback}
        />
        {validateError && (
          <div className={styles.recaptcha_error}>
            Please verify on reCAPTCHA
          </div>
        )}
      </div>

      <button
        type="submit"
        className={cn(
          "button",
          styles.button,
          pending ? "button--loading" : ""
        )}
        disabled={pending}
      >
        <span>{t("Login")}</span>
      </button>
    </form>
  );
};

export default Form;
