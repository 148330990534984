import React from "react";
import cn from "classnames";
import styles from "./Recommends.module.sass";
import Profile from "../../components/Profile";
import TextInput from "../../components/TextInput";

import { useTranslation } from "react-i18next";
import TextArea from "../../components/TextArea";

const Recommends = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    {
      title: t("Home"),
      url: "/",
    },
    {
      title: t("Your recommends"),
    },
  ];
  return (
    <Profile title={t("Your recommends")} breadcrumbs={breadcrumbs}>
      <div className={styles.head}>{t("Your recommends")}</div>
      <div className={styles.row}>
        {t(
          "Create a profile for your project, and if you get a recommendation, tokens will be issued. Create engaging content."
        )}
      </div>
      <div className={styles.field}>
        <div className={styles.title}>
          {t("Upload your image for recommend profile")}
        </div>
        <input type="file" data-listener-added_d1f3db46="true" />
      </div>
      <div className={styles.field}>
        <div className={styles.title}>
          {t("Write about your profile as recommender")}
        </div>
        <TextArea
          style={{
            height: 200,
          }}
        />
      </div>

      <button className={cn("button", styles.button)}>{t("Save")}</button>
    </Profile>
  );
};

export default Recommends;
