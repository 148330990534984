import React, { useEffect, useState } from "react";
import cn from "classnames";
import { toast } from "react-toastify";
import styles from "./Action.module.sass";
import { Range, getTrackBackground } from "react-range";
import Icon from "../../../../../components/Icon";
import CONST from "../../../../../common/const";
import RestService from "../../../../../adapter/base";
import { useBitmaidoContext } from "../../../../../hooks/useBitmaidoContext";
import { useExchangeContext } from "../../../../../hooks/useExchangeContext";

import { useTranslation } from "react-i18next";

const Action = ({
  type,
  side,
  price,
  stop,
  limit,
  classButton,
  buttonText,
  balance,
}) => {
  const { t } = useTranslation();

  const [values, setValues] = useState([0]);
  const [orderPrice, setOrderPrice] = useState("");
  const [stopPrice, setStopPrice] = useState("");
  const [amount, setAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [foreignCurrency, setForeignCurrency] = useState({});
  const [baseCurrency, setBaseCurrency] = useState({});

  const {
    currencies,
    precisions,
    symbolInfo,
    symbolQuote,
    refreshOrderTable,
    setRefreshOrderTable,
  } = useBitmaidoContext();

  const { selectedOrder } = useExchangeContext();

  const stepPrice = 10;
  const minPrice = 0;
  const maxPrice = 100;

  useEffect(() => {
    for (let i in currencies) {
      if (currencies[i].currency === symbolInfo.baseCurrency) {
        setBaseCurrency(currencies[i]);
      }
      if (currencies[i].currency === symbolInfo.foreignCurrency) {
        setForeignCurrency(currencies[i]);
      }
    }
  }, [symbolInfo, currencies]);

  const onMakeOrder = () => {
    if (parseFloat(amount) <= 0) {
      toast.error(t("Invalid order amount"));
      return;
    }

    const order = {
      side: side === CONST.TRADE_SIDE.BUY ? "Buy" : "Sell",
      symbol: symbolInfo.symbol,
      trade_type: type,
      amount: amount,
    };

    if (!amount) {
      toast.error(t("Order Amount is not set"));
      return;
    }

    if (stop && limit) {
      if (!orderPrice) {
        toast.error(t("Limit Price is not set"));
        return;
      }

      if (!stopPrice) {
        toast.error(t("Stop Price is not set"));
        return;
      }

      if (parseFloat(orderPrice) <= 0 || parseFloat(stopPrice) <= 0) {
        toast.error(t("Invalid price value"));
        return;
      }
      order.price = orderPrice;
      order.stopPrice = stopPrice;

      order.type = "STOP_LOSS_LIMIT";
    } else if (price) {
      if (!orderPrice) {
        toast.error(t("Limit Price is not set"));
        return;
      }

      if (parseFloat(orderPrice) <= 0) {
        toast.error(t("Invalid price value"));
        return;
      }

      order.price = orderPrice;
      order.type = "LIMIT";
    } else {
      if (side === CONST.TRADE_SIDE.BUY) order.amount = parseFloat(totalAmount);
      order.price = symbolQuote.price;
      order.type = "MARKET";
    }

    RestService.post("/orders/new", order).then((data) => {
      if (!data.error) {
        toast.success(t("Order opened successfully!"));
        setTimeout(() => setRefreshOrderTable(refreshOrderTable + 1), 1000);
      } else {
        toast.error(
          t("Order opened failed! Reason: ", {
            error: t(data.error),
          })
        );
      }
    });
  };

  const onRangeChange = (val) => {
    const currentPrice = price
      ? orderPrice
      : stop && limit
      ? orderPrice
      : symbolQuote.price;
    setValues(val);
    setAmount(
      (side === CONST.TRADE_SIDE.BUY
        ? (balance * val[0]) /
          100 /
          (currentPrice
            ? currentPrice
            : symbolQuote.price
            ? symbolQuote.price
            : 1)
        : (balance * val[0]) / 100
      ).toFixed(precisions[symbolInfo.baseCurrency])
    );

    setTotalAmount(
      (side === CONST.TRADE_SIDE.BUY
        ? (balance * val[0]) / 100
        : (balance * val[0] * (currentPrice ? currentPrice : 1)) / 100
      )
        .toFixed(precisions[symbolInfo.foreignCurrency])
        .toString()
    );
  };

  const onChangeStopPrice = (ev) => {
    let value = ev.target.value.replace(/[^\d.-]/g, "");
    if (value.split(".").length - 1 > 1) return;

    setStopPrice(value);
  };

  const onChangePrice = (ev) => {
    let value = ev.target.value.replace(/[^\d.-]/g, "");
    if (value.split(".").length - 1 > 1) return;

    setOrderPrice(value);
    if (parseFloat(value) > 0 && parseFloat(amount) > 0) {
      setTotalAmount(amount * parseFloat(value));
    }
  };

  const onChangeAmount = (ev) => {
    let value = ev.target.value.replace(/[^\d.-]/g, "");
    if (value.split(".").length - 1 > 1) return;

    setAmount(value);
    if (value === "") setTotalAmount("");
    else {
      const price = orderPrice ? parseFloat(orderPrice) : symbolQuote.price;
      setTotalAmount(
        (parseFloat(value) * price).toFixed(
          precisions[symbolInfo.foreignCurrency]
        )
      );
    }
  };

  const onChangeTotalAmount = (ev) => {
    let value = ev.target.value.replace(/[^\d.-]/g, "");
    if (value.split(".").length - 1 > 1) return;

    setTotalAmount(value);
    if (value === "") setAmount("");
    else
      setAmount(
        (parseFloat(value) / symbolQuote.price).toFixed(
          precisions[symbolInfo.baseCurrency]
        )
      );
  };

  useEffect(() => {
    if (selectedOrder === undefined) return;

    if (selectedOrder.side !== side) return;
    setOrderPrice(selectedOrder.order[0]);
  }, [selectedOrder]);

  return (
    <>
      <div className={styles.head}>
        <div className={styles.title}>
          {t(side === CONST.TRADE_SIDE.BUY ? "Buy " : "Sell ", {
            currency: symbolInfo.baseCurrency,
          })}
        </div>
        <div className={styles.counter}>
          <Icon name="wallet" size="16" />{" "}
          {`${parseFloat(balance).toFixed(
            side === CONST.TRADE_SIDE.BUY
              ? foreignCurrency.precision
              : baseCurrency.precision
          )} ${
            side === CONST.TRADE_SIDE.BUY
              ? symbolInfo.foreignCurrency
              : symbolInfo.baseCurrency
          }`}
        </div>
      </div>
      {price && (
        <label className={styles.field}>
          <div className={styles.label}>{t("Price")}</div>
          <input
            className={styles.input}
            type="text"
            name="price"
            required
            value={orderPrice}
            onChange={onChangePrice}
          />
          <div className={styles.currency}>{symbolInfo.foreignCurrency}</div>
        </label>
      )}
      {stop && (
        <label className={styles.field}>
          <div className={styles.label}>{t("Stop")}</div>
          <input
            className={styles.input}
            type="text"
            name="stop"
            required
            value={stopPrice}
            onChange={onChangeStopPrice}
          />
          <div className={styles.currency}>{symbolInfo.foreignCurrency}</div>
        </label>
      )}
      {limit && (
        <label className={styles.field}>
          <div className={styles.label}>{t("Limit")}</div>
          <input
            className={styles.input}
            type="text"
            name="limit"
            required
            value={orderPrice}
            onChange={onChangePrice}
          />
          <div className={styles.currency}>{symbolInfo.foreignCurrency}</div>
        </label>
      )}
      <label className={styles.field}>
        <div className={styles.label}>{t("Amount")}</div>
        <input
          className={styles.input}
          type="text"
          name="amount"
          required
          value={amount}
          onChange={onChangeAmount}
        />
        <div className={styles.currency}>{symbolInfo.baseCurrency}</div>
      </label>
      {price && (
        <label className={cn(styles.field, styles.readonly)}>
          <div className={styles.label}>{t("Total Amount")}</div>
          <input
            className={styles.input}
            type="text"
            name="limit"
            required
            value={totalAmount}
            readOnly
          />
          <div className={styles.currency}>{symbolInfo.foreignCurrency}</div>
        </label>
      )}

      {!price && !stop && !limit && (
        <label className={styles.field}>
          <div className={styles.label}>{t("Total Amount")}</div>
          <input
            className={styles.input}
            type="text"
            name="limit"
            required
            value={totalAmount}
            onChange={onChangeTotalAmount}
          />
          <div className={styles.currency}>{symbolInfo.foreignCurrency}</div>
        </label>
      )}
      <Range
        values={values}
        step={stepPrice}
        min={minPrice}
        max={maxPrice}
        onChange={(values) => onRangeChange(values)}
        renderMark={({ props, index }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "6px",
              width: "2px",
              marginTop: "-2px",
              borderRadius: "1px",
              backgroundColor:
                index * stepPrice < values[0] ? "#3772FF" : "#E6E8EC",
            }}
          />
        )}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "2px",
                width: "100%",
                borderRadius: "1px",
                background: getTrackBackground({
                  values,
                  colors: ["#3772FF", "#E6E8EC"],
                  min: minPrice,
                  max: maxPrice,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "18px",
              width: "18px",
              borderRadius: "50%",
              backgroundColor: "#F4F5F6",
              border: "4px solid #777E90",
              boxShadow: "0px 8px 16px -8px rgba(15, 15, 15, 0.2)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-27px",
                color: "#FCFCFD",
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "16px",
                fontFamily: "Poppins",
                padding: "2px 6px",
                borderRadius: "6px",
                backgroundColor: "#777E90",
              }}
            >
              {values[0].toFixed(0)}%
            </div>
          </div>
        )}
      />
      <button className={cn(classButton, styles.button)} onClick={onMakeOrder}>
        {buttonText}
      </button>
    </>
  );
};

export default Action;
