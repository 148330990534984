import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import RestService from "../../adapter/base";
import Profile from "../../components/Profile";
import TextInput from "../../components/TextInput";
import styles from "./TokenSettings.module.sass";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CONST, { TOKEN_CATEGORY } from "../../common/const";
import Dropdown from "../../components/Dropdown";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import lang from "suneditor/src/lang";

const TokenDetails = () => {
  const { t, i18n } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const currency = params.get("currency");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [precision, setPrecision] = useState("");
  const [category, setCategory] = useState(TOKEN_CATEGORY.ARTIST);
  const [totalSecs, setTotalSecs] = useState(0);
  const [CurrencyInfo, setCurrencyInfo] = useState({});
  const [soldAmount, setSoldAmount] = useState(0);

  useEffect(() => {
    RestService.get("/currency/list", {
      where: {
        currency,
      },
      limit: 10,
      offset: 0,
      orderBy: ["created_at", "asc"],
    }).then((currencies) => {
      if (currencies.rows.length !== 0) {
        setName(currencies.rows[0].currency);
        setDescription(currencies.rows[0].description);
        setPrecision(currencies.rows[0].precision);
        setCategory(currencies.rows[0].category);
        setInterval(() => {
          const tmpTotalSec =
            new Date(currencies.rows[0].lock_time).getTime() -
            new Date().getTime();
          tmpTotalSec > 0 && setTotalSecs(tmpTotalSec);
        }, 1000);
        setCurrencyInfo(currencies.rows[0]);
      }
    });
  }, []);

  const breadcrumbs = [
    {
      title: t("Home"),
      url: "/",
    },
    {
      title: t("Your token settings"),
    },
  ];

  const getCategoryName = (category) => {
    switch (category) {
      case TOKEN_CATEGORY.ARTIST:
        return t("Artist");
      case TOKEN_CATEGORY.SPORTS:
        return t("Sports");
      case TOKEN_CATEGORY.ACTOR:
        return t("Actor");
      case TOKEN_CATEGORY.BUSINESS:
        return t("Business");
      case TOKEN_CATEGORY.MUSIC:
        return t("Music");
      case TOKEN_CATEGORY.OTHERS:
      default:
        return t("Others");
    }
  };

  const getSoldAmount = () => {
    RestService.get("/tokenSale/info", { currency }).then((info) => {
      setSoldAmount(info.sold);
    });
  };

  useEffect(() => {
    getSoldAmount();
  }, []);

  return (
    <Profile title={t("Your token settings")} breadcrumbs={breadcrumbs}>
      <div className={styles.field}>
        <div className={styles.title}>{t("Your token settings")}</div>
      </div>
      <div className={styles.field}>
        <TextInput
          type="text"
          style={{ width: "50%" }}
          value={name}
          validate={{ empty: true }}
          label={t("Token Name")}
          disabled
        />
      </div>
      <div className={styles.field}>
        <TextInput
          type="text"
          style={{ width: "100%" }}
          value={description}
          validate={{ empty: true }}
          label={t("Token Description")}
          disabled
        />
      </div>
      <div className={styles.field}>
        <TextInput
          type="text"
          style={{ width: "100px" }}
          value={precision}
          label={t("Precision")}
          disabled
        />
      </div>
      <div className={styles.field}>
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          classDropdownArrow={styles.dropdownArrow}
          classDropdownBody={styles.dropdownBody}
          classDropdownOption={styles.dropdownOption}
          value={getCategoryName(category)}
          options={[
            t("Artist"),
            t("Sports"),
            t("Actor"),
            t("Business"),
            t("Music"),
            t("Others"),
          ]}
          label={t("Category")}
          disabled
        />
      </div>
      <div className={styles.title}></div>
      <div className={styles.field}>
        <div className={styles.title}>{t("Presale Status")}</div>
      </div>

      <div className={styles.timeLabel}>{t("Presale ends in")}</div>
      <div className={styles.timeContent}>
        {(() => {
          const days = Math.floor(totalSecs / (1000 * 3600 * 24));
          const hours = Math.floor(
            (totalSecs - days * 1000 * 3600 * 24) / (1000 * 3600)
          );
          const minutes = Math.floor(
            (totalSecs - days * 1000 * 3600 * 24 - hours * 1000 * 3600) /
              (1000 * 60)
          );
          const second = Math.floor(totalSecs / 1000) % 60;
          return (
            <>
              <label>{totalSecs && totalSecs > 0 ? days : "--"}</label>
              <span>d</span>
              <label>{totalSecs && totalSecs > 0 ? hours : "--"}</label>
              <span>h</span>
              <label>{totalSecs && totalSecs > 0 ? minutes : "--"}</label>
              <span>m</span>
              <label>{totalSecs && totalSecs > 0 ? second : "--"}</label>
              <span>s</span>
            </>
          );
        })()}
      </div>

      <div className={styles.progressArea}>
        <label>{t("Pre Sale")}</label>
        <div className={styles.progressBar}>
          <span
            className={styles.progress}
            style={{
              width: `${(
                (soldAmount * 100) /
                CurrencyInfo.target_amount
              ).toFixed(2)}%`,
            }}
          >
            {`${((soldAmount * 100) / CurrencyInfo.target_amount).toFixed(2)}%`}
          </span>
        </div>
        <div className={styles.progressLabel}>
          <label>
            {soldAmount !== undefined ? soldAmount.toLocaleString() : "--"} sold
          </label>
          <label>
            {CurrencyInfo?.target_amount > 0
              ? CurrencyInfo.target_amount.toLocaleString()
              : "--"}{" "}
            goal
          </label>
        </div>
      </div>
    </Profile>
  );
};

export default TokenDetails;
